import { Component, OnInit } from '@angular/core';
import { UserService } from "../common/services/user.service";
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs';
import { OrganisationService } from '../common/services/organisation.service';
import * as moment from 'moment';
import { ProductUsageService } from '../common/services/product-usage.service';
import { ErrorResponseService } from '../common/services/error-response.service';
declare var $: any;

@Component({
    selector: 'app-product-usage',
    standalone: false,
    templateUrl: './product-usage.component.html',
    styleUrls: ['./product-usage.component.scss']
})
export class ProductUsageComponent implements OnInit {
    breadcrumb;
    admin_data = adminLabels;
    Months = []
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {
        singleSelection: true,
        idField: "item_id",
        textField: "item_text",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        itemsShowLimit: 1,
        allowSearchFilter: true,
        closeDropDownOnSelection: true
    };
    loader = true;
    selectedMonth: string = "Select Month";
    stats = {
        totalUsers: "--",
        newUsers: "--",
        activeUserSessions: "--",
        guidesCreated: "--",
        guidesPublished: "--",
        guidesPlays: "--",
        tooltipsCreated: "--",
        tooltipsPublished: "--",
        tooltipsPlays: "--"
    }
    constructor(
        public userService: UserService,
        private router: Router,
        private titleService: Title,
        private organisationService: OrganisationService,
        private productUsageService: ProductUsageService,
        private errorResponseService: ErrorResponseService
    ) {
        this.userService.getUserData();
        // this.paginationService.initializeValues();
        // this.organisationService.getCDNData()
        this.titleService.setTitle(
            this.admin_data.platFormUsageReport + " | Cornerstone Guide"
        );

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(this.admin_data.themes, this.route.url["_value"]);
                let breadCrumb = [
                    {
                        name: this.admin_data.dashboard,
                        route: '/'
                    },
                    {
                        name: this.admin_data.platFormUsageReport,
                        route: null,
                    },
                ]
                this.userService.setBreadcrumb(breadCrumb)
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }
    ngOnInit(): void {
        this.getInitialOrgList();
        this.Months = this.getMonthArray()
    }

    getInitialOrgList() {
        this.loader = false;
        this.organisationService.getOrganisations(10000, 0).subscribe((response) => {
            if (response['error'] == false) {
                //console.log(response);

                this.dropdownList = response['data']['organizations'].map((d) => {
                    return {
                        item_id: d['organization_id'],
                        item_text: d['name']
                    }
                })
                this.loader = true
            } else {
                this.loader = true
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                ////console.log(errorResponse);                
                $("#errorModal").modal("show");
            }
        })
    }

    updateAppList = (event) => {
        switch(event.type) {
            case "select": this.onItemSelect(event); break;
            case "deselect": this.onDeSelect(); break;
            default: return;
        }
    }

    onItemSelect(event) {
        if(this.selectedMonth != "Select Month") {
            this.selectedItems = [{"item_id": event.item_id, "item_text": event.item_text}]
            this.fetchData();
        }
    }

    onDeSelect = () => this.selectedItems = [];

    onMonthChanged(event) {
        if (event.target.selectedIndex != 0) this.selectedMonth = event.target.value;
        if (event.target.selectedIndex != 0 && this.selectedItems.length != 0) {
            this.fetchData();
        }
    }

    fetchData() {
        let startDate = new Date(this.selectedMonth)
        startDate.setMonth(startDate.getMonth(), 1)
        const offset = startDate.getTimezoneOffset()
        startDate = new Date(startDate.getTime() - (offset * 60 * 1000))
        let endDate = new Date(startDate);
        endDate.setMonth(startDate.getMonth() + 1, 0);
        endDate = new Date(endDate.getTime() - (offset * 60 * 1000))
        const timezone = startDate.toString().slice(25, 33);
        const org_id = this.selectedItems[0]['item_id']
        //console.log(startDate.toISOString().split('T')[0] + "_00:00:00", endDate.toISOString().split('T')[0] + "_00:00:00",);

        this.productUsageService.getInsightsData(
            startDate.toISOString().split('T')[0] + "_00:00:00",
            endDate.toISOString().split('T')[0] + "_23:59:59",
            timezone,
            org_id
        ).subscribe((response) => {
            if (response['error']) {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            } else {
                //console.log(response);
                let data = response['data']['graphData'][0]
                this.stats.guidesPlays = (parseInt(data['guide_play']) + parseInt(data['showme_play'])).toString()
                this.stats.tooltipsPlays = parseInt(data['tooltip_shown']).toString()
            }

        })

        this.productUsageService.getServerStats(
            org_id,
            startDate.toISOString().split('T')[0]
        ).subscribe((response) => {
            if (response['error']) {
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            } else {
                let data = response['data'][0];
                this.stats.totalUsers = parseInt(data['total_users']) + ""
                this.stats.newUsers = parseInt(data['new_users']) + ""
                this.stats.activeUserSessions = parseInt(data['active_sessions']) + ""
                this.stats.guidesCreated = parseInt(data['guide_created']) + ""
                this.stats.guidesPublished = parseInt(data['guide_published']) + ""
                this.stats.tooltipsCreated = parseInt(data['tooltip_created']) + ""
                this.stats.tooltipsPublished = parseInt(data['tooltip_published']) + ""
            }
        });
    }

    getMonthArray() {
        let monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
        let months = [];
        let d = new Date();
        d.setDate(1);
        for (let i = 0; i <= 11; i++) {
            months.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
            d.setMonth(d.getMonth() - 1);
        }
        months.unshift("Select Month")
        return months;
    }
}
