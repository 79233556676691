import { Injectable, NgModule } from "@angular/core";
import {
	HttpClientModule,
	HttpHeaders,
	HttpClient,
	HttpErrorResponse,
	HttpParams,
	HttpRequest,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { OrganisationService } from "./organisation.service";
import { share } from "rxjs/operators";
import { ApiService } from "./api.service";
import { RoutesRecognized } from '@angular/router';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

export const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
		// AppKey: environment.authKey,
	}),
};

export const httpOptionsForInsightsApi = {
    headers: new HttpHeaders({
		"Content-Type": "application/json",
		AppKey: environment.authKey,
	}),
}


// export const allRequestOptions ={
//   headers: new HttpHeaders({
//     'AppKey': environment.authKey,
//     'AccessToken':this.user.token

// })
// }

export interface User {
	token: string;
	organization_id: string;
	partner_id: string;
	role: string;
	refreshtoken: string;
	organization: {
		name: string;
		image_url: string;
		organization_id: string;
		about: string;
		image_id: string;
		orgkey: string;
		bucket: string;
		settings: {
			first_slide_text: string;
			first_slide: string;
			last_slide_text: string;
			copyright_text: string;
			last_slide: string;
			widget_icon_path: string;
			dual_auth: string;
		};
		is_active: string;
		creation_date: string;
		modification_date: string;
		is_verified: string;
		is_suspend: string;
		admin_settings: any;
		theme_settings: any;
		doitforme: number;
		chatbot: number;
		lms: number;
		myteacher: number;
		onboarding_guides: number;
		app_switcher: number;
		guide_translation: number;
		current_page_beacon: number;
		guide_segmentation: number;
		all_guide: number;
		current_page: number;
		guide_limit: number;
		myintel: number;
		cdn_player: number;
		tooltip_enhancements: number;
		isPITrackingEnabled: number;
		sso: number;
		publish_limit: number;
		mediafile_creation: number;
		youtube_setting: number;
		enable_org_and_guide_json_caching: number;
	};
	sync_priority: string;
	is_sync_complete: string;
	organization_type: string;
	on_premise_config: any;
	image_path: string;
	domains: any;
	features: any;
	user_id: string;
	first_name: string;
	last_name: string;
	user_email: string;
	user_key: string;
	image_id: string;
	is_suspend: string;
	is_locked: string;
	settings: string;
	is_paid: string;
	user_type: string;
	image_url: string;
	is_verified: string;
	creation_date: string;
	modification_date: string;
	profile: string;
	relation_id: string;
	org_role_id: string;
	mandatory_pass_change: number;
	app_access: any;
	keep_login: number;
	cdn_signature: string;
	cdn_signature_expiry: number;
	is_player_request: boolean;
	show_public_guides: boolean;
	accesstoken: string;
}

export interface breadCrumb {
	name: string,
	route: any
}

@Injectable({
	providedIn: "root",
})
export class UserService {
	visible = new Subject<boolean>();
	docHeight = new Subject<string>();
	showLoader = false;
	token: string;
	isEdit = false;
	selectedUser: User;
	enableSegments = true;
	enableSSO = false;
	enableTaskList = false;
	enableConnector = false;
	enableAutomation = false;
	enableMediaThemes = false;
	enableDisplayPublishDate = false;
    enablePlayerPackages =false;         
	activeTab = '';
	sidebarOpen = true;
	token_expiry;
	showModal = new Subject<boolean>();
	commonTitle = adminLabels.myGuideTitle;
	organization_id;
	partner_id = '';    
    dashboardLoading = false;
	organization = {
		name: '',
		image_url: '',
		organization_id: '',
		about: '',
		image_id: '',
		orgkey: '',
		bucket: '',
		settings: {
			first_slide_text: '',
			first_slide: '',
			last_slide_text: '',
			copyright_text: '',
			last_slide: '',
			widget_icon_path: '',
			dual_auth: '',
		},
		is_active: '',
		creation_date: '',
		modification_date: '',
		is_verified: '',
		is_suspend: '',
		admin_settings: '',
		theme_settings: '',
		doitforme: '',
		chatbot: '',
		lms: '',
		myteacher: '',
		onboarding_guides: '',
		app_switcher: '',
		guide_translation: '',
		current_page_beacon: '',
		guide_segmentation: '',
		all_guide: '',
		current_page: '',
		guide_limit: '',
		myintel: '',
		cdn_player: '',
		tooltip_enhancements: '',
		isPITrackingEnabled: '',
		sso: '',
		publish_limit: '',
		mediafile_creation: '',
		youtube_setting: '',
		enable_org_and_guide_json_caching: '',
	};

	task_appTitles = [adminLabels.tableHeaderStatus, adminLabels.tableHeaderProgress, adminLabels.tableHeaderCreationDate];
	task_appKeys = ["", "status", "progress", "creation_date"];
	col_widths = ['30%', '30%', '30%'];
	errorMessage = '';
	cuurentYear = new Date(Date.now()).getUTCFullYear();

	delete_button = {
		type: 'delete_button',
		class: 'btn btn-danger minWidth-120 mx-2',
		disable: false,
		text: adminLabels.deleteButtonText
	}

	add_button = {
		type: 'add_user',
		class: 'btn btn-primary minWidth-120 mx-2',
		disable: false,
		text: adminLabels.addButtonText
	}

	import_button = {
		type: 'import_user',
		class: 'btn btn-primary minWidth-120',
		disable: false,
		text: adminLabels.uploadButtonText
	}

	update_button = {
		type: 'update_user',
		class: 'btn btn-primary minWidth-120 mx-2',
		disable: false,
		text: adminLabels.updateButtonText
	}

	cancel_button = {
		type: 'cancel',
		class: 'btn btn-secondary minWidth-120 mx-2',
		disable: false,
		text: adminLabels.cancelButtonText
	}

	user: User = {
		token: "",
		organization_id: "",
		partner_id: "",
		role: "",
		refreshtoken: "",
		organization: {
			name: "",
			image_url: "",
			organization_id: "",
			about: "",
			image_id: "",
			orgkey: "",
			bucket: "",
			settings: {
				first_slide_text: "",
				first_slide: "",
				last_slide_text: "",
				copyright_text: "",
				last_slide: "",
				widget_icon_path: "",
				dual_auth: "",
			},
			is_active: "",
			creation_date: "",
			modification_date: "",
			is_verified: "",
			is_suspend: "",
			admin_settings: [],
			theme_settings: [],
			doitforme: 0,
			chatbot: 0,
			lms: 0,
			myteacher: 0,
			onboarding_guides: 0,
			app_switcher: 0,
			guide_translation: 0,
			current_page_beacon: 0,
			guide_segmentation: 0,
			all_guide: 0,
			current_page: 0,
			guide_limit: 0,
			myintel: 0,
			cdn_player: 0,
			tooltip_enhancements: 0,
			isPITrackingEnabled: 0,
			sso: 0,
			publish_limit: 0,
			mediafile_creation: 0,
			youtube_setting: 0,
			enable_org_and_guide_json_caching: 0,
		},
		sync_priority: "",
		is_sync_complete: "",
		organization_type: "",
		on_premise_config: "",
		image_path: "",
		domains: "",
		features: "",
		user_id: "",
		first_name: "",
		last_name: "",
		user_email: "",
		user_key: "",
		image_id: "",
		is_suspend: "",
		is_locked: "",
		settings: "",
		is_paid: "",
		user_type: "",
		image_url: "",
		is_verified: "",
		creation_date: "",
		modification_date: "",
		profile: "",
		relation_id: "",
		org_role_id: "",
		mandatory_pass_change: 0,
		app_access: "",
		keep_login: 0,
		cdn_signature: "",
		cdn_signature_expiry: 1585826506,
		is_player_request: false,
		show_public_guides: false,
		accesstoken: "",
	};


	activeClass = "branding"
	selectedOrganisation = this.user["user"];
	include_default_segment = environment.include_default_segment;
	show_external_user_id = environment.external_user_id;

	pattern = /[\p{L}\d_@!#\$%&,."'() *-]/u;
	replacePattern = /[^\p{L}\d_@!#\$%&,."'() *-]/u;

	constructor(private http: HttpClient) {

	}

	getUserLoginHandoff(handoff_token): Observable<{}> {
		let urlPath = environment.INSIGHTS_HOST + API_END_POINTS.HANDOFF_TOKEN + '/?session_token=' + handoff_token;
		return this.http.get(urlPath, httpOptionsForInsightsApi);
	}

	generateHandoffToken(accessToken, refreshToken): Observable<{}> {
        console.log("generating handoff token");
        let curr_org_id = localStorage.getItem('active_org_id');
        if(!curr_org_id || curr_org_id ==='' || curr_org_id === '000'){
            curr_org_id = ''
        }else{
        	curr_org_id = curr_org_id
        }


        let request ={
            organization_id : curr_org_id
        }
		let urlPath;
		const userOptions = {
			headers: new HttpHeaders({
				AppKey: environment.authKey,
				AccessToken: accessToken,
				RefreshToken: refreshToken,
			}),
		};
		urlPath = environment.INSIGHTS_HOST + API_END_POINTS.GENERATE_HANDOFF_TOKEN;
		return this.http.post(urlPath,request,userOptions);
	}

	getUserLogin(req): Observable<{}> {
		// let urlPath = environment.baseUrl + API_END_POINTS.SIGN_IN;
		let urlPath = environment.baseUrl + API_END_POINTS.SIGN_IN_NEW;
		return this.http.post(urlPath, req, httpOptions);
	}

	isLoggedIn() {
		let obj = JSON.parse(localStorage.getItem("token"));
		if (obj) {
			if (obj["accesstoken"]) return true;
		} else {
			return false;
		}
	}

	loginWithOtp(req): Observable<{}> {
		let urlPath = environment.baseUrl + API_END_POINTS.USER_VERIFY;
		return this.http.post(urlPath, req, httpOptions);
	}

	unlockWithOtp(req): Observable<{}> {
		let urlPath = environment.baseUrl + API_END_POINTS.USER_OTP_UNLOCK;
		return this.http.post(urlPath, req, httpOptions);
	}

	getUserList(limit, offset, search = "", is_suspend = '', role = '', org_role_id = '', source): Observable<{}> {
		let urlPath;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		if (source == 'topLevelInternalUsers' || source == 'topLevelExternalUsers') {
			if (this.user.role == "superadmin" || this.user.role == "partner_admin") {
				if (this.organization_id == '000') {
					urlPath =
						environment.baseUrl +
						"/user/list/by_type?limit=" +
						limit +
						"&offset=" +
						offset;
				} else {
					urlPath =
						environment.baseUrl +
						"/user/list/by_type?limit=" +
						limit +
						"&offset=" +
						offset +
						"&organization_id=" +
						this.organization_id;
				}

				if (this.user.role == "partner_admin") {
					urlPath = urlPath + "&partner_id=" + this.user.partner_id;
				}

				if (source == 'topLevelInternalUsers') {
					urlPath = urlPath + "&type=" + "internal";
				}
				else {
					urlPath = urlPath + "&type=" + "external";
				}

			} else {
				urlPath =
					environment.baseUrl +
					"/user/list/by_type?limit=" +
					limit +
					"&offset=" +
					offset +
					"&organization_id=" +
					this.organization_id +
					"&type=" +
					"external"
			}
		}
		else {
			if (this.user.role == "superadmin" || this.user.role == "partner_admin") {
				if (this.organization_id == '000') {
					urlPath =
						environment.baseUrl +
						"/user/list?limit=" +
						limit +
						"&offset=" +
						offset;
				} else {
					urlPath =
						environment.baseUrl +
						"/user/list?limit=" +
						limit +
						"&offset=" +
						offset +
						"&organization_id=" +
						this.organization_id;
				}
			} else {
				urlPath =
					environment.baseUrl +
					"/user/list?limit=" +
					limit +
					"&offset=" +
					offset +
					"&organization_id=" +
					this.organization_id;
			}
		}

		if (search != "") {
			// search=""+search;
			urlPath += `&search_text=${encodeURIComponent(search.trim())}`;
			// userOptions['params']=new HttpParams().set('search_text',search);
		}
		if (is_suspend != '') {
			urlPath += "&is_suspend=" + is_suspend;

		}
		if (role != '') {
			urlPath += '&role=' + role;
		}
		if (org_role_id != '') {
			urlPath += '&org_role_id=' + org_role_id;
		}
		return this.http.get(urlPath, userOptions);
	}

	getSearchUserList(limit, offset, search = "", is_suspend = '', role = '', org_role_id = ''): Observable<{}> {
		let urlPath;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		if (this.user.role == "superadmin") {
			if (this.organization_id == '000') {
				urlPath =
					environment.baseUrl +
					"/user/search?limit=" +
					limit +
					"&offset=" +
					offset;
			} else {
				urlPath =
					environment.baseUrl +
					"/user/search?limit=" +
					limit +
					"&offset=" +
					offset +
					"&organization_id=" +
					this.organization_id;
			}
		} else {
			urlPath =
				environment.baseUrl +
				"/user/search?limit=" +
				limit +
				"&offset=" +
				offset +
				"&organization_id=" +
				this.organization_id;
		}
		if (search != "") {
			// search=""+search;
			urlPath += `&search_text=${encodeURIComponent(search.trim())}`;
			// userOptions['params']=new HttpParams().set('search_text',search);
		}
		if (is_suspend != '') {
			urlPath += "&is_suspend=" + is_suspend;

		}
		if (role != '') {
			urlPath += '&role=' + role;
		}
		if (org_role_id != '') {
			urlPath += '&org_role_id=' + org_role_id;
		}
		return this.http.get(urlPath, userOptions);
	}


	changePassword(req) {
		let urlPath = environment.baseUrl + API_END_POINTS.UPDATE_PASSWORD;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.put(urlPath, req, userOptions);

	}

	clearSession(req) {
		let urlPath = environment.baseUrl + API_END_POINTS.CLEAR_SESSION;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.post(urlPath, req, userOptions);
	}

	getUserData() {
		// let appData = JSON.parse(localStorage.getItem("appData"));
		// if (appData) {
		//   this.user = appData["user"];
		//   this.user.token = appData.token;
		//   this.organization_id = appData.organization_id;
		//   this.user.role = appData.role;
		//   this.user.organization.name = appData["organization"];
		//   this.user.cdn_signature = appData["cdn_signature"];
		//   this.user.cdn_signature_expiry = appData["cdn_signature_expiry"];
		// }

		this.user.token = "";

		let user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			this.user = user;
            if(this.user.role == "partner_admin")
            {
                this.partner_id = this.user.partner_id
            } else {
                this.partner_id = "0"
            }            
		}

		let org = JSON.parse(localStorage.getItem("organization"));
		if (org) {
			this.organization = org;
			this.organization_id = org["organization_id"];
			if (this.user.role != 'superadmin') {
				let admin_settings = JSON.parse(this.organization.admin_settings);
				this.enableSegments = admin_settings["guide_segmentation"] == "1" ? true : false;
				this.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
				this.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
				this.enableSSO = admin_settings['sso'] == 1 ? true : false;
				this.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;
				this.enableDisplayPublishDate = admin_settings['display_publish_date'] == 0 ? false : true;
				this.enableAutomation = admin_settings['myBotEnabled'] == 1 ? true : false;
			}

			if (this.user.role == 'superadmin') {
				let admin_settings = JSON.parse(this.organization.admin_settings);
				this.enableSSO = admin_settings["sso"] == 1 ? true : false;
				this.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
				this.enableSegments = admin_settings["guide_segmentation"] == "1" ? true : false;
				this.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
				this.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true
			}
		}else{
            this.organization_id = "000";
            // this.sidebarOpen = false;
        }

		let token = JSON.parse(localStorage.getItem("token"));
		if (token) {
			this.user.accesstoken = token["accesstoken"];
			this.user.token = token["accesstoken"];
			this.user.refreshtoken = token["refreshtoken"];
			// this.user.user_email=token['email'];
		}
	}

	breadCrumbs = [
		{
			name: this.user.organization.name,
			route: [""],
		},
	];

	setBreadcrumb(linkdata) {
		this.generateBreadCrumb(linkdata);
	}

	generateBreadCrumb(linkdata) {
		this.breadCrumbs = Object.assign([], linkdata);
	}

	forgotPassword(email) {
		let urlPath;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
			}),
		};
		urlPath =
			environment.baseUrl + API_END_POINTS.PASSWORD_RESET +
			"?" +
			"email=" +
			encodeURIComponent(email);
		return this.http.get(urlPath, userOptions);
	}

	verifyOTP(code) {
		let urlPath;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
			}),
		};
		urlPath = environment.baseUrl + API_END_POINTS.VERIFY_OTP + '?code=' + code + '&code_type=otp';
		return this.http.get(urlPath, userOptions);
	}

	resetPassword(body) {
		let urlPath;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
			}),
		};
		urlPath = environment.baseUrl + API_END_POINTS.PASSWORD_RESET;
		return this.http.post(urlPath, body, userOptions);
	}

	show() {
		this.visible.next(true);
	}

	hide() {
		this.visible.next(false);
	}

	isVisible(): Observable<boolean> {
		return this.visible.asObservable().pipe(share());
	}

	addUser(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.ADD_USER;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.post(urlPath, request, userOptions);
	}

	getUser(id) {
		let urlPath = environment.baseUrl + API_END_POINTS.GET_USER + "?user_id=" + id;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.get(urlPath, userOptions);
	}

	editUser(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.EDIT_USER;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.put(urlPath, request, userOptions);
	}

	deleteUser(id) {
		let urlPath = environment.baseUrl + API_END_POINTS.DELETE_USER;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		let httpParams = new HttpParams().set("user_id", id);
		userOptions["params"] = httpParams;
		return this.http.delete(urlPath, userOptions);
	}

	assignRole(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.USER_ASSIGN_ROLE;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.put(urlPath, request, userOptions);
	}

	assignOrgRole(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.USER_ASSIGN_ORG_ROLE;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.put(urlPath, request, userOptions);
	}

	assignOrgRoleGlobal(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.USER_ASSIGN_ORG_ROLE_GLOBAL;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.post(urlPath, request, userOptions);
	}

	suspendUser(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.SUSPEND_USER;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.put(urlPath, request, userOptions);
	}

	lockUser(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.USER_UNLOCK;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.put(urlPath, request, userOptions);
	}

	getUploadedFile() {
		let urlPath =
			environment.baseUrl + API_END_POINTS.GET_UPLOAD_FILE
			+ "?organization_id=" +
			this.organization_id +
			"&file_type=csv&mime=" +
			encodeURIComponent("text/csv");
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.get(urlPath, userOptions);
	}

	uploadFileInFTP(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.UPLOAD_FILE_IN_FTP;

		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.put(urlPath, request, userOptions);
	}

	importUser(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.IMPORT_USER;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.post(urlPath, request, userOptions);
	}

	getTaskStatus(limit, offset, org_id) {
		let urlPath =
			environment.baseUrl + API_END_POINTS.GET_TASK_STATUS +
			"?limit=" +
			limit +
			"&offset=" +
			offset +
			"&organization_id=" +
			parseInt(org_id) + "&task_type=user_import";
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.get(urlPath, userOptions);
	}

	getDummyApi() {
		let urlPath = "http://localhost:8000/youtube_settings";
		let req = { name: "Harsh" };
		return this.http.get(urlPath);
	}

	resendOtp(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.OTP_RESEND;
		return this.http.post(urlPath, request, httpOptions);
	}


	exportUserData(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.EXPORT_USER_DATA;
		const userOptions = {
			headers: new HttpHeaders({
				// AppKey: environment.authKey,
				AccessToken: this.user.token,
			}),
		};
		return this.http.post(urlPath, request, userOptions);
	}

	// setimageData(file,name){
	//   formG
	// }

	addInternaluser(request) {
		let urlPath = environment.baseUrl + API_END_POINTS.ADD_INTERNAL_USER;
		return this.http.post(urlPath, request);
	}

	getOrgList(limit, offset, user_id, partner_id?, search_text?) {
		let urlPath = "";
		if (partner_id !== "0") {
			urlPath = environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_LIST + "?limit=" +
				limit + "&offset=" + offset + "&user_id=" + user_id + "&partner_id=" + partner_id;
		} else {
			urlPath = environment.baseUrl + API_END_POINTS.GET_ORGANIZATION_LIST + "?limit=" +
				limit + "&offset=" + offset + "&user_id=" + user_id;
		}
		if (search_text != "") {
			urlPath = urlPath + "&search_text=" + search_text;
		}
		return this.http.get(urlPath);
	}

	validateInput(event: Event) {
		let el = (event.target) as HTMLInputElement;
		if (!this.pattern.test(el.value)) {
			el.value = el.value.replace(this.replacePattern, "");
		}
	}
    checkLanguage(){
        let user = JSON.parse(localStorage.getItem('user'))        
        let settings = JSON.parse(user['settings'])
        if(null != settings && undefined != settings && settings["preferredLanguage"]){        
        switch (settings["preferredLanguage"]["UILanguagePreference"]) {
            case 'en_US':{
                if(localStorage.getItem('language')!='english'){
                    localStorage.setItem('language', 'english')
                    location.reload();
                }
                break;}
            case 'fr_CA':{                
                if(localStorage.getItem('language')!=='frenchCanadian'){
                    localStorage.setItem('language', 'frenchCanadian')
                    location.reload();
                    }
                break;
                }
            case 'fr_FR':{
                if(localStorage.getItem('language')!='french'){
                    localStorage.setItem('language', 'french')
                    location.reload();
                    }
                break;}
            case 'ja_JP':{
               if(localStorage.getItem('language')!='japanese'){
                 localStorage.setItem('language', 'japanese')
                 location.reload();
                 }
                break;}
            case 'ko_KR':{
                if(localStorage.getItem('language')!='korean'){
                    localStorage.setItem('language', 'korean')
                    location.reload();
                    }
                break;}
            default:{
               localStorage.setItem('language', 'english')
               location.reload();
                break;}
        }
    
    }
	}
	getDashboardBreadCrumb() {
		return {
			name: adminLabels.dashboardBreadCrumb,
			route: ['']
		}
	}
}


