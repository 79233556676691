<div class="d-flex"
[ngStyle]="{
            'padding-top':(source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '16px':''
            }"
        >
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id !== undefined && userService.organization_id !== '000' && userService.sidebarOpen), 'collapsed-view':( userService.organization_id !== undefined && userService.organization_id !== '000' && !userService.sidebarOpen), ' ' : (source === 'topLevelInternalUsers' || source === 'topLevelExternalUsers')}"

        [ngStyle]="{
            'margin-top':(source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '0px':'',
            'padding':(source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '0px':'',

            'background-color': (source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '#ffffff':''
            }"
        >

        <!-- [ngStyle]="{
            'margin-top':(this.source== 'topLevelInternalUsers || this.source== 'topLevelExternalUsers) ? '0px':'',
            'padding':(this.source== 'topLevelInternalUsers || this.source== 'topLevelExternalUsers) ? '0px!important':''
            }" -->
        <app-breadcrumb *ngIf="source!= 'topLevelInternalUsers' && source!= 'topLevelExternalUsers'"
            [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section"
        [ngStyle]="{
            'margin-top':(source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '0px':'',
            'padding':(source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '0px':'',
            'border': (source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? 'none':''
            }"
        >
            <div class="sectionBody"
            [ngStyle]="{
                'margin-top':(source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '0px':'',
                'padding':(source== 'topLevelInternalUsers' || source== 'topLevelExternalUsers') ? '0px':''
                }">
                <ng-container>
                    <div class="d-flex justify-content-end mb-3">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-flex">

                            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                                <span  *ngIf="source!= 'topLevelInternalUsers' && source!= 'topLevelExternalUsers'">
                                    {{this.admin_data.usersBreadCrumbTitle}}
                                </span>
                            </div> 

                            <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right d-flex justify-content-end align-items-center">
                                <div class="input-group w-50 ml-2 d-inline-flex width-300">
                                    <input class="form-control py-2 search" type="search"
                                        placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                        (input)="onInputSearch($event)" (search)="searchUserList()" aria-label="Search"
                                        [(ngModel)]="search_text">
                                    <!-- <span class="input-group-append">
                                        <div class="input-group-text bg-white">
                                            <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                                        </div>
                                    </span> -->
                                </div>
                                <div *ngIf="!is_global"  id="filter" class="filter-dropdown ml-2">
                                    <button class="btn btn-lg pl-0 pr-0 minWidth-43" type="button" id="filter-menu"
                                        data-toggle="dropdown">
                                        <svg-icon src="../../../assets/svgs/filter.svg"></svg-icon>
                                    </button>
                                    <div class="dropdown-menu pb-0" role="menu" aria-labelledby="filter-menu"
                                        id="filter-dropdown-menu" (click)="$event.stopPropagation()">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left px-3 pt-3">
                                            <h6 class="font-weight-bold">{{this.admin_data.filterBy}}</h6>
                                        </div>
                                        <hr>
                                        <div class="form-group col-md-4 text-left d-inline-block">
                                            <label class="font-weight-bold"
                                                for="status">{{this.admin_data.usersVariable2}}</label>
                                            <select #status class="form-control form-control-sm" id="status">
                                                <option value="all">{{this.admin_data.usersVariable3}}</option>
                                                <option value="0">{{this.admin_data.usersVariable4}}</option>
                                                <option value="1">{{this.admin_data.usersVariable5}}</option>
                                            </select>
                                        </div>
                                        <div class=" form-group col-md-4 text-left d-inline-block">
                                            <label class="font-weight-bold"
                                                for="guideRole">{{this.admin_data.usersVariable31}}</label>
                                            <select #role class="form-control form-control-sm" id="guideRole">
                                                <option value="all">{{this.admin_data.usersVariable3}}</option>
                                                <option value="owner">Org Admin</option>
                                                <option value="creator">Creator</option>
                                                <option value="user">Player</option>
                                                <option value="app_admin">App Admin</option>
                                                <!-- <option value="mg_admin">MG Admin</option> -->
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4 text-left d-inline-block">
                                            <label class="font-weight-bold"
                                                for="organizationRole">{{this.admin_data.usersVariable12}}</label>
                                            <select #org_role class="form-control form-control-sm"
                                                id="organizationRole">
                                                <option value="all">{{this.admin_data.usersVariable3}}</option>
                                                <option *ngFor="let role of allRoles" [value]="role['role_id']">
                                                    {{role['title']}}
                                                </option>
                                            </select>
                                        </div>
                                        <div
                                            class="filter-footer col-md-12 text-center d-flex align-items-center justify-content-center p-2">
                                            <button type="button" class="btn btn-sm btn-secondary mx-2 px-4"
                                                (click)="close()">
                                                {{this.admin_data.cancelButtonText}}</button>
                                            <button type="button" class="btn btn-sm btn-primary mx-2 px-4"
                                                (click)="applyFilter(status.value,role.value,org_role.value);$event.stopPropagation()">
                                                {{this.admin_data.apply}}</button>
                                        </div>
                                    </div>
                                </div>
                                <button *ngIf="!is_global" type="button" class="btn btn-primary ml-1"
                                    [routerLink]="['/organization',userService.organization_id,'users','add']">{{this.admin_data.usersVariable27}}</button>
                                <button *ngIf="(is_global && this.source == 'topLevelInternalUsers' )" type="button"
                                    class="btn btn-primary ml-2"
                                    [routerLink]="['/users', 'add' , 'internal']">{{this.admin_data.usersVariable32}}</button>
                                <!-- <button *ngIf="!is_global" type="button" class="btn btn-primary ml-2" (click)="download()">Export Data</button> -->
                                <button *ngIf="!is_global" type="button" class="btn btn-primary ml-2"
                                    [routerLink]="['/organization',userService.organization_id,'users','import']">{{this.admin_data.usersVariable28}}</button>
                            </div>

                        </div>
                    </div>
                </ng-container>

                <app-table [data]="users" [titles]="table_titles" [dataKeys]="table_title_keys" [widths]="col_widths"
                    [source]="source" (edit)="performEdit($event)" (delete)="showModal($event)"
                    (assignRole)="assignRole($event)" (clearSession)="clearSession($event)" [allRoles]="allRoles"
                    (assignOrgRole)="assignOrgRole($event)" (assignOrgRoleGlobal)="assignOrgRoleGlobal($event)"
                    (changeUserStatus)="showStatusModal($event)" (changeUserLock)="showlockModal($event)">
                </app-table>
                <app-pagination [offset]="offset" [totalRecords]="paginationService.total" [currentPage]="paginationService.currentPage"
                    [limit]="limit" (gotoFirstPage)="gotoFirstPage()" (gotoLastPage)="gotoLastPage($event)"
                    (gotoPreviousPage)="prependOffsetAndGetUsers()" (gotoNextPage)="appendOffsetAndGetUsers()" (changeLimit)="changeLimit($event)"
                    (gotoAction)="gotoPage($event)"></app-pagination>
                <br>
                <ng-container *ngIf="users">
                    <ng-container *ngIf="users.length>0">
                        <app-delete-modal
                            [delete_elem]="users[deleteIndex]['first_name']+' '+users[deleteIndex]['last_name']"
                            (onDelete)="deleteUser($event)" [show_email]="users[deleteIndex]['user_email']">
                        </app-delete-modal>
                    </ng-container>
                </ng-container>

                <div *ngIf="users" class="modal fade" id="lockModal" tabindex="-1" role="dialog"
                    aria-labelledby="lockModal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 *ngIf="lockModal" class="modal-title" id="deleteSegModalLabel">
                                    {{this.admin_data.usersVariable29}}</h5>
                                <h5 *ngIf="activeModal" class="modal-title" id="deleteSegModalLabel">
                                    {{this.admin_data.usersVariable30}}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">{{this.admin_data.usersVariable21}}</span>
                                </button>
                            </div>
                            <ng-container *ngIf="users[unlockIndex]">
                                <div class="modal-body">
                                    <div class="m-0 padding15" role="alert">
                                        <h6 *ngIf="lockModal" id="deleteSegModalLabel">
                                            {{this.admin_data.usersVariable22}}</h6>
                                        <h6 *ngIf="activeModal" id="deleteSegModalLabel">
                                            {{this.admin_data.usersVariable23}}
                                        </h6>
                                        <b class="wrap">{{users[unlockIndex]['user_email']}}</b>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="modal-footer justify-content-center">
                                <button type="button" class="btn btn-secondary minWidth-120"
                                    data-dismiss="modal" (click)="lockcloseModal()">
                                    <ng-container *ngIf="lockModal">{{this.admin_data.cancelButtonText}}</ng-container>
                                    <ng-container *ngIf="activeModal">{{this.admin_data.no}}</ng-container>
                                </button>
                                <button *ngIf="lockModal" type="button" class="btn btn-primary minWidth-120"
                                    (click)="changeUserLock()">{{this.admin_data.usersVariable25}}</button>
                                <button *ngIf="activeModal" type="button" class="btn btn-primary minWidth-120"
                                    (click)="changeUserStatus(selected_user_id)">{{this.admin_data.usersVariable26}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>





    </div>
</div>