//@ts-nocheck
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, BehaviorSubject, debounceTime } from 'rxjs';
import { OrganisationService } from '../../common/services/organisation.service';
import { SSO, SsoListService } from '../../common/services/sso-list.service';
import { SsoService } from '../../common/services/sso.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from '../../common/services/toast-modal.service';
import { UserService } from '../../common/services/user.service';
import { ADD_SSO_LIST_FORMDATA } from '../../constants/FormData/sso_list_formdata';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { PaginationService } from '../../pagination.service';


declare var $: any;

@Component({
  selector: 'app-sso-list',
  templateUrl: './sso-list.component.html',
  styleUrls: ['./sso-list.component.css']
})
export class SsoListComponent implements OnInit {
  org_id;
  selected_org;
  sso_list_formData = JSON.parse(JSON.stringify(ADD_SSO_LIST_FORMDATA));
  formGroup;
  selectedSSO;

  limit = 10;
  offset = 0;

  pages = [1, 2];
  currentPage = 1;
  prevIndex = 0;
  currentIndex = 1;
  total = 0;
  pageLimit: number = 0;
  disableNext = false;
  disablePrev = false;
  showPagination = false;
  search_text = "";
  deleteIndex = 0;
  breadcrumb;
  delete_sso_id;
  onShow = false;
  pageLimitSet = false;
  admin_data = adminLabels ;

    currentTab = 1;
    private gotoPageSubject = new BehaviorSubject<number>(1);

  constructor(public userService: UserService, public ssolistService: SsoListService, private errorResponseService : ErrorResponseService, private toastService: ToastModalService, private titleService: Title, public paginationService: PaginationService, private organisationService: OrganisationService, private route: ActivatedRoute, private ssoService:SsoService,private router: Router) {
    this.titleService.setTitle("SSO | " + "Cornerstone Guide");
    this.userService.getUserData();
    this.paginationService.initializeValues();
    this.organisationService.getCDNData()
    this.userService.activeTab = 'SSO';
    this.route.paramMap.subscribe((params) => {
      this.org_id = params["params"]["org_id"];
      this.userService.organization_id = this.org_id
      if ((this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" ))) {
        this.organisationService
          .getOrganization(this.org_id)
          .subscribe((response) => {
            let organization = response["data"]["organization"];

            this.userService.organization = organization;
            this.userService.organization_id =
              organization["organization_id"];
            // this.userService.user.cdn_signature = organization["cdn_signature"];
            this.userService.organization = organization;
            let admin_settings = JSON.parse(
              this.userService.organization.admin_settings
            );
            this.userService.enableSegments =
              admin_settings["guide_segmentation"] == "1" ? true : false;
            this.userService.enableSSO =
              admin_settings["sso"] == 1 ? true : false;
            this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
            this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

            this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
            let org = organization;
            let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

            cdn_sign["cdn_signature"] = organization["cdn_signature"];
            cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

            this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
            this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

            localStorage.setItem("organization", JSON.stringify(org));
            localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
            this.selected_org = this.userService.organization_id;
            // this.ngOnInit();
          });
      }

      this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("SSO", this.route.url["_value"]);
                this.userService.setBreadcrumb([
                    {   
                       name:'SSO',
                       route:null
                    },
                   ])
                this.breadcrumb=this.userService.breadCrumbs;
            });
    });
  }

  sso_list: SSO[];

  ngOnInit() {
    if (
      this.userService.user.role != "superadmin" ||
      (this.userService.user.role == "superadmin" &&
        this.org_id == this.userService.organization_id)
    ) {
      this.getSSOList();
    }

    let form = {};
    for (let i = 0; i < this.sso_list_formData.length; i++) {
      form[this.sso_list_formData[i].name] = new FormControl("");
      let validations = [];
      for (let v of this.sso_list_formData[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case "required":
            validators.push(Validators.required);
            break;

          case "email":
            validators.push(Validators.email);
            break;
        }
      }
      form[this.sso_list_formData[i].name].setValidators(validators);
    }
    this.formGroup = new FormGroup(form);
    if (this.ssolistService.isEdit) {
      for (let i = 0; i < this.sso_list_formData.length; i++) {
        if (this.ssolistService.selectedSSO[this.sso_list_formData[i].name]) {
          this.formGroup.patchValue({
            [this.sso_list_formData[i].name]: this.ssolistService.selectedSSO[
              this.sso_list_formData[i].name
            ],
          });
        }
      }
    }
      // console.log("h")
      
      this.gotoPageSubject.pipe(
          debounceTime(800))
          .subscribe((page: number) => {
              if (page != this.paginationService.currentPage) {
                  this.offset = this.paginationService.gotoPage(page, this.limit, this.offset);
                  if (this.offset >= 0) this.getSSOList()
              }
          })
  }

  getSSOList() {
    // this.sso_list = [];
    this.ssolistService
      .geSSOList(this.limit,this.offset).subscribe((response) => {
        this.processSSOResponse(response);
        // this.sso_list = response['data']['configurations'];
        if (this.toastService.showToast) {
          this.toastService.showToastMessage(this.toastService.toastType);
        }
      });
  }

  processSSOResponse(response) {
    this.sso_list = response["data"]["configurations"];
    this.deleteIndex = 0;
    this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);        
    this.pageLimitSet = true;
    this.paginationService.setPaginationStatus(this.sso_list, this.limit);
  }

  appendOffsetAndGetSSOList() {
    let offset = this.paginationService.appendOffset(this.limit, this.offset);
    if (offset >= 0) {
      this.offset = offset;
      this.getSSOList();
    }
  }

  prependOffsetAndGetSSOList() {

    let offset = this.paginationService.prependOffset(this.limit, this.offset);
    if (offset >= 0) {
      this.offset = offset;
      this.getSSOList();
    }
  }

  setIndex(ind) {

    let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
    if (offset >= 0) {
        this.offset = offset;
        this.getSSOList();
    }
}

  addSSO() {
    let request = this.formGroup.value;
    request['organization_id'] = this.org_id;
    this.ssolistService.addSSO(request).subscribe((response) => {
      if (!response['error']) {
        this.closeAddSsoModal();
        this.toastService.showToast = true;
        this.toastService.toastType = "add_sso";
        this.pageLimitSet=false;
        this.getSSOList();
      }
    })
  }

  getSSOData(data) {
    // this.showAddSSOModal();
    // this.ssolistService.isEdit = true;
    // this.ssolistService.selectedSSO = role;
    // for (let i = 0; i < this.sso_list_formData.length; i++) {
    //   if (this.ssolistService.selectedSSO[this.sso_list_formData[i].name]) {
    //     this.formGroup.patchValue({
    //       [this.sso_list_formData[i].name]: this.ssolistService.selectedSSO[
    //         this.sso_list_formData[i].name
    //       ],
    //     });
    //   }
    // }

    this.ssoService.selectedSSO = data;
    this.router.navigate(['/organization',
    this.userService.organization_id,
    'sso',this.ssoService.selectedSSO['id']])
  }

  editSSO() {

  this.ssolistService.selectedSSO['name'] = this.formGroup.get('name').value;
  this.ssolistService.selectedSSO['description'] = this.formGroup.get('description').value;
  let request = {
    sso_settings:JSON.stringify(this.ssolistService.selectedSSO),
    sso_id : this.ssolistService.selectedSSO.id
  }
  this.ssoService.updateSSOConfig(request).subscribe(
      (response) => {
          this.resetFormData();
          this.toastService.showToast=true;
          this.toastService.toastType="update_sso";
          this.getSSOList();
          this.closeAddSsoModal();
      },
      (error) => {
      }
  );
  }

  showAddSSOModal() {
    $("#addSSOModal").modal("show");
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    this.ssolistService.isEdit = false;
  }




  closeAddSsoModal() {
    this.resetFormData();
    $("#addSSOModal").modal("hide");
  }

  resetFormData() {
    for (let i = 0; i < this.sso_list_formData.length; i++) {
      this.formGroup.patchValue({
        [this.sso_list_formData[i].name]: "",
      });
    }
  }

  showDeleteModal(id){
      // $("#deleteModal").modal("show");
      this.userService.showModal.next(true);
      this.sso_list.forEach((elem, i) => {
          if (elem.id == id) {
              this.deleteIndex = i;
              this.delete_sso_id = elem.id;
          }
      });
  }

  deleteSSO(name){
    this.ssolistService.deleteSSO(this.delete_sso_id).subscribe((response)=>{
      if(!response['error']){
        this.userService.showModal.next(false);
        this.toastService.showToast = true;
        this.toastService.toastType = "delete_sso";
        if(this.sso_list.length==1 && this.paginationService.currentPage>1){ 
          this.pageLimitSet=false;
          this.prependOffsetAndGetGetSSOList();
      }else{
        this.pageLimitSet=false;
          this.getSSOList();
      }
      }else{
        this.userService.showModal.next(false);
        let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
        this.userService.errorMessage=errorResponse;
        $("#errorModal").modal("show");
      }
    })
  }

    changeTab(val) {
    this.currentTab = val;
    }
    
    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getSSOList();
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getSSOList();
    }
}
