<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">
            <div class="sectionBody">
                <div class="d-flex">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.exportData}}
                    </div>
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
                        <button type="button" class="btn btn-secondary ml-2"
                            (click)="getGuideExportTaskStatus()">{{this.admin_data.refresh}}</button>
                    </div>
                </div>
                <div class="tab-container clearfix">
                    <div class="row m-0 w-90">
                        <div class="page positionRelative">
                            <div class="d-flex my-3">
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-left">
                                    <div
                                        class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-left displayInlineFlex align-items-center">
                                        <app-button [buttonData]="exportService.guide_data_button"
                                            (clickhandler)="exportGuideList()">
                                        </app-button>

                                        <ng-container>
                                            <div *ngIf="appListFlag; then thenBlock else elseBlock"></div>
                                            <ng-template #thenBlock>
                                                <div class="languageAppContainer">
                                                    <div>
                                                        <select class="form-control" style="margin-right: 10px;"
                                                            (change)="changeLanguage($event)">
                                                            <option *ngFor="let lang of translatedLanguage"
                                                                [selected]="isSelectedLanguage(lang)">{{lang['name']}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <!-- <ng-multiselect-dropdown class="col-lg-7 p-0 font14 text-nowrap"
                                                            [placeholder]="appsText" [data]="dropdownList" [(ngModel)]="selectedItems"
                                                            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                                            (onDeSelect)="onDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                                            (onDeSelectAll)="onDeSelectAll($event)"
                                                            (onFilterChange)="onFilterChange($event)">
                                                            {{this.admin_data.selectApplications}}
                                                            </ng-multiselect-dropdown> -->
                                                        <app-multi-checkbox-dropdown
                                                            className="ml-2"
                                                            [placeholder]="appsText"
                                                            componentName="exportGuides" 
                                                            [appList]="dropdownList"
                                                            (updateAppList)="updateAppList($event)"
                                                            selectAll="true"
                                                            [preSelection]="initalSelectedApps"
                                                            ></app-multi-checkbox-dropdown>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template #elseBlock>
                                                <div class="applist-loader">
                                                    <app-loader></app-loader>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-left displayInlineFlex error  color d-flex"
                                        *ngIf="guideExportError">
                                        {{this.admin_data.selectApplicationsMessage}}
                                    </div>
                                </div>
                            </div>

                            <app-table [data]="export_reports" [titles]="exportService.appTitles"
                                [dataKeys]="exportService.appDataKeys" [widths]="exportService.col_widths"></app-table>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>