import { Component, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { DBUser } from '../services/dashboard.service'
import { EventEmitter } from '@angular/core'; import { RoleService } from '../services/role.service';
import { OrganisationService } from '../services/organisation.service';
import { UserService } from '../services/user.service';
import { ApiandservicesService } from '../services/apiandservices.service';
import { ActivityService } from '../services/activity.service';
import adminLabels from 'src/app/constants/ApplicationStrings/trans_utils';
declare const $: any;
declare const jQuery: any;
;

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
    tableData = [];
    dataTable_initialised = false;
    another_dataTable_initialised = false;
    table;
    anotherTable;
    new_data = false;

    guideRoleFilter = [];

    items = {
        a: '#AF9595', b: '#EBADAD', c: '#FE9469', d: '#FFA477', e: '#FFA54D', f: '#ECC084', g: '#C5FF6C', h: '#A1C87A', i: '#8DE5BA',
        j: '#4E9FFE', k: '#D18CFF', l: '#FF4DFF', m: '#C0FF00', n: '#00FF7F', o: '#E9967A', p: '#FFE5B4', q: '#FF66CC',
        r: '#F88379', s: '#FBEC5D', t: '#FAD6A5', u: '#99CCFF', v: '#FFFFCC', w: '#FFFF99', x: '#E0E0E0', y: '#A5BBCA', z: '#BEA5CA'
    };

    @Input('data') set data(value: []) {
        // $('#dataTable').DataTable().clear().destroy()
        this.tableData = value;
        $('.tooltip').remove();
        if (value && value.length > 0 && this.titles && this.dataKeys) {
            if (this.titles.indexOf('Mark All') == -1) {
                let self = this;
                // self.dataTable_initialised=false;
                if (this.anotherOne == '') {
                    if ($.fn.dataTable.isDataTable('#dataTable')) {
                        $('#dataTable').DataTable().clear().destroy();
                        this.new_data = true;
                    }
                }
                $(document).ready(function () {
                    var table: any;
                    // self.dataTable_initialised=false;
                    if (self.titles.indexOf('Activity') == -1 && this.anotherOne == '') {
                        if ($.fn.dataTable.isDataTable('#dataTable')) {
                            // this.new_data=true;
                            table = $('#dataTable').DataTable({
                                columnDefs: [{
                                    targets: 'truncate',
                                    render: $.fn.dataTable.render.ellipsis(120, true)
                                }],
                                'bPaginate': false,
                                "bLengthChange": false,
                                "ordering": false,
                                "bDestroy": true
                            }).columns().header().each(function (value, i) {
                                $(value).css('width', self.widths[i]);
                            });
                            self.dataTable_initialised = true;
                            self.table = table;
                        }
                        else {
                            table = $('#dataTable').DataTable({
                                columnDefs: [{
                                    targets: 'truncate',
                                    render: $.fn.dataTable.render.ellipsis(120, true)
                                }],
                                'bPaginate': false,
                                "bLengthChange": false,
                                "ordering": false,
                                "bDestroy": true
                            }).columns().header().each(function (value, i) {
                                $(value).css('width', self.widths[i]);
                            });
                            self.dataTable_initialised = true;
                            self.table = table;
                        }
                    }
                    if (this.anotherOne != '' && !this.another_dataTable_initialised) {
                        if ($.fn.dataTable.isDataTable('#anotherDataTable')) {
                            table = $('#anotherDataTable').DataTable({
                                columnDefs: [{
                                    targets: 'truncate',
                                    render: $.fn.dataTable.render.ellipsis(120, true)
                                }],
                                'bPaginate': false,
                                "bLengthChange": false,
                                "ordering": false,
                                "bDestroy": true
                            }).columns().header().each(function (value, i) {
                                $(value).css('width', self.widths[i]);
                            });;

                            // $('#anotherDataTable').dataTable( {
                            //     "columnDefs": [
                            //       { "width": "10%", "targets": 0 }
                            //     ]
                            //   } );
                            this.another_dataTable_initialised = true;
                        }
                    }
                    $('[data-toggle="tooltip"]').tooltip();
                });
            }
        }
    };

    datalength = 0;
    api_key_copied = [];
    api_secret_copied = [];

    @Input('dataCopy') set dataCopy(value: []) {
        if (value && value.length) {
            this.all_roles = value;
            this.datalength = this.tableData.length;
            this.checks = new Array(this.tableData.length);
            for (let i = 0; i < this.checks.length; i++) {
                this.checks[i] = false;
            }
        }
        if (this.all_roles && this.assignedRoles) {
            if (this.all_roles.length > 0 && this.assignedRoles.length > 0) {
                this.preSelectRoles();
            }
        }
    }

    all_roles = [];
    assignedRoles = [];
    assignApps = [];
    @Input('app_roles') set app_roles(value: []) {
        this.assignedRoles = value;
        this.preSelectRoles();
        if (this.all_roles && this.assignedRoles) {
            if (this.all_roles.length > 0 && this.assignedRoles.length > 0) {
                this.preSelectRoles();
            }
        }
    }

    @Input('secret_apps') set secret_apps(value: []) {
        this.assignApps = value;
        this.preSelectApps();
        if (this.all_roles && this.assignApps) {
            if (this.all_roles.length > 0 && this.assignedRoles.length > 0) {
                this.preSelectApps();
            }
        }
    }

    @Input('form_checks') set form_checks(value: []) {
        this.preSelectFormChecks();
    }

    widths: []
    @Input('widths') set col_widths(val: []) {
        if (val && val.length > 0) {
            this.dataTable_initialised = false;
            this.widths = val;
        }
    }




    @Input('titles') titles;

    @Input('dataKeys') dataKeys;

    @Input('allRoles') allRoles;

    @Input('disable') disable;

    @Input('anotherOne') anotherOne = '';
    minWidth = ''

    @Input('min-width') set Width(val) {
        this.minWidth = val;
    };

    @Input('source') source;


    checks;

    @Output() edit = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() roles = new EventEmitter();
    @Output() assignOrgRoleGlobal = new EventEmitter();
    @Output() assignRole = new EventEmitter();
    @Output() assignOrgRole = new EventEmitter();
    @Output() changeUserStatus = new EventEmitter();
    @Output() changeUserLock = new EventEmitter();
    @Output() changePublishStatus = new EventEmitter();
    @Output() changeAutomationStatus = new EventEmitter();
    @Output() details = new EventEmitter();
    @Output() showLeaderBoard = new EventEmitter();
    @Output() visibility = new EventEmitter();
    @Output() clearSession = new EventEmitter();

    admin_data = adminLabels
    constructor(
        private roleService: RoleService,
        public organisationService: OrganisationService,
        public userService: UserService,
        private apiAndService: ApiandservicesService,
        private activityService: ActivityService,
        private cdRef: ChangeDetectorRef) {

    }

    ngOnInit() {

        // console.log(this.source)

    }

    ngAfterViewInit() {


    }

    loaded() {
    }


    emitEdit(data) {
        this.edit.emit(data);
        $('.tooltip').remove();
    }

    emitShowLeaderBoard(data) {
        this.showLeaderBoard.emit(data);
    }

    getUserRoleValue(value) {
        switch (value) {
            case 'creator':
                return 'Creator';
            case 'owner':
                return 'Org Admin';
            case 'user':
                return 'Player'; 
            case 'app_admin':
                return 'App Admin'; 
            case 'mg_admin':
                return 'MG Admin';
            case 'superadmin':
                return 'Super Admin';
            case 'partner_admin':
                return 'Partner Admin';
            default:
                return '';
        }
    }

    emitDelete(data) {
        if (data['application_id'] && (this.userService.user.role != "app_admin") && (this.userService.user.role != "owner")) {
            this.delete.emit(data['application_id']);
        }
        if (data['role_id']) {
            this.delete.emit(data['role_id']);
        }
        if (data['id']) {
            this.delete.emit(data['id']);
        }
        if (data['value']) {
            this.delete.emit(data['value']);
        }
        if (data['user_id']) {
            this.delete.emit(data['user_id']);
        }
        if (data['key_id']) {
            this.delete.emit(data['key_id']);
        }
        if (data['domain_id']) {
            this.delete.emit(data['domain_id']);
        }
        if (data['api_name']) {
            this.delete.emit(data['id'])
        }
    }

    emitRole(data) {
        this.roles.emit(data);
    }

    emitOrgWithMapping(data) {
        this.assignOrgRoleGlobal.emit(data);
        $('.tooltip').remove();
    }

    toggleShow(elem) {
        $('.tooltip').remove();
    }

    dothis(prm) {
    }

    mark(isChecked, ind) {
        this.checks[ind] = isChecked;
        if (isChecked) {
            if (this.all_roles[ind]['role_id']) {
                if (this.roleService.assignRoleList.indexOf(this.all_roles[ind]['role_id']) == -1) {
                    this.roleService.assignRoleList.push(this.all_roles[ind]['role_id']);
                }
            }
            if (this.all_roles[ind]['application_id']) {
                if (this.apiAndService.assignApplications.indexOf(this.all_roles[ind]['application_id']) == -1) {
                    this.apiAndService.assignApplications.push(this.all_roles[ind]['application_id']);
                }
            }
        } else {
            if (this.all_roles[ind]['role_id']) {
                if (this.roleService.assignRoleList.indexOf(this.all_roles[ind]['role_id']) != -1) {
                    this.roleService.assignRoleList.splice(this.roleService.assignRoleList.indexOf(this.all_roles[ind]['role_id']), 1);
                }
            }
            if (this.all_roles[ind]['application_id']) {
                if (this.apiAndService.assignApplications.indexOf(this.all_roles[ind]['application_id']) != -1) {
                    this.apiAndService.assignApplications.splice(this.apiAndService.assignApplications.indexOf(this.all_roles[ind]['application_id']), 1);
                }
            }
        }
        this.checkSelectAllBox();
    }

    markAll(isChecked) {
        for (let i = 0; i < this.checks.length; i++) {
            if (isChecked) {
                this.checks[i] = true;
            } else {
                this.checks[i] = false;
            }
        }
        if (isChecked) {
            for (let i = 0; i < this.all_roles.length; i++) {
                if (this.all_roles[i]['role_id']) {
                    if (this.roleService.assignRoleList.indexOf(this.all_roles[i]['role_id']) == -1) {
                        this.roleService.assignRoleList.push(this.all_roles[i]['role_id']);
                    }
                }
                if (this.all_roles[i]['application_id']) {
                    if (this.apiAndService.assignApplications.indexOf(this.all_roles[i]['application_id']) == -1) {
                        this.apiAndService.assignApplications.push(this.all_roles[i]['application_id']);
                    }
                }
            }
        } else {
            for (let i = 0; i < this.all_roles.length; i++) {
                if (this.all_roles[i]['role_id']) {
                    if (this.roleService.assignRoleList.indexOf(this.all_roles[i]['role_id']) != -1) {
                        this.roleService.assignRoleList.splice(this.roleService.assignRoleList.indexOf(this.all_roles[i]['role_id']), 1);
                    }
                }
                if (this.all_roles[i]['application_id']) {
                    if (this.apiAndService.assignApplications.indexOf(this.all_roles[i]['application_id']) != -1) {
                        this.apiAndService.assignApplications.splice(this.apiAndService.assignApplications.indexOf(this.all_roles[i]['application_id']), 1);
                    }
                }
            }
        }
    }

    checkSelectAllBox() {
        let counter = 0;
        for (let i = 0; i < this.checks.length; i++) {
            if (this.checks[i] == true) {
                counter++;
            }
        }

        if (this.tableData.length > 0 && this.tableData.length == counter) {
            return true;
        }
    }

    preSelectRoles() {
        this.initialiseChecks();

        this.all_roles.forEach((data, i) => {
            if (this.roleService.assignRoleList.indexOf(data['role_id']) != -1) {
                this.checks[i] = true;
            }
        })
    }

    preSelectFormChecks() {
        this.initialiseChecks();
    }

    preSelectApps() {
        this.initialiseChecks();
        this.all_roles.forEach((data, i) => {
            if (this.apiAndService.assignApplications.indexOf(data['application_id']) != -1) {
                this.checks[i] = true;
            }
        })
    }

    initialiseChecks() {
        this.checks = new Array(this.datalength);
        for (let i = 0; i < this.checks.length; i++) {
            this.checks[i] = false;
        }
    }

    emitAssignRole(value, data) {
        data['role'] = value;
        this.assignRole.emit(data);
    }

    emitOrgAssignRole(value, data) {
        data['org_role_id'] = value;
        this.assignOrgRole.emit(data);
    }

    emitDetails(data) {
        this.details.emit(data);
    }

    changeStatus(data) {
        let request = {
            'user_id': data['user_id']
        }
        this.changeUserStatus.emit(request);
    }

    changeLock(data) {
        let request = {
            'user_id': data['user_id']
        }
        this.changeUserLock.emit(request);
    }

    emitPublishStatus(data, value) {
        let request = {
            'id': data['id']
        }
        this.changePublishStatus.emit(request);
    }

    emitAutomationStatus(data) {
        this.changeAutomationStatus.emit(data);
    }

    copySuccess(ind, type) {
        this.api_secret_copied = [];
        this.api_key_copied = [];
        for (let i = 0; i < this.tableData.length; i++) {
            this.api_key_copied.push(false);
            this.api_secret_copied.push(false)
        }
        if (type == 'api_key') {
            this.api_key_copied[ind] = true;
        } else {
            this.api_secret_copied[ind] = true;
        }
    }

    ngAfterViewChecked() {
        if (this.tableData) {
            let table;
            let anotherTable;
            let self = this;
            if (this.tableData.length > 0 && !this.dataTable_initialised && this.anotherOne == '') {
                if ($.fn.dataTable.isDataTable('#dataTable')) {
                    table = $('#dataTable').DataTable({
                        columnDefs: [{
                            targets: 'truncate',
                            render: $.fn.dataTable.render.ellipsis(120, true)
                        }],
                        'bPaginate': false,
                        "bLengthChange": false,
                        "ordering": false,
                        "bDestroy": true
                    }).columns().header().each(function (value, i) {
                        $(value).css('width', self.widths[i]);
                    });
                }
                else {
                    table = $('#dataTable').DataTable({
                        columnDefs: [{
                            targets: 'truncate',
                            render: $.fn.dataTable.render.ellipsis(120, true)
                        }],
                        'bPaginate': false,
                        "bLengthChange": false,
                        "ordering": false,
                        "bDestroy": true
                    }).columns().header().each(function (value, i) {
                        $(value).css('width', self.widths[i]);
                    });
                }
                this.dataTable_initialised = true;
                self.table = table;
                this.new_data = false;
            }
            if (this.tableData.length > 0 && !this.dataTable_initialised && !this.another_dataTable_initialised) {
                if (this.anotherOne != '') {
                    let table;
                    anotherTable = $('#anotherDataTable').DataTable({
                        columnDefs: [{
                            targets: 'truncate',
                            render: $.fn.dataTable.render.ellipsis(120, true)
                        }],
                        'bPaginate': false,
                        "bLengthChange": false,
                        "ordering": false,
                        "bDestroy": true,
                    }).columns().header().each(function (value, i) {
                        $(value).css('width', self.widths[i]);
                    });;
                }
                this.another_dataTable_initialised = true;
                self.anotherTable = anotherTable;

            }

            if (this.tableData.length > 0 && this.dataTable_initialised && this.new_data) {
                if ($.fn.dataTable.isDataTable('#dataTable')) {
                    table = $('#dataTable').DataTable({
                        columnDefs: [{
                            targets: 'truncate',
                            render: $.fn.dataTable.render.ellipsis(120, true)
                        }],
                        'bPaginate': false,
                        "bLengthChange": false,
                        "ordering": false,
                        "bDestroy": true
                    }).columns().header().each(function (value, i) {
                        $(value).css('width', self.widths[i]);
                    });
                } else {
                    table = $('#dataTable').DataTable({
                        columnDefs: [{
                            targets: 'truncate',
                            render: $.fn.dataTable.render.ellipsis(120, true)
                        }],
                        'bPaginate': false,
                        "bLengthChange": false,
                        "ordering": false,
                        "bDestroy": true
                    }).columns().header().each(function (value, i) {
                        $(value).css('width', self.widths[i]);
                    });
                }

                self.dataTable_initialised = true;
                self.new_data = false;
                self.table = table;
            }
            if (this.table) {
                this.table.columns().header().each(function (value, i) {
                    $(value).css('width', self.widths[i]);
                });

                if (this.userService.user.role == 'mg_admin' || this.userService.user.role == 'superadmin') {
                    this.guideRoleFilter = [
                        { selectedValue: 'owner', value: 'Org Admin', key: 'owner' },
                        { selectedValue: 'creator', value: 'Creator', key: 'creator' },
                        { selectedValue: 'user', value: 'Player', key: 'user' },
                        { selectedValue: 'app_admin', value: 'App Admin', key: 'app_admin' },
                        { selectedValue: 'mg_admin', value: 'MG Admin', key: 'mg_admin' }
                    ]
                } 
                else {
                    if(this.userService.user.role == 'app_admin' ){
                        this.guideRoleFilter = [
                            { selectedValue: 'creator', value: 'Creator', key: 'creator' },
                            { selectedValue: 'user', value: 'Player', key: 'user' },
                            { selectedValue: 'app_admin', value: 'App Admin', key: 'app_admin' },
                        ]
                    } else {
                        this.guideRoleFilter = [
                            { selectedValue: 'owner', value: 'Org Admin', key: 'owner' },
                            { selectedValue: 'creator', value: 'Creator', key: 'creator' },
                            { selectedValue: 'user', value: 'Player', key: 'user' },
                            { selectedValue: 'app_admin', value: 'App Admin', key: 'app_admin' },
                        ]
                    }
                    

                }
            }

            // if(this.anotherTable ){
            //     this.anotherTable.columns().header().each(function(value,i){
            //         $(value).css('width',self.widths[i]);
            //     });
            // }
        }
        this.cdRef.detectChanges();
    }

    emitVisibility(data) {
        this.visibility.emit(data);
    }

    // isPiiDataHashed(settings: string) {
    //     if (settings) {
    //         let data = JSON.parse(settings);
    //         return data.is_pii_data_hashed;
    //     }

    //     return false;
    // }

    showCopiedDiv(i, k) {

        $('#copiedText_' + i + '_' + k).css('display', 'block');

        setTimeout(() => {
            $('#copiedText_' + i + '_' + k).css('display', 'none');
        }, 1000);
    }

    setSelectedRole(key, roles) {
        let hasRole = false;
        let roleTitle = '';

        roles.forEach((r) => {
            if (r['role_id'] == key) {
                hasRole = true;
                roleTitle = r['title']
            }
        })
        if (hasRole) {
            return roleTitle;
        } else {
            return roleTitle;
        }
    }

    getWidth() {
        if(window.location.pathname.includes('users') || window.location.pathname.includes('application')){
            return 'overflow-x-table'
        }
    }
}
