<div class="d-flex">
    <app-spinner *ngIf="!loader"></app-spinner>
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">

            <div class="sectionBody">
                <div class="d-flex mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.manageSegments}}
                    </div>
                </div>

                <ng-container>
                    <div *ngIf="loader; then thenBlock"></div>
                    <ng-template #thenBlock>
                        <ul class="nav nav-tabs" id="segmentsTab" role="userTabsList">
                            <li class="nav-item" (click)="changeTab('guides')">
                                <a class="nav-link custom-nav-link " id="guides-tab" data-toggle="tab" role="tab"
                                    [ngClass]="{'active' : (curr_tab == 'guides')}" aria-controls="guidesTab"
                                    aria-selected="true">{{this.admin_data.guides}}</a>
                            </li>
                            <li class="nav-item" (click)="changeTab('segments')">
                                <a class="nav-link custom-nav-link" id="segments-tab" data-toggle="tab" role="tab"
                                    [ngClass]="{'active' : (curr_tab == 'segments')}" aria-controls="segments"
                                    aria-selected="true">{{this.admin_data.segments}}</a>
                            </li>
                        </ul>
                        <div class="bg-white" style="display: flex; flex-direction: column;">
                            <div *ngIf="curr_tab === 'guides'">
                                <div class="d-flex justify-content-end my-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-right ">
                                        <div *ngIf="curr_tour_ids.size>0" class="bulk-add-keyword">
                                            <button class="btn btn-primary ml-2"
                                                (click)="openModalForBulkupdate()">{{admin_data.assignSegments}}</button>
                                            <button class="btn btn-secondary ml-2"
                                                (click)="onCheckboxClick(false, -1)">{{admin_data.clear}}</button>
                                        </div>
                                        <div class="form-group text-left d-inline-block">
                                            <div class="d-flex flex-row align-items-end">
                                                <select #app class="form-control" id="app"
                                                    (change)="appChanged($event.target.value)">
                                                    <option *ngFor="let app of apps" [value]="app['application_id']"
                                                        [selected]="this.curr_app_id===app['application_id']">
                                                        {{app['name']}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div id="filter" class="filter-dropdown ml-2">
                                            <button class="btn btn-lg pl-0 pr-0 minWidth-43" type="button"
                                                id="filter-menu" data-toggle="dropdown">
                                                <svg-icon src="../../../assets/svgs/filter.svg"></svg-icon>
                                            </button>
                                            <div class="dropdown-menu pb-0" role="menu" aria-labelledby="filter-menu"
                                                id="filter-dropdown-menu" (click)="$event.stopPropagation()">
                                                <div
                                                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left px-3 pt-3">
                                                    <h6 class="font-weight-bold">{{this.admin_data.filterBy}}</h6>
                                                </div>
                                                <hr>
                                                <div class=" form-group col-md-4 text-left d-inline-block">
                                                    <label class="font-weight-bold"
                                                        for="status">{{this.admin_data.usersVariable2}}</label>
                                                    <select #status class="form-control form-control-sm" id="status">
                                                        <option value="" [selected]="curr_status===''">
                                                            {{this.admin_data.usersVariable3}}</option>
                                                        <option value="1" [selected]="curr_status==='1'">
                                                            {{this.admin_data.published}}</option>
                                                        <option value="0" [selected]="curr_status==='2'">
                                                            {{this.admin_data.unpublished}}</option>
                                                        <!-- <option value="mg_admin">MG Admin</option> -->
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-4 text-left d-inline-block">
                                                    <label class="font-weight-bold"
                                                        for="folder">{{this.admin_data.apiConnectorFolder}}</label>
                                                    <select #folder class="form-control form-control-sm" id="folder">
                                                        <option value="" [selected]="curr_folder===''">
                                                            {{this.admin_data.usersVariable3}}</option>
                                                        <option *ngFor="let folder of folders"
                                                            [value]="folder['category_id']"
                                                            [selected]="curr_folder===folder['category_id']">
                                                            {{folder['name']}}
                                                        </option>
                                                        <!-- <option value="mg_admin">MG Admin</option> -->
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-4 text-left d-inline-block">
                                                    <label class="font-weight-bold"
                                                        for="userFilter">{{this.admin_data.usersVariable8}}</label>
                                                    <select #userFilter class="form-control form-control-sm"
                                                        id="userFilter">
                                                        <option value="" [selected]="curr_user===''">
                                                            {{this.admin_data.usersVariable3}}</option>
                                                        <option *ngFor="let user of users" [value]="user['user_id']"
                                                            [selected]="curr_user===user['user_id']">{{user['name']}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div
                                                    class="filter-footer col-md-12 text-center d-flex align-items-center justify-content-center p-2">
                                                    <button type="button"
                                                        class="btn btn-sm btn-secondary mx-2 px-4"
                                                        (click)="close()">
                                                        {{this.admin_data.cancelButtonText}}</button>
                                                    <button type="button" class="btn btn-sm btn-primary mx-2 px-4"
                                                        (click)="applyFilter(app.value,status.value,folder.value, userFilter.value);$event.stopPropagation()">
                                                        {{this.admin_data.apply}}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group w-50 ml-1 d-inline-flex width-300">
                                            <input class="form-control py-2 search" type="search"
                                                placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                                (input)="search()" (search)="search()" aria-label="Search"
                                                [(ngModel)]="search_text">
                                            <!-- <span class="input-group-append">
                                                <div class="input-group-text bg-white">
                                                    <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                                                </div>
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex scroll-x w-1240 table-container">
                                    <table *ngIf="tours.length>0" class="table  table-bordered break-w">
                                        <colgroup>
                                            <col style="width: 5%;">
                                            <col style="width: 20%;">
                                            <col style="width: 10%;">
                                            <col style="width: 10%;">
                                            <col style="width: 30%;">
                                            <col style="width: 5%;">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <label class="custom-check">
                                                        <input type="checkbox" name="all" id="allCheck"
                                                        (change)="onCheckboxClick($event.target.checked,-1)">
                                                        <span class="checkmark mr-3"></span>
                                                    </label>
                                                </th>
                                                <th *ngFor="let header of tableHeading; index as i">
                                                    {{header}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let tour of tours; index as i">
                                                <td>
                                                    <label class="custom-check">
                                                        <input type="checkbox" name="" id='tour'
                                                        (change)="onCheckboxClick($event.target.checked,i)"
                                                        [checked]="getCheckedStatus(i)">
                                                        <span class="checkmark mr-3"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <div class="data-wrapper">
                                                        <img class="guide-logo" [src]="getIcon(tour)">
                                                        <span>{{tour['tour_title']}}</span>
                                                    </div>
                                                </td>
                                                <td>{{tour['category_title']}}</td>
                                                <td>{{getCreatorName(tour['creator_id'])}}</td>
                                                <td class="tags">
                                                    <ng-container *ngIf="tour['segment_groups']">
                                                        <ng-container
                                                            *ngFor="let item of tour['segment_groups']; index as i">
                                                            <span *ngIf="i < 3 && segmentMap.get(item) !== undefined"
                                                                class="badge rounded-pill badge-secondary font14 h-3">{{segmentMap.get(item)}}<span
                                                                    class="crossIcon" role="button"
                                                                    (click)="deleteSegment(tour['tour_id'],tour['segment_groups'][i])">
                                                                    &#10006;</span></span>
                                                            <span
                                                                *ngIf="i==3 && segmentMap.get(item) !== undefined">...</span>
                                                        </ng-container>
                                                    </ng-container>
                                                <td><svg-icon src="../../../assets/svgs/emit_edit.svg"
                                                        (click)="openModal(i)" role="button"></svg-icon></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="tours.length == 0" class="no-guide">
                                        <img src="./../../assets/svgs/no_guides_image.svg">
                                        <div class="no-guide-label-1">
                                            <p>{{this.admin_data.noGuideFound}}</p>
                                        </div>
                                        <div class="no-guide-label-2">
                                            <p>{{this.admin_data.modifySearchCriteria}}</p>
                                        </div>
                                    </div>
                                </div>
                                <app-pagination [offset]="offset" [totalRecords]="paginationService.total" [currentPage]="paginationService.currentPage"
                                    [limit]="limit" (gotoFirstPage)="gotoFirstPage()" (gotoLastPage)="gotoLastPage($event)"
                                    (gotoPreviousPage)="prependOffset()" (gotoNextPage)="appendOffset()" (changeLimit)="changeLimit($event)"
                                    (gotoAction)="gotoPage($event)"></app-pagination>
                            </div>
                            <div *ngIf="curr_tab === 'segments'">
                                <div class="d-flex justify-content-end my-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9 p-0 text-right ">
                                        <div class="input-group w-50 ml-2 d-inline-flex width-300">
                                            <input class="form-control py-2 search" type="search"
                                                placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                                                (input)="searchSegments()" (search)="searchSegments()"
                                                aria-label="Search" [(ngModel)]="search_text_segment">
                                            <!-- <span class="input-group-append">
                                                <div class="input-group-text bg-white">
                                                    <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                                                </div>
                                            </span> -->
                                        </div>
                                        <button type="button" class="btn btn-primary ml-2"
                                            [routerLink]="['/segments/add']">{{this.admin_data.addASegment}}</button>
                                    </div>
                                </div>
                                <div class="d-flex scroll-x w-1240 table-container p-2">
                                    <table class="table  table-bordered break-w">
                                        <thead>
                                            <tr>
                                                <th>{{this.admin_data.segmentTitle}}</th>
                                                <th>{{this.admin_data.tableHeaderActions}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of tableData">
                                                <td>{{item['title']}}</td>
                                                <td>
                                                    <div class="flex mx-2">
                                                        <svg-icon role="button"
                                                            (click)="editGroup(item['group_id'], $event)"
                                                            src="../../../assets/svgs/emit_edit.svg"></svg-icon>
                                                        <svg-icon role="button"
                                                            (click)="openModalForDelete(item['group_id'])"
                                                            src="../../../assets/svgs/emit_delete.svg"></svg-icon>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="this.taskSubscription && !this.taskSubscription.closed" class="loading-div w-1240">
                            <div class="loading">
                                <img class="spinner-img img-fluid loading-img"
                                    src="../../../assets/images/g_new_loader.gif" />
                            </div>
                            <div>
                                <span>{{admin_data.segmentLoading}}</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </section>

        <div class="modal fade" tabindex="-1" role="dialog" id="assignSegments" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">{{admin_data.addSegment}}</h6>
                    </div>
                    <div class="modal-body font14 tags">
                        <div class="d-inline-flex w-100 justify-content-around align-items-center">
                            <!-- // <input type="text" class="form-control" [placeholder]="admin_data.addKeywords" (keydown)="keyPressed($event)" [(ngModel)]="keyword"> -->
                            <!-- <input type="text" value="Istanbul, Adana, Adiyaman, Afyon, Agri, Aksaray, Ankara" data-role="tagsinput" class="form-control" /> -->
                            <div style="width: 95%;"> <tag-input [(ngModel)]='curr_segments'
                                    [placeholder]="admin_data.selectSegment"
                                    [secondaryPlaceholder]="admin_data.selectSegment" [onlyFromAutocomplete]="true"
                                    [theme]="'foundation-theme'" [(inputText)]="blankString"
                                    (onAdd)="updateDisabledProp(false)" (onRemove)="updateDisabledProp(false)">
                                    <tag-input-dropdown [showDropdownIfEmpty]="true" [dynamicUpdate]="false"
                                        [focusFirstElement]="true" [autocompleteItems]='values' [appendToBody]="false">
                                        <!-- <ng-template let-item="item" let-index="index">
                                    ({{ index }})
                                    {{ item.key }}:
                                    {{ item.value }}
                                </ng-template> -->
                                    </tag-input-dropdown>
                                </tag-input>
                            </div>
                            <div>
                                <div data-bs-toggle="tooltip" data-placement="right"
                                    title="{{this.admin_data.segmentTooltip}}">
                                    <!-- <span class="tooltiptext">{{this.admin_data.brandLogoTooltip}}</span> -->
                                    <svg-icon src="../../assets/svgs/icon-info.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-end">
                        <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                            (click)="closeModal()">{{this.admin_data.cancelButtonText}}</button>
                        <button type="button" class="btn btn-primary minWidth-120 mx-2" [disabled]="disableUpdateTag"
                            (click)="addSegments()">{{this.admin_data.updateButtonText}}</button>
                        <!-- <app-button [buttonData]="userService.delete_button" (clickhandler)="delete()"></app-button>
                                    <button class="btn btn-primary" (click)="delete()">{{modalOkValue}}</button>-->
                    </div>
                </div>

            </div>
        </div>
        <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
            [modal_ok_value]="modalOkText" [org]="" (onDelete)="deleteGroup($event)"></app-modal-confirmation>
    </div>