//@ts-nocheck
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { RoleService, Roles } from "../common/services/role.service";
import { UserService } from "../common/services/user.service";
import { ADD_ROLES_FORMDATA } from "../constants/FormData/add_roles";
import { ToastrService } from 'ngx-toastr';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { OrganisationService } from "../common/services/organisation.service";
import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { PaginationService } from '../pagination.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../common/services/toast-modal.service";
import adminLabels from "../constants/ApplicationStrings/trans_utils";
declare var $: any;

@Component({
    selector: "app-manage-roles",
    templateUrl: "./manage-roles.component.html",
    styleUrls: ["./manage-roles.component.scss"],
})
export class ManageRolesComponent implements OnInit, AfterViewInit {
    limit = 10;
    offset = 0;
    roles: Roles[];
    role_formData = ADD_ROLES_FORMDATA;
    formGroup;
    initializeRole=false;
    appTitles=[];
    appDataKeys=[];

    pages = [1, 2];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    disableNext = false;
    disablePrev = false;
    showPagination = false;
    deleteIndex = 0;
    searchInputRole;
    delete_role_id;
    isDeleting = false;
    assignRole = false;
    selected_id;
    hasPaginated = false;
    previousPageRoles: Roles[] = [];
    org_id;
    selectedOrg;
    prevGetRolesCall;
    prevSearch;
    pageLimitSet = false;
    search = '';
    breadcrumb;
    is_searching=false;
    lastSearchedValue = "";
    admin_data = adminLabels;
    private gotoPageSubject = new BehaviorSubject<number>(1);

    constructor(
        public roleService: RoleService,
        public userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private organisationService: OrganisationService,
        private toastr: ToastrService,
        private titleService: Title,
        public paginationService: PaginationService,
		private errorResponseService : ErrorResponseService,
        private toastService:ToastModalService
    ) {
        this.userService.getUserData();
        this.paginationService.initializeValues();

        this.organisationService.getCDNData()
        this.userService.activeTab = 'manage roles';
        this.route.paramMap.subscribe((params) => {
            if (params.get("app_id")) {
                this.assignRole = true;
                this.setTableHeaders();
                this.userService.activeTab = 'applications';
                this.selected_id = params.get("app_id");
                // this.userService.setBreadcrumbs(
                //     "Role-assign:",
                //     this.route.url["_value"]
                // );
                // this.roleService.appTitles = [
                //     this.admin_data.tableHeaderMarkAll,
                //     this.admin_data.tableHeaderTitle,
                //     this.admin_data.tableHeaderDescription,
                //     this.admin_data.tableHeaderCreationDate,
                //     this.admin_data.tableHeaderModificationDate,
                // ];
                // this.roleService.appDataKeys = [
                //     "check",
                //     "title",
                //     "description",
                //     "creation_date",
                //     "modification_date",
                // ];

                this.roleService.col_widths=['10%','20%','30%','20%','20%'];
                this.titleService.setTitle(this.admin_data.applications + " | " + "Cornerstone Guide");
                this.userService.activeTab = 'applications';
            } else {
                this.assignRole = false;
                this.setTableHeaders();
                // this.roleService.appTitles = [
                //     this.admin_data.tableHeaderTitle,
                //     this.admin_data.tableHeaderDescription,
                //     this.admin_data.tableHeaderCreationDate,
                //     this.admin_data.tableHeaderModificationDate,
                //     this.admin_data.tableHeaderActions
                // ];
                // this.roleService.appDataKeys = [
                //     "",
                //     "title",
                //     "description",
                //     "creation_date",
                //     "modification_date",
                //     "role_action"
                // ];
                this.roleService.col_widths=['25%','25%','20%','20%','10%'];
                this.titleService.setTitle(this.admin_data.manageRoles + " | " + "Cornerstone Guide");
                this.userService.activeTab = 'manage roles';
            }

            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" )) {
                this.organisationService
                    .getOrganization(this.org_id)
                    .subscribe((response) => {
                        let organization = response["data"]["organization"];

                        this.userService.organization = organization["name"];
                        this.userService.organization_id =
                            organization["organization_id"];
                        // this.userService.user.cdn_signature = organization["cdn_signature"];
                        this.userService.organization = organization;
                        let admin_settings = JSON.parse(
                            this.userService.organization.admin_settings
                        );
                        this.userService.enableSegments =
                            admin_settings["guide_segmentation"] == "1" ? true : false;
                        this.userService.enableSSO =
                            admin_settings["sso"] == 1 ? true : false;
                        this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                        this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;



                        let org = organization;
                        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                        cdn_sign["cdn_signature"] = organization["cdn_signature"];
                        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                        localStorage.setItem("organization", JSON.stringify(org));
                        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                        this.selectedOrg = this.userService.organization_id;
                        // this.ngOnInit();
                    });
            }else{
                // this.ngOnInit();
            }

            this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "Manage Roles",
                //     this.route.url["_value"]
                // );
                if(this.assignRole){
                this.userService.setBreadcrumb([
                    {
                    name:this.admin_data.applications,
                    route:['/organization',this.org_id,'application']
                    },
                    {
                        name:this.admin_data.manageRoleVariable1,
                        route:null
                    }
                ])
            }else{
                this.userService.setBreadcrumb([
                    {
                        name:this.admin_data.manageRoles,
                        route:null
                    }
                ])
            }
            this.breadcrumb=this.userService.breadCrumbs;
            });
        });

    }

    ngAfterViewInit() {
        this.searchInputRole = document.getElementById("searchInputApp");
    }

    ngOnInit() {
        this.is_searching=false;
        
        this.getRoleList();
        let form = {};
        for (let i = 0; i < this.role_formData.length; i++) {
            form[this.role_formData[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.role_formData[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                }
            }
            form[this.role_formData[i].name].setValidators(validators);
        }
        this.formGroup = new FormGroup(form);
        let inputValidateField = ["title", "description"];
        for(let ind=0; ind<inputValidateField.length; ind++){
        this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
            if (x && !this.userService.pattern.test(x)) {
            this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""),{ emitEvent: false });
            }
        });
        }
        if (this.roleService.isEdit) {
            for (let i = 0; i < this.role_formData.length; i++) {
                if (this.roleService.selectedRole[this.role_formData[i].name]) {
                    this.formGroup.patchValue({
                        [this.role_formData[i].name]: this.roleService.selectedRole[
                            this.role_formData[i].name
                        ],
                    });
                }
            }
        }

        this.gotoPageSubject.pipe(
            debounceTime(800))
            .subscribe((page: number) => {
                if (page != this.paginationService.currentPage) {
                    this.offset = this.paginationService.gotoPage(page, this.limit, this.offset);
                    if (this.offset >= 0) { this.getRoleList(); }
                }
            })
    }

    getRoleList() {
        if (this.assignRole) {
            this.getAllRoles();
        } else {
            // let search;
            // if (this.searchInputRole) {
            //     // this.search = this.searchInputRole.value;
            //     if (this.search != '' && this.prevSearch != this.search) {
            //       this.paginationService.initializeValues();
            //     }
            //     this.prevSearch = this.search;
            // }
            if (this.prevGetRolesCall) {
                clearTimeout(this.prevGetRolesCall);
            }

            this.prevGetRolesCall = setTimeout(()=>{this.roleService
                .getRoles(this.limit, this.offset, this.search)
                .subscribe((response) => {
                    // if (!this.pageLimitSet) {
                        this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
                        this.pageLimitSet = true;
                    // }

                    this.roles = response["data"]["organizationRoleList"];
                    // this.roles = this.roles.reverse();

                    this.userService.showLoader = this.userService.showLoader
                        ? false
                        : true;
                    this.deleteIndex = 0;

                    this.paginationService.setPaginationStatus(this.roles, this.limit);

                    if(this.toastService.showToast){
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }

                    this.previousPageRoles = this.roles;
                });
        },this.is_searching?300:0)
    }
}

    setTableHeaders(){
        this.roles=null;
        this.roleService.appTitles=[];
        this.roleService.appDataKeys=[];
        if(this.assignRole){
            this.roleService.appTitles = Object.assign([],[
                this.admin_data.tableHeaderMarkAll,
                this.admin_data.tableHeaderTitle,
                this.admin_data.tableHeaderDescription,
                this.admin_data.tableHeaderCreationDate,
                this.admin_data.tableHeaderModificationDate,
            ]);
            this.roleService.appDataKeys = Object.assign([],[
                "check",
                "title",
                "description",
                "creation_date",
                "modification_date",
            ]);
            this.roleService.col_widths=['10%','20%','30%','20%','20%'];

        }else{
            if(this.userService.user.role == 'app_admin'){
                this.roleService.appTitles = Object.assign([],[
                    this.admin_data.tableHeaderTitle,
                    this.admin_data.tableHeaderDescription,
                    this.admin_data.tableHeaderCreationDate,
                    this.admin_data.tableHeaderModificationDate,
                ]);
                this.roleService.appDataKeys = Object.assign([],[
                    '',
                    'title', 
                    'description', 
                    'creation_date', 
                    'modification_date', 
                ]);
                this.roleService.col_widths=['25%','25%','25%','25%'];
            } else {
                this.roleService.appTitles = Object.assign([],[
                    this.admin_data.tableHeaderTitle,
                    this.admin_data.tableHeaderDescription,
                    this.admin_data.tableHeaderCreationDate,
                    this.admin_data.tableHeaderModificationDate,
                    this.admin_data.tableHeaderActions
                ]);
                this.roleService.appDataKeys = Object.assign([],[
                    '',
                    'title', 
                    'description', 
                    'creation_date', 
                    'modification_date', 
                    'role_action'
                ]);
                this.roleService.col_widths=['25%','25%','20%','20%','10%'];
            }
            
        }
    }


    getAllRoles() {
        let self = this;
        var promise = new Promise((resolve, reject) => {
            let roleService = this.roleService;
            let limit = 100;
            let offset = 0;
            let cb = roleService.getRoles(limit, offset);
            let allRoles = [];
            let fetchRows = false;
            let rows;
            function roles() {
                cb.subscribe(
                    (data) => {
                        Array.prototype.push.apply(
                            allRoles,
                            data["data"]["organizationRoleList"]
                        );
                        offset += 100;
                        cb = roleService.getRoles(limit, offset);
                        if (!fetchRows) {
                            rows = data["data"]["total_rows"]
                            fetchRows = true;
                        } else {
                            rows = rows % 100;
                        }
                        if (rows / 100 > 1) {
                            roles();
                        } else {
                            resolve(allRoles);
                        }
                    },
                    (error) => {
                    }
                );
            }
            roles();
        }).then(function (data: []) {
            self.roles = data;
            var promise = new Promise((resolve, reject) => {
                let limit = 100;
                let offset = 0;
                let cb = self.roleService.getRoles(limit, offset, '', self.selected_id);
                let selected_roles = [];
                let fetchRows = false;
                let rows;
                function roles() {
                    cb.subscribe(
                        (data) => {
                            // if (data["data"]["organizationRoleList"].length > 0) {
                            Array.prototype.push.apply(
                                selected_roles,
                                data["data"]["organizationRoleList"]
                            );
                            offset += 100;
                            cb = self.roleService.getRoles(limit, offset, '', self.selected_id);
                            if (!fetchRows) {
                                rows = data["data"]["total_rows"]
                                fetchRows = true;
                            } else {
                                rows = rows % 100;
                            }
                            if (rows / 100 > 1) {
                                roles();
                            } else {
                                resolve(selected_roles);
                            }
                        },
                        (error) => {
                        }
                    );
                }
                roles();
            }).then(function (data: []) {
                self.roleService.applicationRoles = data;
                let roles = [];
                data.forEach((d) => {
                    if (d['app_role_relation_id'] != null) {
                        roles.push(d);
                    }
                })
                roles.forEach((data) => {
                    if (
                        self.roleService.assignRoleList.indexOf(data["role_id"]) == -1
                    ) {
                        self.roleService.assignRoleList.push(data["role_id"]);
                    }
                });
            })
        });
    }

    appendOffsetAndGetRoles() {

        this.is_searching=false;
        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getRoleList();
        }
    }

    prependOffsetAndGetRoles() {
        this.is_searching=false;
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getRoleList();
        }
    }

    setndex(ind, event) {
        this.hasPaginated = true;
        this.is_searching=false;
        if (this.disablePrev && this.disableNext) {
            event.preventDefault();
            return;
        }

        let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getRoleList();
        }

    }

    searchRoleList() {
        // this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.pageLimitSet = false;
        this.is_searching=true;
        this.getRoleList();
    }

    showAddRoleModal() {
        $("#addRoleModal").modal("show");
        this.resetFormData();
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
        this.roleService.isEdit=false;
    }

    closeAddRoleModal() {
        this.resetFormData();
        $("#addRoleModal").modal("hide");
    }

    addRoles(value) {
        this.roleService.add_button.disable=true;
        this.roleService.add_button.text=this.admin_data.rolesAddingRoleButtonText;
        this.is_searching=false;
        let request = {
            title: this.formGroup.get("title").value.trim(),
            description: this.formGroup.get("description").value.trim(),
            organization_id: this.userService.organization_id,
        };
        this.userService.showLoader = this.userService.showLoader ? false : true;
        this.roleService.addRoles(request, this.limit, this.offset).subscribe(
            (response) => {
                if(response['error']){
                    this.closeAddRoleModal();
                    this.resetFormData();
                    this.roleService.add_button.disable=false;
                    this.roleService.add_button.text=this.admin_data.rolesAddRoleButtonText
                    let errorResponse = this.errorResponseService.getErrorResponse(response);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                } else {
                    this.closeAddRoleModal();
                    this.roleService.add_button.disable=false;
                    this.roleService.add_button.text=this.admin_data.rolesAddRoleButtonText
                    this.pageLimitSet=false;
                    this.toastService.showToast=true;
                    this.toastService.toastType="add_role";
                    if(this.roles.length==this.limit && this.paginationService.currentPage==this.paginationService.pageLimit){
                        this.paginationService.calculatePageLimit((this.offset+this.limit)+1, this.limit);
                        this.appendOffsetAndGetRoles();
                    }else{
                    this.getRoleList();
                    }
                    this.getRoleList();
                }
            },
            (error) => {
                this.roleService.add_button.disable=false;
                this.roleService.add_button.text=this.admin_data.rolesAddRoleButtonText
            }
        );
    }

    getRoleData(role) {
        this.showAddRoleModal();
        this.roleService.isEdit = true;
        this.roleService.selectedRole = role;
        for (let i = 0; i < this.role_formData.length; i++) {
            if (this.roleService.selectedRole[this.role_formData[i].name]) {
                this.formGroup.patchValue({
                    [this.role_formData[i].name]: this.roleService.selectedRole[
                        this.role_formData[i].name
                    ],
                });
            }
        }
    }

    editRole(value) {
        this.roleService.update_button.disable=true;
        this.roleService.update_button.text=this.admin_data.rolesUpdatingRoleButtonText;
        this.is_searching=false;
        let request = {
            organization_role_id: parseInt(this.roleService.selectedRole.role_id),
            title: this.formGroup.get("title").value.trim(),
            description: this.formGroup.get("description").value.trim(),
            organization_id: this.userService.organization_id,
        };
        this.userService.showLoader = this.userService.showLoader ? false : true;
        this.roleService.editRole(request).subscribe(
            (response) => {
                if(response['error']){
                    let errorResponse = this.errorResponseService.getErrorResponse(response);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                } else {
                    this.closeAddRoleModal();
                    this.roleService.update_button.disable=false;
                    this.roleService.update_button.text=this.admin_data.rolesUpdateRoleButtonText;
                    this.userService.showLoader = this.userService.showLoader
                        ? false
                        : true;
                    this.resetFormData();
                    this.toastService.showToast=true;
                    this.toastService.toastType="update_role";
                    this.getRoleList();    
                }
            },
            (error) => {
                this.closeAddRoleModal(); 
                this.roleService.update_button.disable=false;
                this.roleService.update_button.text=this.admin_data.rolesUpdateRoleButtonText;
                this.is_searching=false;
                this.resetFormData();
            }
        );
    }

    resetFormData() {
        for (let i = 0; i < this.role_formData.length; i++) {
            this.formGroup.patchValue({
                [this.role_formData[i].name]: "",
            });
        }
        this.roleService.selectedRole = null;
    }

    showDeleteModal(role_id) {
        this.userService.showModal.next(true);
        this.roles.forEach((role, i) => {
            if (role.role_id == role_id) {
                this.deleteIndex = i;
                this.delete_role_id = role_id;
            }
        });
    }

    closeDeleteModal() {
        $("#deleteModal").modal("hide");
    }

    deleteRole(role) {
        this.userService.delete_button.disable=true;
        this.userService.delete_button.text=this.admin_data.deletingButtonText;
        this.is_searching=false;
        this.userService.showLoader = this.userService.showLoader ? false : true;
        this.roleService.deleteRole(this.delete_role_id).subscribe(
            (response) => {
                if(response['error']){
                    his.userService.delete_button.disable=false;
                    this.userService.delete_button.text=this.admin_data.deleteButtonText;
                    let errorResponse = this.errorResponseService.getErrorResponse(response);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                } else {
                    this.userService.showLoader = this.userService.showLoader
                        ? false
                        : true;
                    this.userService.delete_button.disable=false;
                    this.userService.delete_button.text=this.admin_data.deleteButtonText;
                    this.userService.showModal.next(false);
                    this.isDeleting = true;
                    this.deleteIndex = 0;
                    this.pageLimitSet=false;
                    this.toastService.showToast=true;
                    this.toastService.toastType="delete_role";
                    if(this.roles.length==1 && this.paginationService.currentPage>1){ 
                        this.prependOffsetAndGetRoles()
                    }else{
                        this.getRoleList();
                    }
                }
            },
            (error) => {
                this.userService.delete_button.disable=false;
                this.userService.delete_button.text=this.admin_data.deleteButtonText;
            }
        );
    }

    assignRoles() {
        this.roleService
            .assignRoleToApplication(
                this.selected_id,
                this.userService.organization_id
            )
            .subscribe((response) => {
                if(response['error']){
                    let errorResponse = this.errorResponseService.getErrorResponse(response);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                } else {
                    this.toastService.showToast=true;
                    this.toastService.toastType="assign_role_app";
                    this.router.navigate([
                        "/organization",
                        this.userService.organization_id,
                        "application",
                    ]);
                }
            });
    }

    validateInput(event: Event) {
        this.userService.validateInput(event);
        if((event.target as HTMLInputElement).value!=this.lastSearchedValue){
            this.searchRoleList();
            this.lastSearchedValue=(event.target as HTMLInputElement).value;
        }
    }

    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }
    gotoFirstPage() {
        this.setndex(1);
    }

    gotoLastPage(event) {
        this.setndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getRoleList(this.curr_app_id);
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getRoleList(this.curr_app_id);
    }
}
