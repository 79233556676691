import { AfterViewChecked, Component } from '@angular/core';
import { UserService } from '../common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Subject, debounceTime, filter, takeUntil } from 'rxjs';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { ApplicationService } from '../common/services/application.service';
import { GuideInventoryService } from '../common/services/guide-inventory.service';
import { OrganisationService } from '../common/services/organisation.service';
import { DatePipe } from '@angular/common';
import { PaginationService } from '../pagination.service';
import { ToastModalService } from "../common/services/toast-modal.service";
import { ErrorResponseService } from '../common/services/error-response.service';
import * as moment from 'moment';
declare var $: any;
declare var jquery: any;
declare var bootstrap: any;

interface GuideData {
    guideTitle: string;
    type: string;
    guideId: Number;
    folder: string;
    createdBy: string;
    created: string;
    modified: string;
    status: string;
    publish_env: any[];
    app_id: string;
    category_id: string;
}

@Component({
    selector: 'app-guide-inventory',
    templateUrl: './guide-inventory.component.html',
    styleUrls: ['./guide-inventory.component.scss']
})
export class GuideInventoryComponent {

    breadcrumb;
    admin_data = adminLabels;
    appsText = 'Applications';
    selectedIndex = -1;
    dropdownList = [];
    selectedItems = [];
    allApplications = [];
    search_text: String;
    private searchTextSubject = new Subject();
    private gotoPageSubject = new BehaviorSubject<number>(1);
    trueValue: Boolean = true;
    GuideTypeMap = new Map<string, string>([
        ["bot", this.admin_data.botGuide],
        ["smartTip", this.admin_data.tooltipGuide],
        ["insights", this.admin_data.featureTags],
        ["onboarding_tour", this.admin_data.tutorialGuide],
        ["walkthrough", this.admin_data.workflowGuide],
    ]);
    currentTourSettings = {};

    envMap = new Map<string, {}[]>();
    /**
     * bot: bot guide,
     * smartTip: Tooltip,
     * walkthrough: Workflow
     * insights: Feature Tag
     *  onboarding_tour: Tutorial
     */
    params = {
        limit: 10,
        offset: 0,
        is_published: '',
        guide_type: '',
        language: '',
        search_text: '',
        application_ids: [],
        date_range_from: '',
        date_range_to: '',
        sort_order: '',
        sort_by: ''
    } satisfies Record<string, String | Number | String[]>;
    guide_types: any;
    searchInputApp;
    beginDate;
    endDate;
    dateRangeApplied = false;
    pages = [1, 2, 3];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    disableNext = false;
    disablePrev = false;
    showPagination = false;
    pageLimitSet = false;
    defaultAppEnv = {
        env_3: {
            name: "Production",
            checked: true,
            order: 3,
            old_key: 'prod'
        },
        env_2: {
            name: "QA",
            checked: false,
            order: 2,
            old_key: 'qa'
        },
        env_1: {
            name: "Development",
            checked: false,
            order: 1,
            old_key: 'stage'
        }
    }
    tableHeaders = [
        { heading: this.admin_data.guideTitle, sortable: true, key: 'tour_title' },
        { heading: this.admin_data.guideType, sortable: false },
        { heading: this.admin_data.tableHeaderFolder, sortable: false },
        { heading: this.admin_data.createdBy, sortable: false },
        { heading: this.admin_data.tableHeaderCreationDate, sortable: true, key: 'creation_date' },
        { heading: this.admin_data.tableHeaderModificationDate, sortable: false },
        { heading: this.admin_data.tableHeaderStatus, sortable: false },
        { heading: this.admin_data.environments, sortable: false },
        { heading: this.admin_data.tableHeaderActions, sortable: false }
    ];

    tableData: GuideData[] = [];
    curr_org_id: Number;
    destroyed = new Subject();
    disableAction: boolean = true;
    curr_action: string = this.admin_data.publish
    selected_guide_appid: string;
    curr_appEnv = [];
    currModalString = "";
    minDate = moment().toISOString(true).split("T")[0];
    minTime = moment().toISOString(true).split("T")[1].slice(0, 5);
    invalidDateTime = false;
    visibilityDate = "";
    visibilityTime = "";
    currentTour = {};
    disableVisibilitySettings: boolean = false;
    publishingChanged: boolean = false;
    interval;
    publishingUnpublishingInProgress = false;

    constructor(
        public userService: UserService,
        private router: Router,
        private titleService: Title,
        public applicationService: ApplicationService,
        public guideInventoryService: GuideInventoryService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        public paginationService: PaginationService,
        private toastService: ToastModalService,
        private errorResponseService: ErrorResponseService,
    ) {
        this.userService.getUserData();
        this.paginationService.initializeValues();
        this.titleService.setTitle(
            this.admin_data.guideInventory + " | Cornerstone Guide"
        );
        this.route.paramMap.subscribe((params) => {
            if (params['params']['org_id']) {
                if (params['params']['org_id'] !== undefined && params['params']['org_id'] !== '000') {
                    this.curr_org_id = parseInt(params['params']['org_id']);
                    this.userService.organization_id = this.curr_org_id;
                }
            }
        });
        this.userService.activeTab = 'guide_inventory';
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.guideInventory,
                        route: null,
                    },
                ]);
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    ngOnInit() {
        let curr = this
        this.interval = setInterval(function () {
            curr.styleTooltip();
        }, 1000);
        this.styleTooltip();
        this.getApplications();
        // this.dropdownSettings = {
        //     singleSelection: false,
        //     enableCheckAll: true,
        //     idField: "item_id",
        //     textField: "item_text",
        //     selectAllText: "Select All",
        //     unSelectAllText: "Select All",
        //     itemsShowLimit: 3,
        //     allowSearchFilter: false,
        // };
        this.searchTextSubject.pipe(
            takeUntil(this.destroyed),
            debounceTime(300))
            .subscribe(() => {
                this.getGuides();
            })
        this.gotoPageSubject.pipe(
            takeUntil(this.destroyed),
            debounceTime(800))
            .subscribe((page: number) => {

                if (page != this.paginationService.currentPage) {
                    this.params.offset = this.paginationService.gotoPage(page, this.params.limit, this.params.offset)
                    if (this.params.offset >= 0) this.getGuides();
                }
            })

        let maxdate = new Date(Date.now());
        let mindate = new Date("01/02/1920");
        let maxyear = maxdate.getUTCFullYear();
        let minyear = mindate.getUTCFullYear();
        $('input[name="daterange"]').daterangepicker({
            "autoUpdateInput": false,
            "showDropdowns": true,
            "linkedCalendars": false,
            "autoApply": false,
            "minYear": minyear,
            "maxyear": maxyear,
            "maxDate": maxdate,
            "changeMonth": true,
            "changeYear": true,
            constrainInput: false,
            locale: {
                cancelLabel: this.admin_data.clear,
                applyLabel: this.admin_data.apply
            }
        });

        let self = this;
        $('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {

            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' to ' + picker.endDate.format('DD/MM/YYYY'));
            self.onDateRangeChanged(picker.startDate, picker.endDate);

        });



        $('input[name="daterange"]').on('cancel.daterangepicker', function (ev, picker) {
            $('input[name="daterange"]').val('');
            //vm.model = {startDate: null, endDate: null};
            //var picker = $element.find('[date-range-picker]').data('daterangepicker');
            picker.setStartDate(new Date);
            picker.setEndDate(new Date);
            //self.onDateRangeChanged(picker.startDate,picker.endDate);
            self.beginDate = undefined;
            self.endDate = undefined;
            if (self.dateRangeApplied) {
                self.onDateRangeChanged(undefined, undefined);
            }
            $(this).val('');
        });
    }

    styleTooltip() {
        $('[data-toggle="tooltip"]').tooltip();
        
    }
    ngOnDestroy() {
        this.params.offset = 0;
        this.paginationService.initializeValues();
        this.destroyed.next(true);
        this.destroyed.complete();
        clearInterval(this.interval)
    }

    onDateRangeChanged(begin, end) {
        // this.beginDate = Math.floor(begin / 1000);
        // this.endDate = Math.floor(end / 1000);
        if (end && begin) {
            this.beginDate = begin;
            this.endDate = end;
            this.params.date_range_from = this.datePipe.transform(new Date(this.beginDate), 'yyyy-MM-dd');
            this.params.date_range_to = this.datePipe.transform(new Date(this.endDate), 'yyyy-MM-dd');
            this.dateRangeApplied = true;
            this.params.offset = 0;
            this.paginationService.initializeValues();
            this.getGuides();
        } else {
            this.params.date_range_from = "";
            this.params.date_range_to = "";
            this.dateRangeApplied = false;
            this.params.offset = 0;
            this.paginationService.initializeValues();
            this.getGuides();
        }
    }

    getApplications() {
        // this.appListFlag = false;
        this.applicationService.getApplications(100, 0, '', '').subscribe(
            (response) => {
                //response['data']['applications]['settings']
                /**"app_env": {
                    "env_3": {
                      "name": "Production",
                      "checked": true,
                      "order": 3,
                      "old_key": "prod",
                      "$$hashKey": "object:4917"
                    },
                    "env_2": {
                      "name": "QAaaa",
                      "checked": true,
                      "order": 2,
                      "old_key": "qa",
                      "$$hashKey": "object:4916"
                    },
                    "env_1": {
                      "name": "Development",
                      "checked": true,
                      "order": 1,
                      "old_key": "stage",
                      "$$hashKey": "object:4915"
                    },
                    "env_4": {
                      "name": "newadd",
                      "checked": true,
                      "order": 4,
                      "$$hashKey": "object:4918"
                    }
                  },
                   */
                let data = response["data"]['applications'];
                this.selectedItems = [];
                this.dropdownList = [];
                for (let itr0 = 0; itr0 < data.length; itr0++) {
                    this.envMap.set(data[itr0]['application_id'], []);
                    if (JSON.parse(data[itr0]['settings'])['app_env']) {
                        Object.entries(JSON.parse(data[itr0]['settings'])['app_env']).map(entry => {
                            if (entry[1]['checked']) {
                                this.envMap.get(data[itr0]['application_id'])
                                    .push({
                                        // key: entry[1]['old_key'],
                                        order: entry[1]['order'],
                                        apiKey: entry[0],
                                        name: entry[1]['name'],
                                        checked: false
                                    })
                            }
                        });
                    } else {
                        Object.entries(this.defaultAppEnv).map(entry => {
                            this.envMap.get(data[itr0]['application_id'])
                                .push({
                                    order: entry[1]['order'],
                                    apiKey: entry[0],
                                    name: entry[1]['name'],
                                    checked: false
                                })
                        });
                    }
                    if (itr0 == 0) {
                        this.selectedItems.push({ item_id: data[itr0]['application_id'], item_text: data[itr0]['title'], checked: true })
                        this.dropdownList[itr0] = { item_id: data[itr0]['application_id'], item_text: data[itr0]['title'], checked: true };
                    } else {
                        this.dropdownList[itr0] = { item_id: data[itr0]['application_id'], item_text: data[itr0]['title'], isDisabled: undefined };
                    }
                }
                this.styleTooltip();
                this.getGuides();
            },
            (error) => {

            }
        );
    }

    updateAppList = (event) => {
        switch (event.type) {
            case "select": this.onItemSelect(event); break;
            case "deselect": this.onDeSelect(event); break;
            case "selectAll": this.onSelectAll(); break;
            case "deselectAll": this.onDeSelectAll(); break;
            default: return;
        }
        this.params.offset = 0;
        this.paginationService.initializeValues();
        this.getGuides();
    }

    onItemSelect(item: any) {
        if (!this.selectedItems.some(selectedItem => selectedItem.item_id === item.item_id)) {
            this.selectedItems.push({ item_id: item.item_id, item_text: item.item_text });
        }
    }
    onSelectAll() {
        this.selectedItems = this.dropdownList.map(item => ({ item_id: item.item_id, item_text: item.item_text }));
        this.params.limit = 10;
    }

    onDeSelect = (items: any) => this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.item_id !== items.item_id);

    onDeSelectAll = () => this.selectedItems = [];

    search() {
        this.params.offset = 0;
        this.paginationService.initializeValues();
        this.searchTextSubject.next(this.params.search_text);
    }

    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }

    close() {
        let status = document.getElementById("status") as HTMLSelectElement
        status.value = this.params.is_published;

        let guide_type = document.getElementById("guide_type") as HTMLSelectElement
        guide_type.value = this.params.guide_type;

        if (document.getElementById("filter-dropdown-menu") && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
            document.getElementById("filter-dropdown-menu").classList.remove("show");
            document.getElementById("filter").classList.remove("show");
        }
    }
    applyFilter(status: string, guide_type: string) {
        this.params.is_published = status;
        this.params.guide_type = guide_type;
        this.params.offset = 0;
        this.paginationService.initializeValues();
        this.getGuides();
        this.close();
    }

    getGuides() {
        this.params.application_ids = [];
        this.selectedItems.forEach((item) => {
            this.params.application_ids.push(String(item.item_id))
        })
        if (this.params.application_ids.length > 0) {
            this.guideInventoryService.getAllGuides(
                this.params.limit,
                this.params.offset,
                this.curr_org_id,
                this.params.application_ids,
                this.params.guide_type, //tour_type
                '', //guide_creator
                this.params.is_published,
                this.params.search_text,
                this.params.sort_by,
                this.params.sort_order, this.params.date_range_from, this.params.date_range_to).subscribe((response) => {
                    this.tableData = [];
                    if (response['data']) {
                        if (this.toastService.showToast) {
                            this.toastService.showToastMessage(this.toastService.toastType);
                        }
                        this.paginationService.calculatePageLimit(parseInt(response['data']['total_count']), this.params.limit);
                        this.pageLimitSet = true;
                        for (let data of response['data']['tours']) {
                            /**
                             * 
                             * {
                                "tour_id": "1201",
                                "category_id": "583",
                                "tour_title": "first",
                                "tour_description": "",
                                "tour_type": "walkthrough_tour,beacon_tour",
                                "creation_date": "1537527002",
                                "modification_date": "1546925646",
                                "is_published": "0",
                                "application_id": "320",
                                "image_path": null,
                                "application_title": "AA Slack WebApp",
                                "category_title": "goibi",
                                "user_first_name": "Manish",
                                "user_last_name": "Jain",
                                "user_email": "manish+dualauth@edcast.com",
                                "publish_env": []
                            }
                             */
                            this.selectedIndex = -1;
                            let guide: GuideData = {
                                guideTitle: data['tour_title'],
                                type: this.getGuideType(data['tour_type'].toString().split(',')),
                                guideId: data['tour_id'],
                                folder: data['category_title'],
                                createdBy: data['user_first_name'] + " " + data['user_last_name'],
                                created: data['creation_date'],
                                modified: data['modification_date'],
                                publish_env: this.getEnvNames(data['app_publish_envs'], data['application_id']),
                                status: data['is_published'] == '1' ? this.admin_data.published : this.admin_data.unpublished,
                                app_id: data['application_id'],
                                category_id: data['category_id']
                            }
                            this.tableData.push(guide);
                            this.styleTooltip();
                        }
                        this.paginationService.setPaginationStatus(this.tableData, this.params.limit);
                    }
                })
        } else {
            this.tableData = []
        }

    }


    getEnvNames(envKeys: string[], app_id: string) {
        let names = [];

        for (let key of envKeys) {
            for (let env of this.envMap.get(app_id)) {
                if (key == env['apiKey']) {
                    names.push(JSON.parse(JSON.stringify(env)));
                    names[names.length - 1]['checked'] = true;
                }
            }
        }
        return names;
    }

    getGuideType(guideTypeArray: string[]): string {
        let res: string = '';
        for (let guideType of guideTypeArray) {
            for (let entry of this.GuideTypeMap.entries()) {
                if (guideType.includes(entry[0])) {
                    res += res.length > 0 ? ', ' : '';
                    res += entry[1];
                }
            }
        }
        return res;
    }

    checkMark(event: Event, index: number) {
        let elem = (event.target as HTMLInputElement);
        if (elem.checked) {
            this.selectedIndex = index;
            this.curr_action = this.tableData[index].status === this.admin_data.published ? this.admin_data.unpublish : this.admin_data.publish
            this.selected_guide_appid = this.tableData[index].app_id
        } else {
            this.selectedIndex = -1;
        }
    }

    sort(sort_by: string, order: string) {
        this.params.offset = 0;
        this.paginationService.initializeValues();
        this.params.sort_order = order;
        this.params.sort_by = sort_by;
        this.getGuides();
    }

    appendOffset() {
        let offset = this.paginationService.appendOffset(this.params.limit, this.params.offset);
        if (offset >= 0) {
            this.params.offset = offset;
            this.getGuides();
        }
    }

    prependOffset() {
        let offset = this.paginationService.prependOffset(this.params.limit, this.params.offset);
        if (offset >= 0) {
            this.params.offset = offset;
            this.getGuides();
        }

    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.params.limit = parseInt(event);
        this.params.offset = 0;
        this.getGuides();
        this.paginationService.initializeValues();
    }

    setIndex(ind) {
        let offset = this.paginationService.setIndex(ind, this.params.limit, this.params.offset);
        if (offset >= 0) {
            this.params.offset = offset;
            this.getGuides();
        }
    }

    actionOnModal() {
        this.publishingUnpublishingInProgress = true
        let req = {};
        let publish_env = [];
        let unpublish_env = [];
        let count = 0

        this.curr_appEnv.forEach((env) => {
            if (env.checked) {
                count++
            }
        })

        this.tableData[this.selectedIndex].publish_env.forEach((env) => {
            if (env.checked) {
                count--;
            }
        })

        if (count != 0) {
            if (count > 0) {
                req['action'] = 'publish'
                this.curr_appEnv.forEach((env) => { if (env.checked) publish_env.push((env.apiKey)) })
                req['env_name'] = publish_env;
            } else {
                req['action'] = 'unpublish'
                this.curr_appEnv.forEach((env) => { if (!env.checked) unpublish_env.push((env.apiKey)) })
                req['env_name'] = unpublish_env;
            }

            req['category_id'] = this.tableData[this.selectedIndex].category_id;
            req['tour_id'] = this.tableData[this.selectedIndex].guideId;
            this.guideInventoryService.publishGuide(req).subscribe((response) => {
                if (response['error'] == false) {

                    if (this.isInProduction()) {
                        this.updateGuideSettings();
                    }
                    this.toastService.showToast = true;
                    this.toastService.toastType = "guide_published";
                    this.selectedIndex = -1;
                    this.publishingUnpublishingInProgress = false;
                    this.getGuides();
                    this.closeModal();
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage = errorResponse;
                    //console.log(errorResponse);                
                    $("#errorModal").modal("show");
                    this.closeModal();
                }
            })

        } else {
            this.closeModal();
        }
    }

    updateGuideSettings() {
        if (this.currentTourSettings['visibilitySettings']['setvisibiltyrule'] === 1) {
            let visibilityDateTime = new Date(this.visibilityDate + 'T' + this.visibilityTime + ":00");
            this.currentTourSettings['visibilitySettings']['visibilitytimestamp'] = visibilityDateTime.getTime();
        }
        this.currentTour['tour_settings'] = JSON.stringify(this.currentTourSettings)

        let req = {
            tour_id: this.currentTour['tour_id'],
            keywords: this.currentTour['keywords'],
            tour_type: this.currentTour['tour_type'],
            new_settings: this.currentTour['tour_settings']
        }

        this.guideInventoryService.updateGuide(req).subscribe((response) => {

            if (response['error'] === false) {
                this.toastService.showToast = true;
                this.toastService.toastType = "visibility_settings_updated";
                this.selectedIndex = -1;
                if (this.toastService.showToast) {
                    this.toastService.showToastMessage(this.toastService.toastType);
                }
            }
            this.closeModal();
        })
    }
    closeModal() {
        this.selectedIndex = -1;
        this.curr_appEnv = [];
        this.currentTourSettings = {};
        this.currentTour = {};
        this.publishingChanged = false;
        this.publishingUnpublishingInProgress = false;
        $("#actionModal").modal("hide");
    }

    openActionModal(curr_action, index) {
        this.selectedIndex = index;
        this.currModalString = ""
        this.minTime = moment().toISOString(true).split("T")[1].slice(0, 5);
        if (curr_action == 'Publish') {
            this.invalidDateTime = false;
            this.curr_appEnv = JSON.parse(JSON.stringify(this.envMap.get(this.tableData[this.selectedIndex].app_id)));
            this.curr_appEnv.sort(function (a, b) { return (a['order'] > b['order']) ? 1 : ((a['order'] < b['order']) ? -1 : 0); });
            let publishedEnv = this.tableData[this.selectedIndex].publish_env
            for (let i = 0; i < publishedEnv.length; i++) {
                for (let j = 0; j < this.curr_appEnv.length; j++) {
                    if (publishedEnv[i].apiKey === this.curr_appEnv[j].apiKey) {
                        this.curr_appEnv[j].checked = true;
                        break;
                    }
                }
            }
            // publishedEnv.forEach((el) => {
            //     this.curr_appEnv.find((curr_env) => {
            //         console.log(el.apiKey, curr_env.apiKey);
            //         return el.apiKey === curr_env.apiKey
            //     })['checked'] = true;
            // })


            this.guideInventoryService.getGuideInfo(this.tableData[this.selectedIndex].guideId
                , this.curr_org_id
            ).subscribe((response) => {
                if (response['error'] === false) {
                    if (response['data']['tours'] && response['data']['tours'][0] && response['data']['tours'][0]['tour_settings']) {
                        this.currentTour = response['data']['tours'][0];
                        this.currentTourSettings = JSON.parse(response['data']['tours'][0]['tour_settings']);


                        if (this.isInProduction()) {
                            this.disableVisibilitySettings = true;
                            if (!this.currentTourSettings['visibilitySettings']) {
                                this.currentTourSettings['visibilitySettings'] = {}
                            }
                            if (this.currentTourSettings['visibilitySettings']['setvisibiltyrule'] === 1) {
                                const currVisibilityDate = new Date(this.currentTourSettings['visibilitySettings']['visibilitytimestamp'])
                                console.log(currVisibilityDate, currVisibilityDate.getHours())
                                this.visibilityDate = currVisibilityDate.toISOString().split('T')[0];
                                if (currVisibilityDate.getHours() < 10) {
                                    this.visibilityTime = '0' + currVisibilityDate.getHours() + ':';
                                } else {
                                    this.visibilityTime = currVisibilityDate.getHours() + ':';
                                }
                                if (currVisibilityDate.getMinutes() < 10) {
                                    this.visibilityTime += '0' + currVisibilityDate.getMinutes();
                                } else {
                                    this.visibilityTime += currVisibilityDate.getMinutes();
                                }
                                // this.visibilityTime = currVisibilityDate.getHours() + ':' + currVisibilityDate.getMinutes();
                            }
                        } else {
                            this.visibilityDate = "";
                            this.visibilityTime = "";
                            this.currentTourSettings['visibilitySettings'] = {}
                            this.disableVisibilitySettings = false;
                        }

                    }
                }
                $("#actionModal").modal("show");
            })
        } else {
            let unpublishEnv = [];
            this.curr_appEnv = JSON.parse(JSON.stringify(this.envMap.get(this.tableData[this.selectedIndex].app_id)));
            this.curr_appEnv.sort(function (a, b) { return (a['order'] > b['order']) ? 1 : ((a['order'] < b['order']) ? -1 : 0); });

            this.curr_appEnv.forEach((env) => unpublishEnv.push(env.apiKey));
            let req = {};
            req['action'] = 'unpublish';
            req['env_name'] = unpublishEnv;
            req['category_id'] = this.tableData[this.selectedIndex].category_id;
            req['tour_id'] = this.tableData[this.selectedIndex].guideId;
            this.guideInventoryService.publishGuide(req).subscribe((response) => {
                if (response['error'] == false) {
                    this.curr_appEnv = [];
                    this.selectedIndex = -1;
                    this.getGuides();
                    this.toastService.showToast = true;
                    this.toastService.toastType = "guide_unpublished";
                    this.getGuides();
                } else {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");
                }
                if (response) {

                }
            })
        }
    }

    changeInPublish(order: number) {
        this.publishingChanged = true;
        this.currModalString = this.admin_data.guidePublishInfoMessage;
        let numberOfEnvsSelected = 0;
        this.curr_appEnv.forEach((el) => {

            if (el['order'] < order) {
                this.currModalString += el['name'] + ", "
                numberOfEnvsSelected++;
                el['checked'] = true;
            } else if (el['order'] > order) {
                el['checked'] = false;
            } else {
                if (el['order'] == order && el['checked'] == false) {
                    this.currModalString += el['name'] + ", ";
                    numberOfEnvsSelected++;
                    el['checked'] = true;
                } else if (el['order'] == order && el['checked'] == true) {
                    el['checked'] = false
                }
            }
        })
        this.currModalString = this.currModalString.slice(0, -2)
        if (numberOfEnvsSelected === 0) {
            this.currModalString = ""
        }

    }

    // onDeSelectAll($event) {
    //     this.selectedItems = [];
    //     this.params.offset = 0;
    //     this.paginationService.initializeValues();
    //     this.getGuides();
    // }

    exportGuides() {
        this.guideInventoryService.exportGuides(
            this.curr_org_id,
            this.params.application_ids,
            this.params.guide_type, //tour_type
            '', //guide_creator
            this.params.is_published,
            this.params.search_text,
            this.params.sort_by,
            this.params.sort_order, this.params.date_range_from, this.params.date_range_to).subscribe((response) => {
                if (response['error'] == false) {
                    this.toastService.showToast = true;
                    this.toastService.toastType = "export_inventory";
                    if (this.toastService.showToast) {
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }
                }
            }
            );
    }

    isInProduction = (guide?) => {
        if (!guide) {
            let guide = this.tableData[this.selectedIndex];
        }
        if (this.curr_appEnv && this.curr_appEnv[this.curr_appEnv.length - 1] && this.curr_appEnv[this.curr_appEnv.length - 1]['checked'] === true) return true
        return false;
    }

    toggleCurrentVisibility(checked) {
        if (checked) {
            this.currentTourSettings['visibilitySettings']['setvisibiltyrule'] = 1;

        }
        else this.currentTourSettings['visibilitySettings']['setvisibiltyrule'] = 0;
    }

    changeDate(event) {
        this.visibilityDate = event.target.value
        if (this.visibilityTime == "") {
            this.invalidDateTime = true;
        }
        else if (this.visibilityDate == this.minDate && this.minTime > this.visibilityTime) {
            this.invalidDateTime = true;
        } else {
            this.invalidDateTime = false;
        }
    }

    changeTime(event) {
        if (this.visibilityDate == this.minDate && this.minTime > event.target.value) {
            this.invalidDateTime = true;
        } else if (this.visibilityDate == "") {
            this.invalidDateTime = true;
        }
        else {
            this.invalidDateTime = false;
        }
        this.visibilityTime = event.target.value;
    }

    getMinDate() {
        const UTCDate = new Date()
        UTCDate.toISOString().split('T')[0];
        const offset = UTCDate.getTimezoneOffset()
        let localDate = new Date(UTCDate.getTime() - (offset * 60 * 1000))
        return localDate.toISOString().split('T')[0]
    }

    gotoPage(page) {
        this.params.offset = this.paginationService.gotoPage(page, this.params.limit, this.params.offset)
        if (this.params.offset >= 0) this.getGuides();
    }

    invalidCombinationOfvisibilitySettings() {
        return (this.isInProduction() && this.currentTourSettings['visibilitySettings']['setvisibiltyrule'] == 1) && (this.visibilityDate === '' || this.visibilityTime === '')
    }
}
