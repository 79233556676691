import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent {
  admin_data = adminLabels;
  showDropDown = false;
  attributeMapper = new Map();

  @Input() dataList: any[];
  @Input() componentName: string = "guides";
  @Input() className: string = "";
  @Input() className2: string = "";
  @Input() placeholder: string = "";
  @Input() isDisabled: boolean = false;
  @Input() position: string = '';
  @Input() selected: string = '';
  @Output() updateDataList = new EventEmitter<any>();

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {
    this.attributeMapper.set("guides", ['guide_value', 'guide_state']);
    this.attributeMapper.set("apps", ['application_id', 'name']);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataList'] && !changes['dataList'].firstChange) {
      this.cdr.detectChanges();
    }
  }

  openCloseDropdown = () => {
    this.showDropDown = !this.showDropDown;
  }

  onSelect = (data_id: number) => {
    this.updateDataList.emit(data_id);
  };

  getSelectedLabel = () => this.getLabel(this.dataList.find(obj => this.selected == this.getValue(obj)));
  getValue = (obj) => obj[this.attributeMapper.get(this.componentName)[0]];
  getLabel = (obj) => obj[this.attributeMapper.get(this.componentName)[1]];

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (this.showDropDown && !this.elementRef.nativeElement.contains(targetElement)) {
      this.showDropDown = false;
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScrollOutside(event: Event) {
    if (this.showDropDown) {
      this.showDropDown = false;
    }
  }

}
