import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { API_END_POINTS } from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

export interface CustomizationCentre {
  isDefault?: any;
  admin_settings?: any;
  appTitle?: String,
  appId?: String,
  image_id?: String,
  description?: String,
  orgName?: String,
  settings?: {
    tutorial_guide: any;
    selectedApps?: [any],
    chatIconSize?: {
      chatIconWidth?: Number,
      chatIconHeight?: Number
    },
    chatIconType: string;
    widgetIconType: string;
    widgetIconSize?: {
      widgetIconWidth?: Number,
      widgetIconHeight?: Number
    },
    chatIconPos: string;
    stepDefaultColorArray: any;
    selectorStyle: any;
    chat_icon_path: any;
    chat_icon_pos: any;
    showChatIconBottom: boolean;
    showChatIconLeft: boolean;
    showChatIconRight: boolean;
    showChatIconTop: boolean;
    chatLabels: any;
    notifications: any;
    logo: String,
    watermark_video: String,
    widget_icon_path: String,
    widget_icon_pos: {
      widget_icon_top_pos: Number,
      widget_icon_bottom_pos: Number,
      widget_icon_left_pos: Number,
      widget_icon_right_pos: Number,
      widget_icon_id: String,
      widget_icon_top_pos_unit: String,
      widget_icon_bottom_pos_unit: String,
      widget_icon_left_pos_unit: String,
      widget_icon_right_pos_unit: String
    },
    last_slide_id: String,
    first_slide_id: String,
    last_slide: String,
    first_slide: String,
    userLabels: {
      guideMe: String,
      showMe: String,
      testMe: String,
      vLab: String,
      doItForMe: String,
      miniPlayerHelp: String,
      userNotFollowingGuideMessage: String,
      maintenanceMessage: String,
      serviceErrorMessage: String,
      resumeGuideMessage: String,
      playAudio: Boolean,
      isMiniPlayer: Boolean,
      keep_player_panel_open: false
    },
    guideMeSetting: Boolean,
    showMeSetting: Boolean,
    doItForMeSetting: Boolean,
    testMeSetting: Boolean,
    vLab: Boolean,
    notificationDelay: Number,
    widgetIconPos: String,
    showWidgetIconTop: Boolean,
    showWidgetIconBottom: Boolean,
    showWidgetIconLeft: Boolean,
    showWidgetIconRight: Boolean,
    stepPopupWidth: Number,
    popupDesign: {
      type: String,
      default: {
        bgColor: String,
        stepCountColor: String,
        borderColor: String,
        borderWidth: Number,
        borderRadius: Number,
        nextBtnBackground: String,
        nextBtnColor: String,
        prevBtnBackground: String,
        prevBtnColor: String,
        stepTitleColor: String,
        stepDescColor: String,
        audioIconColor: String,
        closeIconColor: String,
        stepDesFontSize: String,
        stepDesFontFamily: String,
        stepTitleFontSize: String,
        stepTitleFontFamily: String,
        padding?: {
          top: Number,
          bottom: Number,
          left: Number,
          right: Number,
        }
      },
      classic: {
        bgColor: String,
        stepCountColor: String,
        borderColor: String,
        borderWidth: Number,
        borderRadius: Number,
        nextBtnBackground: String,
        nextBtnColor: String,
        prevBtnBackground: String,
        prevBtnColor: String,
        stepTitleColor: String,
        stepDescColor: String,
        audioIconColor: String,
        closeIconColor: String,
        stepDesFontSize: String,
        stepDesFontFamily: String,
        stepTitleFontSize: String,
        stepTitleFontFamily: String,
        padding?: {
          top: Number,
          bottom: Number,
          left: Number,
          right: Number,
        }
      },
      current: {
        bgColor: String,
        stepCountColor: String,
        borderColor: String,
        borderWidth: Number,
        borderRadius: Number,
        nextBtnBackground: String,
        nextBtnColor: String,
        prevBtnBackground: String,
        prevBtnColor: String,
        stepTitleColor: String,
        stepDescColor: String,
        audioIconColor: String,
        closeIconColor: String,
        stepDesFontSize: String,
        stepDesFontFamily: String,
        stepTitleFontSize: String,
        stepTitleFontFamily: String,
        padding?: {
          top: Number,
          bottom: Number,
          left: Number,
          right: Number,
        }
      }
    },
    hide_widget_if_noguide: Boolean,
    guide_count_widget_color: String,
    guide_count_on_widget: String,
    widget_icon_zindex: Number,
    showWidgetIcon: Number,
    rules: {
      type: String,
      name: String,
      condition: String,
      value: String,
      logical_condition: String,
      showValueField: true
    }[],
    first_slide_text: String,
    last_slide_text: String,
    defaultGuideView: Boolean,
    enable_send_feedback: Boolean,
    chatBotrules: {
        type: String,
        name: String,
        condition: String,
        value: String,
        logical_condition: String,
        showValueField: true
    }[],      
    showChatbotIcon: Number,
  }
}



@Injectable({
  providedIn: 'root'
})
export class CustomizationCentreService {

  col_widths = ['10%', '12%', '10%', '18%', '9%', '8%', '11%', '11%', '11%'];
  apps: CustomizationCentre[] = [];
  org: CustomizationCentre = {};
  changedApps: CustomizationCentre[] = [];
  assetsUrl = "https://stagecdn.guideme.io/guideme-assests/myguide_library/"
  cdn_signature;
  private selectedSubmenuSource = new BehaviorSubject<string>('branding');
  selectedSubmenu$ = this.selectedSubmenuSource.asObservable();

  constructor(private userService: UserService, private http: HttpClient, private apiService: ApiService) { }

  getApplications(limit, offset, org_id) {
    let urlPath;
    if (this.userService.organization_id == '000') {
      urlPath = environment.baseUrl + API_END_POINTS.GET_APPLICATIONS + "?limit=" + limit + "&offset=" + offset;
    } else {
      urlPath = environment.baseUrl + "/application/list?limit=" + limit + "&offset=" + offset + "&organization_id=" + org_id;
    }
    return this.apiService.get(urlPath);
  }

  getSettings(org_id) {
    let urlPath;
    // if (this.userService.organization_id == '000') {
    urlPath = environment.baseUrl + API_END_POINTS.GET_ORGANIZATION + "?organization_id=" + org_id
    // } else {
    //      urlPath = environment.baseUrl + "/application/list?limit=" + limit + "&offset=" + offset + "&organization_id=" + this.userService.organization_id;
    // }
    return this.apiService.get(urlPath);
  }

  getImageId(request) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_IMAGE_ID;
    return this.apiService.post(urlPath, request);
  }

  getLibraryImages(section: String, types: String[]) {
    let number = 1;
    let urlPaths = [];
    let urlPath = "";
    let colors = ["Mono", "Multi"]
    if (section === "Images") {
      for (let type of types) {
        for (number = 1; number <= 20; number++) {
          let name = type
          if (type === "Miscellenous") { name = "Miscelleneous" }
          if (number < 10) {
            urlPath = this.assetsUrl + section + "/" + type + "/180x180/" +
              (name === "Finance" ? name + "_0" : name + "_jpeg_0")
              + number +
              ".jpg";
          }
          else {
            urlPath = this.assetsUrl + section + "/" + type + "/180x180/" +
              (name === "Finance" ? name + "_" : name + "_jpeg_")
              + number + ".jpg";
          }
          urlPaths.push(urlPath)
        }
      }
    } else if (section === "Icons") {
      for (let type of types) {
        if (type === "Solid") { colors = ["Multi"] }
        for (let color of colors) {
          for (number = 1; number <= 20; number++) {
            if (type === "Solid" && number > 15) { continue; }
            if (number < 10) {
              urlPath = this.assetsUrl + section + "/" + type + "/" + color + "color" + "/180x180/" + (color.toLowerCase()) + "_" +
                (type === "Animals" ? "animal" :
                  type === "Audio_and_video" ? "audio-video" : type.toLowerCase()) + "_0" + number + ".jpg";
            }
            else {
              urlPath = this.assetsUrl + section + "/" + type + "/" + color + "color" + "/180x180/" + (color.toLowerCase()) + "_" +
                (type === "Animals" ? "animal" :
                  type === "Audio_and_video" ? "audio-video" : type.toLowerCase()) + "_" + number + ".jpg";
            }
            urlPaths.push(urlPath)
          }
        }

      }
    } else if (section === "Colors") {
      let total = 0;
      for (let type of types) {
        type === "Popular" ? total = 60 :
          type === "Trendy" ? total = 40 :
            total = 20
        for (number = 1; number <= total; number++) {
          if (number < 10) {
            urlPath = this.assetsUrl + section + "/" + type + "/180x180/" + "color_" + type.toLowerCase() + "_0" + number + ".jpg";
          }
          else {
            urlPath = this.assetsUrl + section + "/" + type + "/180x180/" + "color_" + type.toLowerCase() + "_" + number + ".jpg";
          }
          urlPaths.push(urlPath)
        }
      }
    }

    return urlPaths
  }

  getImageFromUrl(src: String) {
    return this.apiService.get(src);
  }
  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  saveAllSettings() {
    let urlPath = "";
    let body = {};
    let requestArr = [];
    for (let i = 0; i < this.changedApps.length; i++) {
      if (this.changedApps[i].appId === '0') {
        urlPath = environment.baseUrl + API_END_POINTS.UPDATE_ORGANIZATION
        body = {
          organization_id: this.userService.organization_id,
          name: this.changedApps[i].orgName,
          image_id: this.changedApps[i].image_id,
          about: this.changedApps[i].description,
          settings: JSON.stringify(this.changedApps[i].settings)
        }
      } else {
        body = {
          application_id: this.changedApps[i].appId,
          title: this.changedApps[i].appTitle,
          image_id: this.changedApps[i].image_id,
          description: this.changedApps[i].description,
          settings: JSON.stringify(this.changedApps[i].settings)
        }
        urlPath = environment.baseUrl + API_END_POINTS.EDIT_APPLICATION
      }
      let tempReq = this.apiService.put(urlPath, body);
      requestArr.push(tempReq);
    }
    this.changedApps.length = 0;
    return forkJoin(requestArr);
  }

  getSignature(organization_id) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_SIGNATURE + "?organization_id=" + organization_id;
    return this.apiService.get(urlPath);
  }
  
  selectSubMenu = (submenu) => {
    this.selectedSubmenuSource.next(submenu);
  }
}
