import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { OrganisationService } from 'src/app/common/services/organisation.service';
declare var $: any;
@Component({
    selector: 'app-modal-confirmation',
    templateUrl: './modal-confirmation.component.html',
    styleUrls: ['./modal-confirmation.component.css']
})
export class ModalConfirmationComponent implements OnInit {

    admin_data = adminLabels;
    headerValue;
    showModal = false;
    contentValue;
    modalOkValue;
    orgValue;
    constructor(public orgService: OrganisationService) { }



    ngOnInit() {
        this.orgService.showConfirmationModal.subscribe((value) => {
            if (value) {
                $("#confirmModal").modal("show");
            } else {
                $("#confirmModal").modal("hide");
            }
        })
    }

    @Input('header_value') set header_value(value) {
        this.headerValue = value;
    };

    @Input('content_value') set content_value(value) {
        this.contentValue = value;
    };

    @Input('modal_ok_value') set modal_ok_value(value) {
        this.modalOkValue = value;
    };

    @Input('org') set org(value) {
        this.orgValue = value;
    };

    @Input('show') set show(value) {
        this.showModal = value;
        if (this.showModal) {
            $("#confirmModal").modal("show");
        } else {
            $("#confirmModal").modal("hide");
        }
    }
    @Output() onDelete = new EventEmitter();
    @Output() onClose = new EventEmitter();

    closeModal() {
        this.orgService.showConfirmationModal.next(false);
        this.onClose.emit();
    }

    delete() {
        this.onDelete.emit(this.headerValue);
    }
}
