<div class="d-flex">
	<div class="container-fluid content-container p-3 toggle-view "
		[ngClass]="{'expanded-view':(userService.user.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.user.organization_id != '000' && !userService.sidebarOpen)}">
		<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
		<section class="component-section">
	
			<div class="sectionBody">

				<div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.tableTitle3}}
					</div>
				</div>
				

				<div class="heading">
					<!-- <div class="d-flex justify-content-end my-3"> -->
					<!-- <div class="col-sm-8 col-md-6 col-lg-6 col-xl-5 p-2 text-right">
				  <button *ngIf="folders.length==0" type="button" class="btn btn-primary ml-2"
					(click)="addContentsWithoutFolder()">Add Content</button>
				  <button *ngIf="contentsWithoutFolder.length==0" type="button" class="btn btn-primary ml-2"
					(click)="addFolder()">Add Folder</button>
				</div> -->

					<div class="displayFlex task-contentDiv innerDiv">
						<div class="displayFlex partitionDiv borderR">
							<span>{{this.admin_data.manageContentVariable1}}</span>
							<span class="title taskTitle" data-toggle="tooltip" data-placement="bottom"
								(mouseover)="showTooltip()" (mouseout)="removeTooltip()" title="{{list_name}}">
								{{list_name}}</span>
						</div>
						<div class="displayFlex partitionDiv borderL">
							<span>{{this.admin_data.manageContentVariable5}}</span>
							<span class="title taskTitle">{{application_name}}</span>
						</div>
					</div>


					<!--
				<table>
				  <tr>
					<td colspan="3"><div style="width:100px">Task List Name:</div></td>
					<td class="title " colspan="4"><b class="textEllipsis1">{{list_name}}</b></td>
					<td colspan="1">|</td>
					<td colspan="2"><div style="width:50px">Application:</div></td>
					<td class="title" colspan="2"><b class="textEllipsis1">{{application_name}}</b></td>
				  </tr>
				</table>
				-->
				</div>
				<div class="layout p-2 min-h-420px">
					<div *ngIf="task_list"
						[ngClass]="{' col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2 text-right bg-gray':(folders.length==0 && task_list['is_publish']=='0') || (folders.length==0 && contentsWithoutFolder.length>0) || (contentsWithoutFolder.length==0 && task_list['is_publish']=='0') || (task_list['is_publish']=='1')}"
						style="min-height: 51px;">
						<div class="d-inline-flex float-left">
							<div class="enforce-sequence float-left my-2 font14">
								<label class="switch mx-2 mb-0"
									[ngClass]="isChanged || task_list['is_publish']=='1' || (folders.length==0 && contentsWithoutFolder.length==0)  ? ' switch-disabled' : ''"
									(click)="toggleEnforce($event.target.checked)">
									<input class=" primary" [value]="enforce == true ? 1 : 0" [checked]="enforce"
										type='checkbox' placeholder=""
										[disabled]="isChanged || task_list['is_publish']=='1' || (folders.length==0 && contentsWithoutFolder.length==0) " />
									<span class="slider round"
										[ngClass]="isChanged || task_list['is_publish']=='1' || (folders.length==0 && contentsWithoutFolder.length==0)  ? ' slider-disabled' : ''"
										(click)="$event.stopPropagation()"></span>
								</label>
								<label>{{this.admin_data.enforceSequence}}</label>
							</div>
							<div *ngIf="isChanged && task_list['is_publish']=='0'" class="">
								<button class="btn btn-primary ml-2"
									(click)="updateSequence()">{{this.admin_data.updateSequence}}</button>
								<button class="btn btn-secondary ml-2"
									(click)="reset()">{{this.admin_data.reset}}</button>
							</div>
						</div>

						<button *ngIf="folders.length==0 && task_list['is_publish']=='0'" type="button"
							class="btn btn-primary ml-2"
							(click)="addContentsWithoutFolder()">{{this.admin_data.manageContentVariable12}}</button>
						<button *ngIf="folders.length==0 && contentsWithoutFolder.length>0" type="button"
							class="btn btn-primary ml-2"
							(click)="showPreviewModal()">{{this.admin_data.manageContentVariable6}}
						</button>
						<button *ngIf="contentsWithoutFolder.length==0 && task_list['is_publish']=='0'" type="button"
							class="btn btn-primary w-10 mx-2 ml-2"
							(click)="addFolder()">{{this.admin_data.manageContentVariable13}}</button>
					</div>
					<div *ngIf="showFolder && folders;else showContents">
						<ng-container *ngFor="let folder of folders;let i=index">
							<div class="border accordion w-100 rounded mb-2 mt-1 rounded">
								<div class="folder_view col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-4 py-3 bg-gray cursor pointer rounded-top positionRelative"
									style="display:flex; align-items:center" data-toggle="collapse"
									[attr.data-target]="'#course'+i" (click)="toggle('#arrow',i,folder['id'])">
									<span class="folder" style="width:80%; display:flex; align-items:center"
										id="folder{{i}}">
										<svg-icon src="../assets/svgs/folder-icon.svg"></svg-icon>
										<span class="marginLeft-10 taskFolderTitle" data-toggle="tooltip"
											data-placement="bottom" (mouseover)="showTooltip()"
											(mouseout)="removeTooltip()" title="{{folder['title']}}">
											{{folder['title']}}
										</span>
									</span>
									<span class="hide" id="folder_name{{i}}" (click)="$event.stopPropagation()">
										<input type="text" [(ngModel)]="folder['title']"
											(click)="$event.stopPropagation()" autocomplete="off">
										<svg-icon src="../assets/svgs/org_tick.svg"
											(click)="updateFolder(i,folder['id'],folder['title']);$event.stopPropagation()">
										</svg-icon>
									</span>


									<div class="actionIcons-div">
										<i class="edit-folder float-right position-relative">
											<div class="mr-1 d-inline-block" data-toggle="tooltip"
												data-placement="bottom"
												title="{{this.admin_data.manageContentVariable6}}">
												<svg-icon src="../../../assets/svgs/visibility-icon.svg" class="visibility-icon"
													(click)="showPreviewModal(folder['id']);$event.stopPropagation()">
												</svg-icon>
											</div>
											<div *ngIf="task_list['is_publish']=='0'" class="mr-1 d-inline-block"
												data-toggle="tooltip" data-placement="bottom"
												title="{{this.admin_data.tableTitle2}}">
												<svg-icon src="../assets/svgs/emit_edit.svg"
													(click)="editFolder(i,folder['id']);$event.stopPropagation()">
												</svg-icon>
											</div>
											<!-- <i class="mr-2 fa fa-arrow-up" (click)="moveUpwards(i,folders);$event.stopPropagation()"></i>
														  <i class="mr-2 fa fa-arrow-down" (click)="moveDownwards(i,folders);$event.stopPropagation()"></i> -->
											<div *ngIf="task_list['is_publish']=='0'" class="mr-1 d-inline-block"
												data-toggle="tooltip" data-placement="bottom"
												title="{{this.admin_data.manageContentVariable12}}">
												<svg-icon src="../assets/svgs/add_content.svg"
													(click)="showContentModal(i,folder['id']);$event.stopPropagation()">
												</svg-icon>
											</div>
											<div *ngIf="task_list['is_publish']=='0'" class="mr-1 d-inline-block"
												data-toggle="tooltip" data-placement="bottom"
												title="{{this.admin_data.deleteButtonText}}">
												<svg-icon src="../assets/svgs/emit_delete.svg"
													(click)="showModal(folder['id']);$event.stopPropagation()">
												</svg-icon>
											</div>
										</i>
									</div>
									<i id="arrow{{i}}" class=" m-1 float-right positionRelativeL">
										<svg-icon src="../assets/svgs/icon-down-arrow.svg"></svg-icon>
									</i>
								</div>
								<div id="course{{i}}" class="row pl-4 pr-0 py-3 collapse w-100">
									<div class="spinner-wrapper" *ngIf="visible[i]" id="loader{{i}}">
										<img class="spinner-img img-fluid"
											src="../../assets/images/g_new_loader.gif" />
									</div>
									<ng-container *ngIf="!visible[i] && folder['contents']">
										<ng-container *ngIf="folder['contents'].length>0">
											<ul *ngFor="let content of folder['contents'];let k=index"
												class="w-100 m-0 p-0 ls-none">
												<li class="folder content_list w-100 h-100 p-1 pl-3 mt-1 h-45px lh">
													<svg-icon src="../assets/svgs/icon-onboarding-published.svg">
													</svg-icon>
													<span class="pl-2 pt-1">{{content['guide_title']}}</span>
													<!-- <i class="edit" (click)="editFolder(i);$event.stopPropagation()"> -->
													<div *ngIf="enforce && task_list['is_publish']=='0'"
														class="float-right">
														<span role="button" class="mr-2" data-toggle="tooltip"
															data-placement="bottom" (mouseover)="showTooltip()"
															title="{{this.admin_data.shiftUp}}"
															(click)="moveUpwards(k,folder['contents'], folder['id'])">
															<svg-icon src="../../assets/svgs/caret-up-arrow.svg">
															</svg-icon>
														</span>
														<span role="button" class="mr-2"
															(click)="moveDownwards(k,folder['contents'], folder['id']);$event.stopPropagation()"
															data-toggle="tooltip" data-placement="bottom"
															(mouseover)="showTooltip()"
															title="{{this.admin_data.shiftDown}}">
															<svg-icon src="../../assets/svgs/caret-down-arrow.svg">
															</svg-icon>
														</span>
													</div>
													<!-- </i> -->
												</li>
												<!-- <li>Content 2</li>
			  <li>Content 3</li>
			  <li>Content 4</li>
			  <li>Content 5</li> -->
											</ul>
										</ng-container>
										<h6 class="text-center" *ngIf="!visible[i] && folder['contents'].length==0">
											{{this.admin_data.manageContentVariable10}}</h6>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
					<ng-template #showContents>
						<ul class="w-100 m-0 p-0 ls-none" *ngFor="let content of contentsWithoutFolder;let ind=index">
							<li class="folder content_list w-100 h-100 p-1 pl-3 mt-1 lh h-45px">
								<svg-icon src="../../assets/svgs/icon-onboarding-published.svg"></svg-icon>
								<span class="pl-2 pt-1 content-align-center"> {{content['guide_title']}}</span>
								<span *ngIf="enforce && task_list['is_publish']=='0'" class="float-right mr-4">
									<span role="button" class="mr-2" data-toggle="tooltip"
										data-custom-class="move-left-16" data-placement="bottom"
										(mouseover)="showTooltip()" title="{{this.admin_data.shiftUp}}"
										(click)="moveUpwards(ind,contentsWithoutFolder)">
										<svg-icon src="../../assets/svgs/caret-up-arrow.svg"></svg-icon>
									</span>
									<span role="button" class="mr-2"
										(click)="moveDownwards(ind,contentsWithoutFolder);$event.stopPropagation()"
										data-toggle="tooltip" data-custom-class="move-left-16" data-placement="bottom"
										(mouseover)="showTooltip()" title="{{this.admin_data.shiftDown}}">
										<svg-icon src="../../assets/svgs/caret-down-arrow.svg"></svg-icon>
									</span>
								</span>
							</li>
						</ul>
					</ng-template>
					<div class="modal fade" id="addContentModal" tabindex="-1" role="dialog"
						aria-labelledby="addContentLabel" aria-hidden="true">
						<div [style.max-width]="addFolderWithContents?'35%':'50%'"
							class="modal-dialog modal-dialog-centered h-100" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 *ngIf="!addFolderWithContents " class="modal-title" id="addRoleModalLabel">
										{{this.admin_data.manageContentVariable12}}</h5>
									<h5 *ngIf="addFolderWithContents " class="modal-title" id="addRoleModalLabel">
										{{this.admin_data.manageContentVariable13}}</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div [style.min-height]="addFolderWithContents?'100%':'420px'"
									class="modal-body overflow-auto">
									<!-- <app-form [formData]="segment_formData" [formGroup]="formGroup"></app-form> -->
									<div *ngIf="!addFolderWithContents && (search==''? checkActive() ? true :!visible_guide_loader :search)"
										class="input-group w-80 m-0 pb-4 d-inline-flex">
										<!-- <span class="input-group-append">
											<div
												class="border-right-0 input-group-text bg-white rounded-left custom-border-color">
												<svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
											</div>
										</span> -->
										<input id="searchGuide"
											class="form-control search"
											type="search" autocomplete="off"
											placeholder={{this.admin_data.searchPlaceholder}}
											(input)="validateInput($event)" (search)="searchGuide()"
											[(ngModel)]="search" aria-label="Search">
									</div>
									<ng-container *ngIf="addFolderWithContents" class="col-lg-5 my-2">
										<app-form [formData]="add_folder_formData" [formGroup]="formGroup"></app-form>
									</ng-container>
									<form *ngIf="!addFolderWithContents" [formGroup]="form">
										<ng-container formArrayName="cont">

											<div class="layout-modal m-0 overflow-auto rounded min-h-331px max-h-331px">
												<div *ngIf="visible_guide_loader"
													class="spinner d-block margin-auto w-40">
													<img class="spinner-img img-fluid d-block w-50 h-100 margin-auto"
														src="../../assets/images/g_new_loader.gif" />
												</div>
												<ng-container *ngIf="!visible_guide_loader">
													<div class="p-1 m-auto w-97" *ngIf="contents.length > 0">
														<div class="d-inline-block p-1">
															<label class="custom-modal-check">
																<input type="checkbox" class="m-1"
																	[checked]="selected_guide_ids.length == all_contents.length"
																	(click)="selectAllGuides($event.target.checked)">
																<span class="checkmark-custom"></span>
															</label>
														</div>
														<div
															class="d-inline-block p-1 pl-3 h-100 position-relative ml-1 va-super pt-3px">
															<b>{{this.admin_data.manageContentVariable17}}</b>
														</div>
														<div class="float-right d-inline-block p-1 h-100">
															<b><u>{{selected_guide_ids.length + " "}}{{this.admin_data.manageContentVariable18}}</u></b>
														</div>
													</div>
													<ul class="m-0 p-1 ls-none" formGroupName="{{j}}"
														*ngFor="let content of contents;let j=index">
														<!-- <input *ngIf="showFolder && !this.addFolderWithContents" class="mr-3" type="checkbox" [checked]="this.folders[this.folder_index]['contents'].indexOf(content)!=-1" (click)="setSelected($event.target.checked,j)">
													  <input *ngIf="this.addFolderWithContents" class="mr-3" type="checkbox" [checked]="this.contentsWithoutFolder.length>0" (click)="setSelected($event.target.checked,j)">
													  <input *ngIf="!this.addFolderWithContents && this.addContentWithoutFolder"  [checked]="this.contentsWithoutFolder.indexOf(content)!=-1" class="mr-3" type="checkbox" (click)="setSelected($event.target.checked,j)"> -->

														<li>
															<div class="row w-97 m-auto">
																<div class="w-100 content_list h-100 displayFlex">
																	<div class="d-inline-block p-1 ">
																		<label class="custom-modal-check top5">
																			<input type="checkbox" class="m-1"
																				formControlName="check"
																				[checked]="this.selected_guide_ids.indexOf(content['tour_id'])!=-1"
																				(change)="setSelected($event.target.checked,j)">
																			<span class="checkmark-custom"></span>
																		</label>
																	</div>
																	<div class="d-inline-block p-1 pl-3 h-100 va-super">
																		<svg-icon
																			src="../assets/svgs/icon-onboarding-published.svg">
																		</svg-icon>
																		<span
																			class="p-1 position-relative pt-1px">{{content['tour_title']}}</span>
																	</div>
																</div>
															</div>
														</li>
													</ul>
												</ng-container>
												<ng-container *ngIf="!addFolderWithContents && contents.length==0">
													<h6 class="textAlignCenter marginTop-25percent"
														*ngIf="!visible_guide_loader">
														{{this.admin_data.manageContentVariable21}}</h6>
												</ng-container>
											</div>
										</ng-container>
									</form>
								</div>
								<div class="modal-footer justify-content-center">
									<button type="button" class="btn btn-secondary minWidth-120"
										data-dismiss="modal"
										(click)="closeContentModal()">{{this.admin_data.cancelButtonText}}</button>
									<!-- <button *ngIf="addFolderWithContents" type="button" class="btn btn-primary minWidth-120"
						(click)="folder_isEdit?updateFolder(formGroup.get('title').value):saveFolder()" [disabled]="formGroup.invalid">
						<span *ngIf="folder_isEdit">Update</span>
						<span *ngIf="!folder_isEdit">Save</span>
					  </button> -->
									<app-button *ngIf="addFolderWithContents && !folder_isEdit"
										[disabled]="!formGroup.valid" [buttonData]="contentService.save_button"
										(clickhandler)="saveFolder()"></app-button>
									<app-button *ngIf="addFolderWithContents && folder_isEdit"
										[disabled]="!formGroup.valid" [buttonData]="contentService.update_button"
										(clickhandler)="updateFolder(formGroup.get('title').value)"></app-button>
									<button *ngIf="!addFolderWithContents" type="button"
										class="btn btn-primary minWidth-120"
										(click)="addContents()">{{this.admin_data.saveButtonText}}</button>
								</div>
							</div>
						</div>
					</div>

					<!-- Preview modal -->

					<div class="modal fade" id="previewmodal" tabindex="-1" role="dialog"
						aria-labelledby="addContentLabel" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered h-100 w-50" role="document">
							<div class="modal-content">
								<div class="modal-header">
									{{this.admin_data.preview}}
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="layout p-2 min-h-420px">
									<div id="videoCarousel" class="carousel slide" data-ride="carousel">
										<div *ngIf="showPreview && organisationService.cdn_signature && video_urls.length>0"
											class="carousel-inner">
											<div *ngFor="let url of video_urls;let m=index" class="carousel-item"
												[ngClass]="{'active':m==currentIndex}"
												(click)="$event.stopPropagation()">
												<ng-container *ngIf="url && content_ids.length>0">
													<div>{{m+1}}. {{content_titles[m]}}</div>
												</ng-container>
												<div class="d-flex vertical-center-align h-325"
													*ngIf="url=='Video preview does not exist'">{{url}}</div>
												<video #guidepreview *ngIf="url!='Video preview does not exist'"
													class="d-block w-100" height="560" controls>
													<source src="{{url}}{{organisationService.cdn_signature}}"
														type="video/mp4">
												</video>
											</div>
											<!-- <div class="carousel-item">
								<video class="d-block w-100" height="560" controls>
									<source
										[src]="getVideoURL()"
										type="video/mp4">
								</video>
							</div>
							<div class="carousel-item">
								<video class="d-block w-100" height="560" controls>
									<source
										[src]="getVideoURL()"
										type="video/mp4">
								</video>
							</div> -->
										</div>
										<div class="spinner-wrapper h-325 d-flex vertical-center-align"
											*ngIf="!showPreview">
											<img class="spinner-img img-fluid"
												src="../../assets/images/g_new_loader.gif" />
										</div>
										<a class="carousel-control-prev carousel-control" role="button"
											[class.d-none]="currentIndex==0" data-slide="prev">
											<span class="carousel-control-prev-icon" aria-hidden="true"></span>
											<span class="sr-only">{{this.admin_data.manageContentVariable27}}</span>
										</a>
										<a class="carousel-control-next carousel-control"
											[class.d-none]="content_ids.length<2" role="button" data-slide="next">
											<span class="carousel-control-next-icon" aria-hidden="true"></span>
											<span class="sr-only">{{this.admin_data.manageContentVariable29}}</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>




					<div class="modal fade" id="deleteWarningmodal" tabindex="-1" role="dialog"
						aria-labelledby="deleteWarningmodal" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="addRoleModalLabel">{{this.admin_data.actionDeclined}}
									</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<ng-container>
									<div class="modal-body">
										{{this.admin_data.manageContentVariable30}}
									</div>
								</ng-container>

							</div>
						</div>
					</div>
					<!-- <div class="accordion w-100 mx-3 mt-3 rounded">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-4 py-3 bg-gray cursor pointer rounded-top" data-toggle="collapse" [attr.data-target]="'#course1'" (click)="toggle('#arrow',1)">
		   <span class="folder" id="folder1" (click)="$event.stopPropagation()">{{folders[1]}}  
		   <i class="edit" (click)="editFolder(1);$event.stopPropagation()">
			<svg-icon src="../assets/svgs/emit_edit.svg"></svg-icon>
			<i class="mr-2 fa fa-arrow-up" (click)="moveUpwards(1,folders);$event.stopPropagation()"></i>
			<i class="mr-2 fa fa-arrow-down" (click)="moveDownwards(1,folders);$event.stopPropagation()"></i>
		  </i>
		</span>
		<span class="hide" id="folder_name1" (click)="$event.stopPropagation()">
			<input type="text" [(ngModel)]="folders[1]" (click)="$event.stopPropagation()">
		  <svg-icon src="../assets/svgs/org_tick.svg" (click)="saveFolderName(1);$event.stopPropagation()"></svg-icon>
		  </span>  
			  <i id="arrow1" class="float-right">
				<svg-icon src="../assets/svgs/icon-down-arrow.svg"></svg-icon>
			  </i>
			</div>
			<div id="course1" class="row px-4 py-3 collapse">
			  <ul>
			  <li>Content 1</li>
			  <li>Content 2</li>
			  <li>Content 3</li>
			  <li>Content 4</li>
			  <li>Content 5</li>
			</ul>
			</div>
		  </div> -->
				</div>
			</div>
		</section>

		<!-- <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-4 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-secondary w-25 mx-2" data-dismiss="modal"
          (click)="unassign()">{{this.admin_data.cancelButtonText}}</button>
        <button type="button" class="btn btn-primary w-25 mx-2" (click)="addOrganization()">Save</button>
      </div>
    </div> -->
		<ng-container *ngIf="folders">
			<ng-container *ngIf="folders.length>0">
				<app-delete-modal [delete_elem]="folders[deleteIndex]['title']" (onDelete)="deleteFolder($event)">
				</app-delete-modal>
			</ng-container>
		</ng-container>
	</div>
</div>