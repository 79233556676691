import { Component, OnInit } from '@angular/core';
import { CustomizationCentre, CustomizationCentreService } from 'src/app/common/services/customization.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

declare var $: any;
declare var jquery: any;
@Component({
    selector: 'app-modal-step-theme',
    templateUrl: './modal-step-theme.component.html',
    styleUrls: ['./modal-step-theme.component.css']
})
export class ModalStepThemeComponent implements OnInit {
    admin_data = adminLabels;
    appId = '';
    mutableDefaultObj = {};
    mutableClassicObj = {};
    logoSrc= "";
    app: CustomizationCentre = {}
    fontSizes = ["xx-small", "x-small", "small", "medium", "large", "x-large", "xx-large",]
    fontWeightArray = [{
        name: 'lighter',
        css: '100'
    }, {
        name: 'normal',
        css: '400'
    }, {
        name: 'bold',
        css: '600'
    }, {
        name: 'bolder',
        css: '900'
    }];
    fontFamilies = ["Arial", "Comic Sans MS", "Courier New", "Cursive", "Fantasy", "Garamond", "Georgia", "Helvetica", "Monospace", "Narrow", "Proxima Nova", "Sans-Serif", "Serif", "Tahoma", "Trebuchet MS", "Verdana", "Wide"];
    constructor(private cutomizationService: CustomizationCentreService) { }


    ngOnInit() {

    }

    openPopup(app_id, logoSrc) {
        this.logoSrc = logoSrc;
        $("#stepTheme").modal("show");
        this.appId = app_id;
        this.app = JSON.parse(JSON.stringify(this.cutomizationService.apps[this.cutomizationService.apps.findIndex((app) => {
            return app.appId === app_id
        })]))
        if (this.app.settings.popupDesign == undefined) {
            this.app.settings.popupDesign = { ...this.cutomizationService.apps[0].settings.popupDesign };
        }
        let popupDesign = this.getPropValue(this.app, "settings.popupDesign");
        if (this.getPopupStyles("type") == "classic") {
            this.mutableDefaultObj = { ...popupDesign.default };
            this.mutableClassicObj = { ...popupDesign.current };
        } else {
            this.mutableDefaultObj = { ...popupDesign.current };
            this.mutableClassicObj = { ...popupDesign.classic };
        }
    }

    closePopup($event) {
        $event.stopPropagation();
        $("#stepTheme").modal("hide");
        delete this.mutableClassicObj;
        delete this.mutableDefaultObj;
        delete this.app;
    }

    getCardWidth(card_number) {
        if (!card_number) {
            // if (Number(this.getPopupStyles('stepPopupWidth')) > 800) { return "100%" }
            { return "100%" }
        } else {
            if (Number(this.getPopupStyles('stepPopupWidth')) < 800) { return "100%" }
            else { return this.getPopupStyles('stepPopupWidth') }
        }
    }

    getPopupStyles(property) {
        let type = this.getPropValue(this.app, "settings.popupDesign.type");
        if (property == "type") return type;
        let currentObj = this.getCurrentMutableObj(type);
        if (property == "stepPopupWidth") {
            let val = this.getPropValue(this.app, "settings.stepPopupWidth");
            val = val < 250 ? 250 : val;
            val = val > 1000 ? 1000 : val;
            return val;
        }
        if(property.includes("padding")){
           if(currentObj["padding"]!=undefined) {
            return currentObj.padding[property.split(".")[1]]==undefined?0:currentObj.padding[property.split(".")[1]];
           } 
           return 0;
        }

        console.log(currentObj)
        console.log(property, currentObj[property])
        return currentObj[property];
    }

    getCurrentMutableObj(type): any {
        if (type == null) type = this.getPropValue(this.app, "settings.popupDesign.type");
        if (type === "classic") return this.mutableClassicObj;
        return this.mutableDefaultObj;
    }

    changeTheme($event) {
        this.app.settings.popupDesign.type = $event.target.value;
    }

    changeFontSize(property, $event) {
        let currentObj = this.getCurrentMutableObj(null);
        console.log(this.getCurrentMutableObj(null));
        
        currentObj[property] = $event.target.value;
    }

    changeFontFamily(property, $event) {
        let currentObj = this.getCurrentMutableObj(null);
        currentObj[property] = $event.target.value + "," + currentObj[property].split(",")[1];
    }

    getFontFamily(property) {
        let currentObj = this.getCurrentMutableObj(null);
        return this.capitalizeFirstLetter(currentObj[property]).split(',')[0];
    }

    changeColor(property, color) {
        let currentObj = this.getCurrentMutableObj(null);
        currentObj[property] = color;
        if (property == "closeIconColor") currentObj["audioIconColor"] = color;
    }

    changeNumber(property, $event) {
        $event.stopPropagation();
        let currentObj = this.getCurrentMutableObj(null);
        if ($event.target.value == "") $event.target.value = 0;
        this.enforceMinMax($event);
        currentObj[property] = parseInt($event.target.value);
    }

    changeStepPopupWidth = ($event) => {
        if ($event.target.value == "") this.app.settings.stepPopupWidth = 0;
        else this.app.settings.stepPopupWidth = parseInt($event.target.value);
    }

    changePadding = (prop, $event) => {
        $event.stopPropagation();
        let currentObj = this.getCurrentMutableObj(null);
        if(currentObj.padding == undefined) {
            currentObj.padding = {};
        }
        if ($event.target.value == "") $event.target.value = 0;
        this.enforceMinMax($event);
        currentObj.padding[prop] = parseInt($event.target.value);
    }

    capitalizeFirstLetter(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    saveTheme($event) {
        this.app.settings.popupDesign.current = this.getCurrentMutableObj(null);
        this.cutomizationService.apps[
            this.cutomizationService.apps.findIndex(app => { return this.appId == app.appId })].settings = JSON.parse(JSON.stringify(this.app.settings))
        this.addAppToChanged(this.app)
        this.closePopup($event);
    }

    addAppToChanged(app) {
        if (this.cutomizationService.changedApps.length >= 1) {
            const pos = this.cutomizationService.changedApps.findIndex((app) => {
                return app.appId === this.appId
            })
            if (pos > -1) {
                this.cutomizationService.changedApps.splice(pos, 1)
                this.cutomizationService.changedApps.push(app)

            } else {
                this.cutomizationService.changedApps.push(app)
            }
        }
        else {
            this.cutomizationService.changedApps.push(app)
        }
    }

    getPropertyValue = (o, prop) => {
        const [head, ...rest] = prop.split(".");
        if (rest.length && o.hasOwnProperty(head)) {
            return this.getPropertyValue(o[head], rest.join("."));
        } else {
            return o.hasOwnProperty(head) ? o[head] : "";
        }
    }

    getPropValue = (o: object, prop: string) => {
        let propValue = this.getPropertyValue(o, prop);
        if (JSON.stringify(propValue) != JSON.stringify("")) {
            return propValue;
        }
        return this.getPropertyValue(this.cutomizationService.apps[0], prop);
    }

    resetPopupTheme = () => {
        this.app.settings.popupDesign.type = "default";
        this.app.settings.stepPopupWidth = this.cutomizationService.apps[0].settings.stepPopupWidth;
        this.mutableDefaultObj = { ...this.cutomizationService.apps[0].settings.popupDesign.default };
        this.mutableClassicObj = { ...this.cutomizationService.apps[0].settings.popupDesign.classic };
    }

    enforceMinMax = (event) => {
        let el = (event.target as HTMLInputElement);
        if (el.value != "") {
            if (parseInt(el.value) < parseInt(el.min)) {
                el.value = el.min;
            }
            if (parseInt(el.value) > parseInt(el.max)) {
                el.value = el.max;
            }
        }
    }

    isDisabledSave() {
        let val = this.getPropValue(this.app, "settings.stepPopupWidth");
        if (isNaN(parseInt(val))) {
            return true;
        }
        if (val > 1000 || val < 250) {
            return true;
        }
        return false;
    }

    enforceMinMaxForStepPopup(event) {
        let el = (event.target as HTMLInputElement).value;
        if (el == "" || isNaN(parseInt(el))) {
            event.target.value = this.getPropertyValue(this.cutomizationService.apps[0], "settings.stepPopupWidth");
            this.app.settings.stepPopupWidth = this.getPropertyValue(this.cutomizationService.apps[0], "settings.stepPopupWidth");
        } else {
            this.enforceMinMax(event);
            this.app.settings.stepPopupWidth = parseInt((event.target as HTMLInputElement).value);
        }
    }

    getCdnSignature = () => {
        return this.cutomizationService.cdn_signature;
    }

    getPopupVisibility = () => {
        if (this.app === undefined || this.app.appId === undefined)
            return false;
        return true;
    }

    getPadding = () => {
        let currentObj = this.getCurrentMutableObj(null);
        if(currentObj.padding == undefined) {
            return "0";
        }
        let padding = this.getPadUtil(currentObj, "top")+this.getPadUtil(currentObj, "right")+this.getPadUtil(currentObj, "bottom")+this.getPadUtil(currentObj, "left");
        return padding;
    }

    getPadUtil = (obj, prop) => {
        if(obj.padding[prop]==undefined) return "0px ";
        return obj.padding[prop]+"px ";
    }
}

