<div class="modal fade" tabindex="-1" role="dialog" id="tutorialStep" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="getModalVisibility()">
      <div class="modal-header">
        <h4 class="modal-title">{{this.admin_data.tutorialModal}}</h4>
      </div>
      <div class="modal-body">
        <div class="preview card">
          <div class="preview-tutorial-container">
            <div class="preview-tutorial" [ngStyle]="{
                'border-color': getStyle('outerBox.border_color'),
                'border-width': getStyle('outerBox.border_width')+'px',
                'border-radius': getStyle('outerBox.border_radius')+'px',
                'box-shadow': getStyle('shadow')
            }">
              <div class="preview-tutorial-header" [ngStyle]="{'background-color': getStyle('titleBgColor')}">
                <div class="preview-tutorial-title-wrapper">{{this.admin_data.tutorial}}</div>
                <div class="icon-wrapper">
                  <div class="preview-tutorial-close-btn-wrapper">
                    <svg width="16px" height="16px" viewBox="0 0 8 8" version="1.1">
                      <defs></defs>
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="01-player-popup-Design-sample" transform="translate(-275.000000, -76.000000)">
                          <g id="Group-3" transform="translate(46.000000, 65.000000)">
                            <g class="Group" transform="translate(225.000000, 7.000000)">
                              <path fill="#8E90A5"
                                d="M7.42857143,7.42857143 L3,7.42857143 L3,8.57142857 L7.42857143,8.57142857 L7.42857143,13 L8.57142857,13 L8.57142857,8.57142857 L13,8.57142857 L13,7.42857143 L8.57142857,7.42857143 L8.57142857,3 L7.42857143,3 L7.42857143,7.42857143 Z"
                                id="Rectangle-221"
                                transform="translate(8.000000, 8.000000) rotate(-45.000000) translate(-8.000000, -8.000000) ">
                              </path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="preview-tutorial-description-wrapper">
                {{this.admin_data.dummyText}}
                <div class="preview-tutorial-navigation-wrapper">
                  <div class="defaultBtn" [ngStyle]="{
                    'background-color': getStyle('prevBtn.bgColor'),
                    'border-color': getStyle('prevBtn.border_color'),
                    'border-width': getStyle('prevBtn.border_width')+'px',
                    'border-radius': getStyle('prevBtn.border_radius')+'px',
                    'color': getStyle('prevBtn.color'),
                    'font-size': getStyle('prevBtn.font_size'),
                    'padding-top': getStyle('prevBtn.padding.top')+'px',
                    'padding-left': getStyle('prevBtn.padding.left')+'px',
                    'padding-bottom': getStyle('prevBtn.padding.bottom')+'px',
                    'padding-right': getStyle('prevBtn.padding.right')+'px'
                }">
                    <span>{{this.admin_data.manageContentVariable27}}</span>
                  </div>
                  <div class="defaultBtn" [ngStyle]="{
                    'background-color': getStyle('nextBtn.bgColor'),
                    'border-color': getStyle('nextBtn.border_color'),
                    'border-width': getStyle('nextBtn.border_width')+'px',
                    'border-radius': getStyle('nextBtn.border_radius')+'px',
                    'color': getStyle('nextBtn.color'),
                    'font-size': getStyle('nextBtn.font_size'),
                    'padding-top': getStyle('nextBtn.padding.top')+'px',
                    'padding-left': getStyle('nextBtn.padding.left')+'px',
                    'padding-bottom': getStyle('nextBtn.padding.bottom')+'px',
                    'padding-right': getStyle('nextBtn.padding.right')+'px'
                }">
                    <span>{{this.admin_data.manageContentVariable29}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="property card">
          <div class="card-body">
            <div class="title-col">
              <label for="titleBgColor">{{this.admin_data.titleBgColor}}:</label>
              <input name="titleBgColor"  type="color" class="colorPicker" (input)="changeProp('titleBgColor', $event.target.value)"
                [value]="getPropValue('titleBgColor')">
            </div>
            
            <div class="btn-reset-settings adjust-margin" (click)="reset()">
              {{this.admin_data.reset}}
          </div>
          </div>
          <br />
          <div class="heading-label">{{this.admin_data.prevBtnLabel}}</div>
          <div class="card-body">
            <div class="attr-row">
              <label for="prevButtonColor">{{this.admin_data.color}}:</label>
              <input name="prevButtonColor"  type="color" class="colorPicker" (input)="changeProp('prevBtn.color', $event.target.value)"
                [value]="getPropValue('prevBtn.color')">
            </div>
            <div class="attr-row">
              <label for="prevButtonBgColor">{{this.admin_data.background}}:</label>
              <input name="prevButtonBgColor"  type="color" class="colorPicker" (input)="changeProp('prevBtn.bgColor', $event.target.value)"
                [value]="getPropValue('prevBtn.bgColor')">
            </div>
            <div class="attr-row">
              <label for="prevButtonBorderColor">{{this.admin_data.borderColor}}:</label>
              <input name="prevButtonBorderColor"  type="color" class="colorPicker"
                (input)="changeProp('prevBtn.border_color', $event.target.value)"
                [value]="getPropValue('prevBtn.border_color')">
            </div>
          </div>
          <div class="card-body">
            <div class="attr-row">
              <label for="prevButtonFontSize">{{this.admin_data.fontSize}}:</label>
              <select name="prevButtonFontSize" class="select-input" [value]="getPropValue('prevBtn.font_size')"
                (change)="changeProp('prevBtn.font_size', $event.target.value)">
                <option *ngFor="let item of fontSizes" [ngStyle]="{'font-size': item}" [selected]="getPropValue('prevBtn.font_size')==item" >{{item}}</option>
              </select>
            </div>
            <div class="attr-row">
              <label for="prevButtonBorderWidth">{{this.admin_data.borderWidth}}:</label>
              <input name="prevButtonBorderWidth" type="number" min="0" max="5" (keydown)="preventInput($event)"
                (change)="changeProp('prevBtn.border_width', $event.target.value)"
                [value]="getPropValue('prevBtn.border_width')">
            </div> 
            <div class="attr-row">
              <label for="prevButtonBorderRadius">{{this.admin_data.borderRadius}}:</label>
              <input name="prevButtonBorderRadius" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('prevBtn.border_radius', $event.target.value)"
                [value]="getPropValue('prevBtn.border_radius')">
            </div>
          </div>
          <div class="card-body">
            <div class="attr-row">
              <label for="prevButtonTop">{{this.admin_data.top}}:</label>
              <input name="prevButtonTop" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('prevBtn.padding.top', $event.target.value)"
                [value]="getPropValue('prevBtn.padding.top')">
            </div>
            <div class="attr-row">
              <label for="prevButtonBottom">{{this.admin_data.bottom}}:</label>
              <input name="prevButtonBottom" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('prevBtn.padding.bottom', $event.target.value)"
                [value]="getPropValue('prevBtn.padding.bottom')">
            </div>
            <div class="attr-row">
              <label for="prevButtonLeft">{{this.admin_data.left}}:</label>
              <input name="prevButtonLeft" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('prevBtn.padding.left', $event.target.value)"
                [value]="getPropValue('prevBtn.padding.left')">
            </div>
          </div>
          <div class="card-body">
            <div class="attr-row">
              <label for="prevButtonRight">{{this.admin_data.right}}:</label>
              <input name="prevButtonRight" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('prevBtn.padding.right', $event.target.value)"
                [value]="getPropValue('prevBtn.padding.right')">
            </div>
          </div>
          <div class="heading-label">{{this.admin_data.nextBtnLabel}}</div>
          <div class="card-body">
            <div class="attr-row">
              <label for="nextButtonColor">{{this.admin_data.color}}:</label>
              <input name="nextButtonColor"  type="color" class="colorPicker" (input)="changeProp('nextBtn.color', $event.target.value)"
                [value]="getPropValue('nextBtn.color')">
            </div>
            <div class="attr-row">
              <label for="nextButtonBgColor">{{this.admin_data.background}}:</label>
              <input name="nextButtonBgColor"  type="color" class="colorPicker" (input)="changeProp('nextBtn.bgColor', $event.target.value)"
                [value]="getPropValue('nextBtn.bgColor')">
            </div>
            <div class="attr-row">
              <label for="nextButtonBorderColor">{{this.admin_data.borderColor}}:</label>
              <input name="nextButtonBorderColor"  type="color" class="colorPicker"
                (input)="changeProp('nextBtn.border_color', $event.target.value)"
                [value]="getPropValue('nextBtn.border_color')">
            </div>
          </div>
          <div class="card-body">
            <div class="attr-row">
              <label for="nextButtonFontSize">{{this.admin_data.fontSize}}:</label>
              <select name="nextButtonFontSize" class="select-input" [value]="getPropValue('nextBtn.font_size')"
                (change)="changeProp('nextBtn.font_size', $event.target.value)">
                <option *ngFor="let item of fontSizes" [ngStyle]="{'font-size': item}" [selected]="getPropValue('nextBtn.font_size')==item">{{item}}</option>
              </select>
            </div>
            <div class="attr-row">
              <label for="nextButtonBorderWidth">{{this.admin_data.borderWidth}}:</label>
              <input name="nextButtonBorderWidth" type="number" min="0" max="5" (keydown)="preventInput($event)"
                (change)="changeProp('nextBtn.border_width', $event.target.value)"
                [value]="getPropValue('nextBtn.border_width')">
            </div>
            <div class="attr-row">
              <label for="nextButtonBorderRadius">{{this.admin_data.borderRadius}}:</label>
              <input name="nextButtonBorderRadius" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('nextBtn.border_radius', $event.target.value)"
                [value]="getPropValue('nextBtn.border_radius')">
            </div>
          </div>
          <div class="card-body">
            <div class="attr-row">
              <label for="nextButtonTop">{{this.admin_data.top}}:</label>
              <input name="nextButtonTop" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('nextBtn.padding.top', $event.target.value)"
                [value]="getPropValue('nextBtn.padding.top')">
            </div>
            <div class="attr-row">
              <label for="nextButtonBottom">{{this.admin_data.bottom}}:</label>
              <input name="nextButtonBottom" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('nextBtn.padding.bottom', $event.target.value)"
                [value]="getPropValue('nextBtn.padding.bottom')">
            </div>
            <div class="attr-row">
              <label for="nextButtonLeft">{{this.admin_data.left}}:</label>
              <input name="nextButtonLeft" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('nextBtn.padding.left', $event.target.value)"
                [value]="getPropValue('nextBtn.padding.left')">
            </div>
          </div>
          <div class="card-body">
            <div class="attr-row">
              <label for="nextButtonRight">{{this.admin_data.right}}:</label>
              <input name="nextButtonRight" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('nextBtn.padding.right', $event.target.value)"
                [value]="getPropValue('nextBtn.padding.right')">
            </div>
          </div>
          <div class="heading-label">{{this.admin_data.outerBox}}</div>
          <div class="card-body">
            <div class="attr-row">
              <label for="outerBoxBorderColor">{{this.admin_data.borderColor}}:</label>
              <input name="outerBoxBorderColor"  type="color" class="colorPicker"
                (input)="changeProp('outerBox.border_color', $event.target.value)"
                [value]="getPropValue('outerBox.border_color')">
            </div>
            <div class="attr-row">
              <label for="outerBoxBorderWidth">{{this.admin_data.borderWidth}}:</label>
              <input name="outerBoxBorderWidth" type="number" min="0" max="5" (keydown)="preventInput($event)"
                (change)="changeProp('outerBox.border_width', $event.target.value)"
                [value]="getPropValue('outerBox.border_width')">
            </div>
            <div class="attr-row">
              <label for="outerBoxBorderRadius">{{this.admin_data.borderRadius}}:</label>
              <input name="outerBoxBorderRadius" type="number" min="0" max="30" (keydown)="preventInput($event)"
                (change)="changeProp('outerBox.border_radius', $event.target.value)"
                [value]="getPropValue('outerBox.border_radius')">
            </div>
          </div>
          <div class="heading-label">{{this.admin_data.outerBoxShadow}}</div>
          <div class="card-body">
            <div class="attr-nest-row">
              <label for="outerBoxShadowBlur">{{this.admin_data.blur}}:</label>
              <input name="outerBoxShadowBlur" type="number" min="0" max="10" (keydown)="preventInput($event)"
                (change)="changeProp('outerBox.shadow.blur', $event.target.value)"
                [value]="getPropValue('outerBox.shadow.blur')">
            </div>
            <div class="attr-nest-row">
              <label for="outerBoxShadowSpread">{{this.admin_data.spreadRadius}}:</label>
              <input name="outerBoxShadowSpread" type="number" min="0" max="5" (keydown)="preventInput($event)"
                (change)="changeProp('outerBox.shadow.spreadRadius', $event.target.value)"
                [value]="getPropValue('outerBox.shadow.spreadRadius')">
            </div>
            <div class="attr-nest-row">
              <label for="outerBoxShadowInset" class="label-cb">{{this.admin_data.inset}}:</label>
              <input name="outerBoxShadowInset" type="checkbox" [checked]="getPropValue('outerBox.shadow.inset')"
                (change)="changeProp('outerBox.shadow.inset', $event.target.checked)">
            </div>
          </div>
          <div class="card-body">
            <div class="attr-nest-row">
              <label for="outerBoxShadowColor">{{this.admin_data.color}}:</label>
              <input name="outerBoxShadowColor"  type="color" class="colorPicker"
                (input)="changeProp('outerBox.shadow.color', $event.target.value)"
                [value]="getPropValue('outerBox.shadow.color')">
            </div>
            <div class="attr-nest-row">
              <label for="outerBoxShadowHorizontal">{{this.admin_data.horizontal}}:</label>
              <input name="outerBoxShadowHorizontal" type="number" min="0" max="10" (keydown)="preventInput($event)"
                (change)="changeProp('outerBox.shadow.horizontal', $event.target.value)"
                [value]="getPropValue('outerBox.shadow.horizontal')">
            </div>
            <div class="attr-nest-row">
              <label for="outerBoxShadowVertical">{{this.admin_data.vertical}}:</label>
              <input name="outerBoxShadowVertical" type="number" min="0" max="10" (keydown)="preventInput($event)"
                (change)="changeProp('outerBox.shadow.vertical', $event.target.value)"
                [value]="getPropValue('outerBox.shadow.vertical')">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-discard btn-secondary" (click)="closePopup($event)">
          {{this.admin_data.discardBtnTxt}}
        </button>
        <button type="button" class="btn btn-primary" (click)="save($event)">
          {{this.admin_data.apply}}
        </button>
      </div>
    </div>
  </div>
</div>