<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <ng-container>
            <div class="d-flex justify-content-end my-3">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9 p-0 text-right ">
                    <div class="input-group w-50 ml-2 d-inline-flex width-300">
                        <input class="form-control py-2 search" type="search"
                            placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
                            (input)="searchOrgList()" (search)="searchOrgList()" aria-label="Search"
                            [(ngModel)]="search_text">
                        <!-- <span class="input-group-append">
                            <div class="input-group-text bg-white">
                                <svg-icon src="../../../../assets/svgs/search-icon.svg"></svg-icon>
                            </div>
                        </span> -->
                    </div>
                </div>
            </div>
        </ng-container>
        <div style="display: flex; flex-direction: column;">
            <div class="d-flex scroll-x w-1240 table-div">
                <table class="table  table-bordered break-w">
                    <thead *ngIf="orgList.length> 0">
                        <tr>
                            <th *ngFor="let title of table_titles">
                                <span class="thead-title-wrap">{{ title }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="table-body-cust">
                        <tr *ngFor="let org of orgList; index as i">
                            <td>{{org.title}}</td>
                            <td><button class="btn btn-primary"
                                    (click)="changeMappingStatus(i, org.id, org.mappingStatus === 'assign'? 'unassign': 'assign')">
                                    {{org.mappingStatus === 'assign' ? admin_data.unassign : admin_data.assign }}
                                </button></td>
                            <!-- <td >{{org.mappingStatus}}</td> -->
                            <!-- <td>
                            <svg-icon (click) = "performEdit(partner.id, $event)" src="../../../assets/svgs/emit_edit.svg"></svg-icon>
                            <svg-icon (click) = "showModal(partner.id, $event)" src="../../../assets/svgs/emit_delete.svg"></svg-icon>
                        </td> -->
                        </tr>
                </table>
            </div>
        </div>
        <app-pagination [offset]="offset" [totalRecords]="paginationService.total" [currentPage]="paginationService.currentPage"
            [limit]="limit" (gotoFirstPage)="gotoFirstPage()" (gotoLastPage)="gotoLastPage($event)"
            (gotoPreviousPage)="prependOffsetAndGetUsers()" (gotoNextPage)="appendOffsetAndGetUsers()" (changeLimit)="changeLimit($event)"
            (gotoAction)="gotoPage($event)"></app-pagination>

    </div>
</div>