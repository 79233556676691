<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">
            <div class="sectionBody">
                <div class="d-flex justify-content-end mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.manageRoles}}
                    </div>
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right">
                        <div *ngIf="!assignRole" class="input-group w-50 mx-2 d-inline-flex width-300">
                            <input id="searchInputApp" class="form-control py-2 search" type="search"
                                autocomplete="off" placeholder={{this.admin_data.searchPlaceholder}}
                                (input)="validateInput($event)" (search)="searchRoleList()" aria-label="Search"
                                [(ngModel)]="search">
                            <!-- <span class="input-group-append">
                                <div class="input-group-text bg-white">
                                    <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                                </div>
                            </span> -->
                        </div>

                        <ng-container *ngIf="!(userService.user.role == 'app_admin')">
                            <button *ngIf="!assignRole" type="button" class="btn btn-primary ml-2"
                                (click)="showAddRoleModal()">{{this.admin_data.manageRoleVariable5}}</button>
                            <button *ngIf="assignRole" type="button" class="btn btn-primary ml-2"
                                (click)="assignRoles()"
                                [disabled]="roleService.assignRoleList.length==0">{{this.admin_data.manageRoleVariable1}}</button>
                        </ng-container>

                    </div>
                </div>
                <app-table [titles]="roleService.appTitles" [dataKeys]="roleService.appDataKeys" [data]="roles"
                    [dataCopy]="roles" [app_roles]="roleService.applicationRoles" (edit)="getRoleData($event)"
                    (delete)="showDeleteModal($event)" [widths]="roleService.col_widths"></app-table>
                <!-- <app-table *ngIf="!assignRole" [data]="roles" [titles]="roleService.appRoleTitles" [dataKeys]="roleService.appRoleDataKeys"
                    [widths]="roleService.col_widths" [dataCopy]="roles" [app_roles]="roleService.applicationRoles"
                    (edit)="getRoleData($event)" (delete)="showDeleteModal($event)"></app-table> --><app-pagination [offset]="offset" [totalRecords]="paginationService.total" [currentPage]="paginationService.currentPage"
                        [limit]="limit" (gotoFirstPage)="gotoFirstPage()" (gotoLastPage)="gotoLastPage($event)"
                        (gotoPreviousPage)="prependOffsetAndGetRoles()" (gotoNextPage)="appendOffsetAndGetRoles()" (changeLimit)="changeLimit($event)"
                        (gotoAction)="gotoPage($event)"></app-pagination>
                <br>

                <ng-container *ngIf="roles">
                    <ng-container *ngIf="roles.length>0">
                        <app-delete-modal [delete_elem]="roles[deleteIndex]['title']" (onDelete)="deleteRole($event)">
                        </app-delete-modal>
                    </ng-container>
                </ng-container>
            </div>
        </section>


        <div class="modal fade" id="addRoleModal" tabindex="-1" role="dialog" aria-labelledby="addRoleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addRoleModalLabel">{{this.admin_data.manageRoleVariable5}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="closeAddRoleModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <app-form [formData]="role_formData" [formGroup]="formGroup"></app-form>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
                            (click)="closeAddRoleModal()">{{this.admin_data.cancelButtonText}}</button>
                        <!-- <button type="button" class="btn btn-primary minWidth-120"
                            (click)="roleService.isEdit?editRole():addRoles()" [disabled]="!formGroup.valid">
                            <ng-container *ngIf="roleService.isEdit">Update Role</ng-container>
                            <ng-container *ngIf="!roleService.isEdit">Add Role</ng-container>
                        </button> -->
                        <app-button *ngIf="!roleService.isEdit" [disabled]="!formGroup.valid"
                            [buttonData]="roleService.add_button" (clickhandler)="addRoles($event)"></app-button>
                        <app-button *ngIf="roleService.isEdit" [disabled]="!formGroup.valid"
                            [buttonData]="roleService.update_button" (clickhandler)="editRole($event)"></app-button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>