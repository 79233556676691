<section class="component-section">
    <div class="sectionBody">
        <div class="d-flex justify-content-end mb-3">

            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                {{this.admin_data.applicationsBreadCrumbTitle}}
            </div>

            <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right d-flex align-items-center justify-content-end">
                <div *ngIf="!assignApp" class="input-group w-50 ml-2 d-inline-flex width-300">
                    <input id="searchInputApp" class="form-control py-2 search" type="search" autocomplete="off"
                        placeholder={{this.admin_data.searchPlaceholder}} (input)="validateInput($event);"
                        (search)="searchApplicationList()" aria-label="Search" [(ngModel)]="search">
                    <!-- <span class="input-group-append">
                        <div class="input-group-text bg-white">
                            <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                        </div>
                    </span> -->
                </div>
                <div *ngIf="!assignApp" id="filter" class="filter-dropdown ml-2">
                    <button class="btn btn-lg pl-0 pr-0 minWidth-43" type="button" id="filter-menu"
                        data-toggle="dropdown">
                        <svg-icon src="../../../assets/svgs/filter.svg"></svg-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-app" role="menu" aria-labelledby="filter-menu"
                        id="filter-dropdown-menu" (click)="$event.stopPropagation()">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left px-3 pt-3">
                            <h6 class="font-weight-bold">{{this.admin_data.filterBy}}</h6>
                        </div>
                        <hr>
                        <div class="form-group col-md-12 text-left d-inline-block mb-4">
                            <label class="font-weight-bold" for="status">{{this.admin_data.tableHeaderType}}</label>
                            <select #type class="form-control form-control-sm" id="status">
                                <option value="all">{{this.admin_data.all}}</option>
                                <option value="guide">{{this.admin_data.web}}</option>
                                <option value="electron">{{this.admin_data.desktop}}</option>
                                <option value="android">{{this.admin_data.android}}</option>
                                <option value="ios">{{this.admin_data.ios}}</option>
                                <option value="mobile_web">{{this.admin_data.mobileWeb}}</option>
                            </select>
                        </div>
                        <div
                            class="filter-footer col-md-12 text-center d-flex align-items-center justify-content-center p-2">
                            <button type="button" class="btn btn-sm btn-secondary mx-2 px-4" (click)="close()">
                                {{this.admin_data.cancelButtonText}}</button>
                            <button type="button" class="btn btn-sm btn-primary mx-2 px-4"
                                (click)="applyFilter(type.value);$event.stopPropagation()">
                                {{this.admin_data.apply}}</button>
                        </div>
                    </div>
                </div>
                <button *ngIf="!assignApp && !(userService.user.role=='app_admin' || userService.user.role=='owner')"
                    type="button" class="btn btn-primary ml-1"
                    [routerLink]="['/organization',userService.organization_id,'application','add']">{{this.admin_data.addApplicationAddBreadcrumb}}</button>
                <button *ngIf="assignApp" type="button" class="btn btn-primary ml-2" (click)="assignApps()"
                    [disabled]="apiAndService.assignApplications.length==0">{{this.admin_data.applicationsAssignTitle}}</button>
            </div>
        </div>
        <app-table [data]="applications" [titles]="application.appTitles" [dataKeys]="application.appDataKeys"
            (edit)="performEdit($event)" (delete)="showModal($event)" (roles)="showRoles($event)"
            [dataCopy]="applications" [secret_apps]="apiAndService.keyApplications" [widths]="application.col_widths"
            (changeAutomationStatus)="showAutomationStatusModal($event)">
        </app-table>
        <app-pagination [offset]="offset" [totalRecords]="paginationService.total"
            [currentPage]="paginationService.currentPage" [limit]="limit" (gotoFirstPage)="gotoFirstPage()"
            (gotoLastPage)="gotoLastPage($event)" (gotoPreviousPage)="prependOffsetAndGetApplications()" (gotoNextPage)="appendOffsetAndGetApplications()"
            (changeLimit)="changeLimit($event)" (gotoAction)="gotoPage($event)"></app-pagination>
        <br>
        <ng-container *ngIf="applications">
            <ng-container *ngIf="applications.length>0">
                <app-delete-modal [delete_elem]="applications[deleteIndex]['title']"
                    (onDelete)="deleteApplication($event)"></app-delete-modal>
            </ng-container>
        </ng-container>

        <div *ngIf="applications" class="modal fade" id="automationStatusModal" tabindex="-1" role="dialog"
            aria-labelledby="automationStatusModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteSegModalLabel">
                            {{this.admin_data.applicationsUpdateAutomationStatus}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ng-container *ngIf="applications[statusIndex]">
                        <div class="modal-body">
                            <div class="m-0 padding15" role="alert">
                                <h6 id="deleteSegModalLabel">{{this.admin_data.changeAutomationStatus}}</h6>
                                <b class="wrap">{{applications[statusIndex]['title']}}</b>
                            </div>
                        </div>
                    </ng-container>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
                            (click)="closeAutomationStatusModal()">
                            <ng-container>{{this.admin_data.no}}</ng-container>
                        </button>
                        <button type="button" class="btn btn-primary minWidth-120"
                            (click)="changeAutomationStatus()">{{this.admin_data.yes}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
