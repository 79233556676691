import { Component, OnInit } from '@angular/core';

import adminLabels from "../../../constants/ApplicationStrings/trans_utils";
import { UserService } from 'src/app/common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/common/services/api.service';
import { RoleService } from 'src/app/common/services/role.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { PaginationService } from 'src/app/pagination.service';
@Component({
    selector: 'app-users-list-mapping',
    templateUrl: './users-list-mapping.component.html',
    styleUrls: ['./users-list-mapping.component.css']
})
export class UsersListMappingComponent implements OnInit {

    user_id = ""
    breadcrumb;
    admin_data = adminLabels;
    search_text = "";
    limit = 10;
    offset = 0;
    orgList = [];
    partner_id = "0";
    col_widths = ['70%', '30%'];
    pageLimitSet = false;
    table_titles = [this.admin_data.tableHeaderTitle, this.admin_data.tableHeaderActions]
    constructor(
        public userService: UserService,
        private router: Router,
        private apiService: ApiService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private titleService: Title,
        public paginationService: PaginationService,) {
        userService.getUserData()
        this.paginationService.initializeValues();
        if (this.userService.partner_id !== "") {
            this.partner_id = this.userService.partner_id
        }
        this.route.paramMap.subscribe((params) => {
            if (params['params']['user_id']) {
                this.user_id = params['params']['user_id']
                this.router.events
                    .pipe(filter((event) => event instanceof NavigationEnd))
                    .subscribe(() => {
                        let breadCrumb = [
                            {
                                name: this.admin_data.usersBreadCrumbTitle,
                                route: ['/users']
                            },
                            {
                                name: this.admin_data.orgAssign,
                                route: ['/users/org/assignment', this.user_id]
                            }
                        ];
                        if (this.userService.organization_id === "000") {
                            let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
                            breadCrumb.unshift(tempDashboardBreadCrumb);
                        }
                        this.userService.setBreadcrumb(breadCrumb)
                        this.breadcrumb = this.userService.breadCrumbs;
                    }
                    );

            }
        });
        this.getOrgList()
    }

    ngOnInit() {
    }

    searchOrgList() {
        // this.limit = 10;
        this.offset = 0
        this.paginationService.initializeValues();
        this.getOrgList();
    }

    getOrgList() {

        this.userService.getOrgList(this.limit, this.offset, this.user_id, this.partner_id, this.search_text).subscribe((response) => {
            this.orgList = [];
            this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
            this.pageLimitSet = true;
            let array = response["data"]["organizations"]
            for (let index = 0; index < array.length; index++) {

                let orgData = {
                    id: array[index]["organization_id"],
                    title: array[index]["name"],
                    mappingStatus: array[index]["mapping_status"]
                }
                this.orgList.push(orgData)
            }
            this.paginationService.setPaginationStatus(this.orgList, this.limit);

        })
    }


    appendOffsetAndGetUsers() {

        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getOrgList()
        }
    }

    setIndex(index) {
        let offset = this.paginationService.setIndex(index, this.limit, this.offset);
        if (offset >= 0) {

            this.offset = offset;
            this.getOrgList();
        }

    }

    prependOffsetAndGetUsers() {
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getOrgList()
        }
    }
    changeMappingStatus(pos, org_id, action) {
        let request = {
            organization_id: org_id,
            user_id: this.user_id,
            action: action
        }
        this.userService.assignOrgRoleGlobal(request).subscribe((response) => {
            if (response["error"] === false) {
                this.orgList[pos]["mappingStatus"] = action;
            }

        })
    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getOrgList()
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getOrgList();
    }
}
