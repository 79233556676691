<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb" templateVar="findReplace" >
        </app-breadcrumb>

        <section class="component-section">
            
            <div class="sectionBody">

                <div class="d-flex justify-content-end mb-3">
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{this.admin_data.findReplace}}
                    </div>



                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right display-flex gap-10 justify-content-end">

                        <button class="btn btn-discard btn-secondary" *ngIf="this.selectedNum!=0" disabled="this.updatingData"
                        (click)="cancelSelection()">{{this.admin_data.cancelButtonText}}</button>

                        <button type="button" class="btn btn-save btn-primary"
                            (click)="refreshSection()">{{this.admin_data.refresh}}</button>
                    </div>
                </div>


                <ng-container>
                    <div class="main-content-container">
                        <div class="filter-container">
                            <div class="filter-wrapper">
                                <label>{{this.admin_data.apps}}:</label>
                                <select class="form-control"  (change)="changeApp($event);">
                                    <option value="" selected disabled>{{this.admin_data.select}}</option>
                                    <ng-container *ngFor="let app of apps">
                                        <option value="{{app.application_id}}"
                                            [selected]="app.application_id==selectedAppId">
                                            {{app.title}}</option>
                                    </ng-container>
                                </select>
                            </div>
                            <!-- <div class="filter-wrapper">
                                <label>{{this.admin_data.content}}:</label>
                                <select class="form-control" disabled>
                                    <option value="text">{{this.admin_data.text}}</option>
                                </select>
                            </div> -->
                            <div class="filter-wrapper">
                                <label>{{this.admin_data.find}}:</label>
                                <input class="form-control" type="text" [(ngModel)]="searchVal" (input)="onInput()"
                                    [disabled]="this.statusTableData.length>0"
                                    placeholder="{{this.admin_data.findReplaceSearchPlaceholder}}">
                            </div>
                            <div class="filter-wrapper">
                                <label>{{this.admin_data.replace}}:</label>
                                <input class="form-control" [disabled]="this.statusTableData.length>0 || this.searchVal.trim()==''"
                                    type="text" [(ngModel)]="replaceVal" (input)="replaceText()"
                                    placeholder="{{this.admin_data.replace}}">
                            </div>

                            <div class="btn-wrapper">
                                <button class="btn btn-save btn-primary"
                                    [disabled]="this.updatingData || selectedNum==0 || this.replaceVal.trim()=='' || this.searchVal.trim() == this.replaceVal.trim()"
                                    (click)="replace()">{{this.admin_data.replace}}</button>
                                <button class="btn btn-discard btn-secondary"
                                    [disabled]="this.updatingData || this.searchVal.trim()==''"
                                    (click)="clear()">{{this.admin_data.clear}}</button>
                            </div>
                        </div>
                        <div class="table-container" (scroll)="onTableScroll($event)">
                            <div class="card {{this.updatingData?'non-clickable':''}}">
                                <table *ngIf="this.tableData && this.tableData.length > 0">
                                    <thead>
                                        <tr>
                                            <th>
                                                
                                                <label class="custom-check" >
                                                    <input type="checkbox" class="check" (change)="onSelectAll($event)">
                                                    <span class="checkmark mr-3"></span>
                                                    <span class="mx-3"></span>
                                                </label>
                                            </th>
                                            <ng-container *ngFor="let header of tableHeader">
                                                <th>{{header}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let data of tableData; index as i">
                                            <tr>
                                                <td>

                                                    <label class="custom-check" >
                                                        <input type="checkbox" class=""  [(ngModel)]="selectedTableData[i]"
                                                        (click)="onSelect($event, i);" />
                                                        <span class="checkmark mr-3"></span>
                                                        <span class="mx-3"></span>
                                                    </label>

                                                   

                                                <td>
                                                    <div *ngIf="!isSearchResult" class="data-wrapper">
                                                        <img class="guide-logo" [src]="getIcon(data)">
                                                        <span>{{data.tour_title}}</span>
                                                    </div>

                                                    <div *ngIf="isSearchResult" class="data-wrapper">
                                                        <div class="image-wrapper">
                                                            <img class="guide-logo" [src]="getIcon(data)">
                                                        </div>
                                                        <div class="guide-detail-wrapper">
                                                            <div class="guide-title-wrapper"
                                                                [innerHTML]="data.tour_title">
                                                            </div>
                                                            <div class="guide-description-wrapper"
                                                                [innerHTML]="data.tour_description"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="data-wrapper">{{data.category_title}}</div>
                                                </td>
                                                <td>
                                                    <div class="data-wrapper status-msg">
                                                        <span>{{statusTableData[i]}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div *ngIf="!this.apiLoading && this.tableData && this.tableData.length == 0"
                                    class="no-guide">
                                    <img src="./../../assets/svgs/no_guides_image.svg">
                                    <div class="no-guide-label-1">
                                        <p>{{this.admin_data.noGuideFound}}</p>
                                    </div>
                                    <div class="no-guide-label-2">
                                        <p>{{this.admin_data.modifySearchCriteria}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </section>
    </div>
</div>