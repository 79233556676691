import { Injectable } from "@angular/core";
import { httpOptions, UserService } from "./user.service";
import {
  HttpClientModule,
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import {API_END_POINTS} from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

export interface DBUser {
  first_name: string;
  last_name: string;
  user_email: string;
  role: string;
  org_role_id: string;
  total_rows: string;
  image_url: string;
}

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private userService: UserService, private http: HttpClient) { }
  closeNav = false;

  trigger_button={
    type:'trigger_orgjson',
    class:'btn btn-primary marginLeft-16',
    disable:false,
    text: adminLabels.dashboardTriggerInstantUpdateButton,
    tooltip:adminLabels.dashboardTriggerInstantUpdateButtonTooltip,
  }

  clear_session={
    type:'clear_orgsession',
    class:'btn btn-primary marginLeft-16',
    disable:false,
    text: adminLabels.dashboardClearSessionButton,
    tooltip:adminLabels.dashboardClearSessionButtonTooltip
  }

  DBtableHead = [
    adminLabels.addUserAvtar,
    adminLabels.addUserFirstName,
    adminLabels.addUserLastName,
    adminLabels.addUserEmailId,
    adminLabels.tableHeaderStatus,
    adminLabels.addUserLockedStatus,
    adminLabels.addUserRole,
    adminLabels.addUserOrganisationRole,
    adminLabels.dashboardUserAction,
  ];
  
  userKeys = [
    "image_url",
    "first_name",
    "last_name",
    "user_email",
    "is_suspend",
    "is_locked",
    "role",
    "org_role_id",
    "user_action",
  ];

  DBtableHead_global = [
    adminLabels.addUserFirstName,
    adminLabels.addUserLastName,
    adminLabels.addUserEmailId,
    adminLabels.tableHeaderStatus,
    adminLabels.addUserLockedStatus,
    // adminLabels.addOrganisationName,
    adminLabels.addUserRole,
    // adminLabels.dashboardUserKey,
    adminLabels.dashboardRegisteredDate,
    adminLabels.dashboardUserAction
  ];

  userKeys_global = [
    '',
    "first_name",
    "last_name",
    "user_email",
    "is_suspend",
    "is_locked",
    // "organization_name",
    "role",
    // "user_key",
    "creation_date",
    "user_action"
  ]

  DBtableHead_global_external = [
    adminLabels.addUserFirstName,
    adminLabels.addUserLastName,
    adminLabels.addUserEmailId,
    adminLabels.tableHeaderStatus,
    adminLabels.addUserLockedStatus,
    adminLabels.addOrganisationName,
    adminLabels.addUserRole,
    // adminLabels.dashboardUserKey,
    adminLabels.dashboardRegisteredDate,
    adminLabels.dashboardUserAction
  ];

  userKeys_global_external = [
    '',
    "first_name",
    "last_name",
    "user_email",
    "is_suspend",
    "is_locked",
    "organization_name",
    "role",
    // "user_key",
    "creation_date",
    "user_action"
  ]

  col_widths = ['5%','13.5%','13.5%','13.5%','8%','8%','13.5%','13.5%','15%'];
  toggleCloseNav() {
    this.closeNav = !this.closeNav;
    var div1 = document.getElementById("cont");
    var div2 = document.getElementById("sideNav");
    var div3 = document.getElementById("flexNav");
    div3!.classList.toggle("closed");
    div1!.classList.toggle("container-open");
    div2!.classList.toggle("sidenav-closed");
  }

  getDashboard(): Observable<{}> {
    let dashboardOptions = {
      headers: new HttpHeaders({
        // AppKey: environment.authKey,
        AccessToken: this.userService.user.token,
      }),
    };
    let urlPath;
    if (
      (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" ) &&
      this.userService.organization_id == "000"
    ) {
      if(this.userService.user.role == "partner_admin")
      {
        urlPath = environment.baseUrl + API_END_POINTS.GET_DASHBOARD + "?partner_id=" + this.userService.user.partner_id;
      }
      else
      {
        urlPath = environment.baseUrl + API_END_POINTS.GET_DASHBOARD;
      }
    } else {
      urlPath =
        environment.baseUrl +
        API_END_POINTS.GET_DASHBOARD+"?"+
        "organization_id=" +
        this.userService.organization_id;

        if(this.userService.user.role == "partner_admin")
        {
          urlPath = urlPath + "&partner_id=" + this.userService.user.partner_id;
        }

    }
    return this.http.get(urlPath, dashboardOptions);
  }

  triggerUpdate(request){
    let dashboardOptions = {
      headers: new HttpHeaders({
        // AppKey: environment.authKey,
        AccessToken: this.userService.user.token,
      })
  }
  let urlPath;
  urlPath = environment.baseUrl + API_END_POINTS.FORCE_PUSH_UPDATES;
  return this.http.put(urlPath,request,dashboardOptions);
}

clearSession(request){
  let dashboardOptions = {
    headers: new HttpHeaders({
    //   AppKey: environment.authKey,
      AccessToken: this.userService.user.token,
    })
}
let urlPath;
urlPath = environment.baseUrl + API_END_POINTS.ORG_CLEAR_SESSION;
return this.http.post(urlPath,request,dashboardOptions);
}
}
