import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject, of, tap } from 'rxjs';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import {API_END_POINTS} from '../../constants/api-end-points';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";


export interface Organisation
{
  organization_id: string,
  name: string,
  about: string,
  image_id: string,
  orgkey: string,
  bucket: string,
  settings:
  {
  first_slide_text:string,
  first_slide:string,
  last_slide_text:string,
  copyright_text:string,
  last_slide:string,
  widget_icon_path:string,
  dual_auth:string
  },
  is_active: string,
  creation_date: string,
  modification_date: string,
  is_verified: string,
  is_suspend: string,
  admin_settings: {
    language_settings:
    {
      default:
        {
          language:string,
          voice:string,
          name:string
        },
      translations:[
        {
          language:string,
          voice:string,
          name:string
        }
    ],
    },
    theme_settings: any,
    doitforme:number,
    chatbot:number,
    lms:number,
    myteacher:number,
    onboarding_guides:number,
    app_switcher:number,
    guide_translation:number,
    current_page_beacon:number,
    guide_segmentation:number,
    all_guide:number,
    current_page:number,
    guide_limit:number,
    myintel:number,
    cdn_player:number,
    tooltip_enhancements:number,
    isPITrackingEnabled:number,
    sso:number,
    publish_limit:number,
    mediafile_creation:number,
    youtube_setting:number,
    enable_org_and_guide_json_caching:number
  }
  sync_priority: string,
  is_sync_complete: string,
  organization_type: string,
  on_premise_config: any,
  image_path: string,
  image_url: string,
  cdn_signature: string,
  theme_settings: {
    activeThemeId: string,
    themes: object
  }
}


@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
    allRequestOptions:any;
    constructor(private userService:UserService,private http: HttpClient,private apiService:ApiService) {
        this.allRequestOptions ={
            headers: new HttpHeaders({
            //   'AppKey': environment.authKey,
              'AccessToken':this.userService.user.token
          })
        } 
    }
    
  showConfirmationModal = new Subject<boolean>();
  cdn_signature;
  cdn_signature_expiry;
  

  add_button={
    type:'add_organization',
    class:'btn btn-primary minWidth-120 mx-2',
    disable:false,
    text: adminLabels.addButtonText
  }
  
  update_button={
    type:'update_organization',
    class:'btn btn-primary minWidth-120 mx-2',
    disable:false,
    text: adminLabels.updateButtonText
  }
  
  cancel_button={
    type:'cancel',
    class:'btn btn-secondary minWidth-120',
    disable:false,
    text: adminLabels.cancelButtonText
  }

  countImages:Subject<number> = new Subject<number>();
  private organizationData = null;
  private organizationDataTimeout: any = null;
  private signatureData = null;
  private signatureDataTimeout: any = null;


getOrganisations(limit,offset,search=''){
  let urlPath = '';

  if(this.userService.user.role == "partner_admin")
  {
    urlPath= environment.baseUrl+API_END_POINTS.GET_ORGANIZATION_LIST+"?limit="+limit+"&offset="+offset + "&partner_id=" + this.userService.user.partner_id;
  }
  else
  {
    urlPath= environment.baseUrl+API_END_POINTS.GET_ORGANIZATION_LIST+"?limit="+limit+"&offset="+offset;
  }

  if(search!=''){
    urlPath+='&search_text='+search;
  }
  return this.apiService.get(urlPath);
}

searchandgetOrganisations(limit,offset,search){

  let urlPath= '';

  if(this.userService.user.role == "partner_admin")
  {
    urlPath= environment.baseUrl+API_END_POINTS.GET_ORGANIZATION_LIST+"?limit="+limit+"&offset="+offset+"&search_text="+search + "&partner_id=" + this.userService.partner_id;
  }
  else
  {
    urlPath= environment.baseUrl+API_END_POINTS.GET_ORGANIZATION_LIST+"?limit="+limit+"&offset="+offset+"&search_text="+search;
  }
  
  return this.apiService.get(urlPath);
}

getSignature(organization_id, isMandatory=false){
  // let urlPath = environment.baseUrl+API_END_POINTS.GET_SIGNATURE+"?organization_id="+organization_id;
  //   return this.apiService.get(urlPath);
  
  if(isMandatory || this.signatureData === null || this.isCallBasedOnOrgId(organization_id) ) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_SIGNATURE + "?organization_id=" + organization_id;
    return this.apiService.get(urlPath).pipe(
      tap((response: any) => {
        this.signatureData = response;

        if (this.signatureDataTimeout) {
          clearTimeout(this.signatureDataTimeout);
        }

        this.signatureDataTimeout = setTimeout(() => {
          this.signatureData = null;
        }, 300000);
      })
    );
  } else {
    return of(this.signatureData);
  }
}

getOrganizationRole(organization_role_id,organization_id){
  let urlPath= environment.baseUrl+API_END_POINTS.GET_ORGANIZATION_ROLE+'?organization_role_id='+organization_role_id+"&organization_id="+organization_id;
  return this.apiService.get(urlPath);
}

getOrganization(org_id, isMandatory = false){
  // let urlPath=environment.baseUrl+API_END_POINTS.GET_ORGANIZATION+'?organization_id='+org_id;
  // return this.apiService.get(urlPath);

  if(isMandatory || this.organizationData === null || this.isCallBasedOnOrgId(org_id) ) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_ORGANIZATION + "?organization_id=" + org_id;
    return this.apiService.get(urlPath).pipe(
      tap((response: any) => {
        this.organizationData = response;
        console.log(response["data"]);
        if (this.organizationDataTimeout) {
          clearTimeout(this.organizationDataTimeout);
        }

        this.organizationDataTimeout = setTimeout(() => {
          this.organizationData = null;
        }, 900000);
      })
    );
  } else {
    return of(this.organizationData);
  }
}

updateOrganization(request){
  let urlPath=environment.baseUrl+API_END_POINTS.UPDATE_ORGANIZATION;
  return this.apiService.put(urlPath,request);
}

addOrganisation(request){
  let urlPath=environment.baseUrl+API_END_POINTS.ADD_ORGANIZATION;
  return this.apiService.post(urlPath,request);
}

deletOrganisation(id){
  let httpParams = new HttpParams().set('organization_id', id);
  let urlPath=environment.baseUrl+API_END_POINTS.DELETE_ORGANIZATION;
  return this.apiService.delete(urlPath,httpParams);
}

changeVerify(request){
let urlPath=environment.baseUrl+API_END_POINTS.ORGANIZATION_APPROVE;
return this.apiService.put(urlPath,request);
}

changeSuspend(request){
  let urlPath=environment.baseUrl+API_END_POINTS.ORGANIZATION_SUSPEND;
  return this.apiService.put(urlPath,request);
  }

updateThemeSetting(request) {
  let urlPath=environment.baseUrl+API_END_POINTS.UPDATE_THEME_SETTING;
  return this.apiService.put(urlPath,request);
}

getCDNData(){
  let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
    if (cdn_sign) {
      this.cdn_signature = cdn_sign["cdn_signature"];
      this.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];
    }
}

getSuspendedDeletedOrgs(limit,offset,search){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_SUSPENDED_ORGANIZATION+"?limit="+limit+"&offset="+offset+"&search_text="+search;
    if(this.userService.user.role == "partner_admin")
    {   
        urlPath = urlPath +  "&partner_id=" + this.userService.user.partner_id;  
    }
    return this.apiService.get(urlPath);

}

deleteBackup(organization_id){
    let httpParams = new HttpParams().set('organization_id', organization_id);
    let urlPath=environment.baseUrl+API_END_POINTS.DELETE_BACKUP;
    return this.apiService.delete(urlPath,httpParams);
}

getSelfHostedConfig(org_id){
    let urlPath = environment.baseUrl + API_END_POINTS.SELF_HOSTED_CONFIG + "?organization_id=" + org_id;
    return this.apiService.get(urlPath);
}

setSelfHostedConfig(request, org_id){
    let urlPath = environment.baseUrl + API_END_POINTS.SELF_HOSTED_CONFIG ;
    return this.apiService.put(urlPath,request);
}
    
getGlobalFeature(org_id?: string) {
    let urlPath = environment.baseUrl + API_END_POINTS.GET_GLOBAL_FEATURE;
    if (org_id) {
        urlPath += "?organization_id=" + org_id;
    }
    return this.apiService.get(urlPath);
}

postGlobalFeature(req) {
    let urlPath = environment.baseUrl + API_END_POINTS.POST_GLOBAL_FEATURE;
    
    return this.apiService.post(urlPath,req);
}

private isCallBasedOnOrgId(org_id) {
  let orgData = localStorage.getItem('organization');
  let orgId = null;
  if(orgData) {
    let jsonParsedData = JSON.parse(orgData);
    orgId =jsonParsedData.organization_id;
  }
  return orgId === null || orgId !== org_id;
}

}