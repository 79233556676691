<div>
    <div [ngClass]="className" class="main-drop-div">
        <div>
            <button class="drop-toggle btn flat" [ngClass]="className2" (click)="openCloseDropdown()"
                [disabled]="isDisabled">
                <span>{{getSelectedLabel()}}</span>
                <img *ngIf="!showDropDown" src="./../../assets/svgs/icon-down-arrow.svg" />
                <img *ngIf="showDropDown" src="./../../assets/svgs/icon-up-arrow.svg" />
            </button>
        </div>

        <div class="drop-show" [ngClass]="position=='up'?'drop-show-up':'drop-show-down'" *ngIf="showDropDown">
            <div *ngIf="dataList.length>0; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <ng-container *ngFor="let obj of dataList; index as i;">
                    <div class="drop-list-row" [ngClass]="selected == getValue(obj) ? 'active' : ''">
                        <div class="labelText" (click)="onSelect(getValue(obj))">{{getLabel(obj)}}</div>
                    </div>
                </ng-container>
            </ng-template>
            <ng-template #elseBlock>
                {{this.admin_data.noDataFound}}
            </ng-template>
        </div>
    </div>
</div>