import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subject, Subscription, forkJoin, from, interval } from 'rxjs';
import { UserService } from '../common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ManageKeywordsService } from '../common/services/manage-keywords.service';
import { CustomizationCentreService } from '../common/services/customization.service';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from '../common/services/error-response.service';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { SegmentationService } from '../common/services/segmentation.service';
import { ManageSegmentsService } from '../manage-segments.service';
import { OrganisationService } from '../common/services/organisation.service';
import { GuidesService } from '../common/services/guides.service';
import { ToastModalService } from "../common/services/toast-modal.service";
declare var $: any;
declare var AzureStorage: any;
declare var bootstrap: any;
@Component({
    selector: 'app-guides',
    templateUrl: './guides.component.html',
    styleUrls: ['./guides.component.scss']
})
export class GuidesComponent implements OnInit {

    breadcrumb;
    admin_data = adminLabels;
    loader = false;
    curr_org_id: any;
    limit = 1000;
    offset = 0;
    tours = [];
    apps = [];
    cdn_signature = "";
    curr_app_id = "";
    curr_status: any = '';
    curr_folder = '';
    curr_user = '';
    folders: any[];
    curr_tour_ids = new Set([]);
    destroy: Subject<boolean> = new Subject<boolean>();
    search_text: string = "";
    tableHeading = [this.admin_data.apiConnectorFolder, this.admin_data.tableHeaderActions];
    subFolders: any[];
    tableData: any[];
    navArray: String[] = ['All Folders'];
    curr_view = "folder_view"
    selectCategories = [this.admin_data.all, this.admin_data.published, this.admin_data.unpublished,this.admin_data.clear]
    customSelectObj = [
        {guide_state: this.admin_data.all , guide_value: "all"},
        {guide_state: this.admin_data.published , guide_value: "published"},
        {guide_state: this.admin_data.unpublished , guide_value: "unpublished"},
        {guide_state: this.admin_data.clear , guide_value: "clear"}
    ]
    copiedGuides: any;
    isDropdownOpen: boolean = false;
    selectedOption: string = 'all';
    curr_tab = "transfer_guides";
    importTableHeading = [this.admin_data.guideName, this.admin_data.tableHeaderProgress, this.admin_data.usersVariable2, this.admin_data.tableHeaderCreationDate]
    importedTasks = [];
    exportTableHeading = [this.admin_data.guideName, this.admin_data.tableHeaderProgress, this.admin_data.usersVariable2, this.admin_data.tableHeaderCreationDate, this.admin_data.tableHeaderActions]
    exportedTasks = [];
    globalChecked = false;
    enableExportCopy = false;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('roundbutton', { static: false}) roundbutton: ElementRef;
    constructor(
        public userService: UserService,
        private router: Router,
        private manageKeywordService: ManageKeywordsService,
        private route: ActivatedRoute,
        private customizationService: CustomizationCentreService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private organisationService: OrganisationService,
        private guidesService: GuidesService,
        private toastService: ToastModalService, 
        private renderer: Renderer2) {
        this.userService.getUserData();
        this.titleService.setTitle(
            this.admin_data.guides + " | Cornerstone Guide"
        );
        this.userService.activeTab = 'guides';
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.guides,
                        route: null,
                    },
                ]);
                this.breadcrumb = this.userService.breadCrumbs;
            });
        this.route.paramMap.subscribe((params) => {
            if (params['params']['org_id']){
            if(params['params']['org_id'] !== undefined && params['params']['org_id'] !== '000') {
                this.curr_org_id = params['params']['org_id'];
                this.userService.organization_id = this.curr_org_id;
                this.organisationService.getSignature(this.userService.organization_id).subscribe((response) => {
                    this.cdn_signature = response["data"]["cdn_signature"];
                });
            }
        }
        });

        
    }

    ngAfterViewInit(){
    
        this.renderer.listen('window', 'click',(e:Event)=>{        
            if(this.roundbutton && this.roundbutton.nativeElement && !this.roundbutton.nativeElement.contains(e.target)){
                this.isDropdownOpen = false;
            }
        });
    }
    ngOnInit() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
        this.getApps()
    }

    getApps(app_id?) {
        this.customizationService.getApplications(1000, 0, this.curr_org_id).subscribe((response => {
            this.apps = []
            response['data']['applications'].forEach(element => {
                let app = {
                    application_id: element['application_id'],
                    name: element['title']
                }
                this.apps.push(app);
            });
            if (this.curr_app_id == "")
                this.curr_app_id = this.apps[0]['application_id'];
            this.getFolders(this.curr_app_id)
        }))
    }

    getFolders(app_id) {
        this.guidesService.getCategoryList(this.limit, this.offset, app_id, 0).subscribe((response) => {
            this.folders = []

            this.folders = response['data']['categories']
            this.navArray = ['All Folders'];
            this.curr_view = 'folder_view';
            this.tableData = this.folders;            
            if(this.toastService.showToast){
                this.toastService.showToastMessage(this.toastService.toastType);
              }
        })
    }

    getSubFolders(app_id, parent_id, currParentFolders) {
        this.guidesService.getCategoryList(this.limit, this.offset, app_id, parent_id).subscribe((response) => {
            this.tableData = currParentFolders.find((element) => element['category_id'] == parent_id)['subFolder'] = response['data']['categories']
            //  currParentFolders.find((element) => element['category_id'] == parent_id)['subFolder'];
        })
    }

    getGuides(app_id, category_id?) {
        this.destroy.next(true);
        this.tours = []
        this.destroy.next(false);
        this.manageKeywordService.getAllGuides(this.limit, this.offset, this.curr_org_id, app_id, this.curr_user, this.curr_folder, this.curr_status, "", category_id)
            .pipe(takeUntil(this.destroy),debounceTime(300))
            .subscribe((response) => {
                this.curr_tour_ids.clear()
                this.tours = response['data']['tours'];
                // ////console.log(this.tours);
                this.tours.forEach((tour) => {
                    tour['checked'] = false;
                    tour['disabled'] = false;
                })
                this.loader = true
                //console.log(this.tours);

            })
        this.selectedOption = "all";
        this.enableExportCopy = false;
    }

    appChanged(app_id) {
        this.curr_app_id = app_id;
        this.getFolders(this.curr_app_id)
    }

    close() {
        if (document.getElementById("filter-dropdown-menu") && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
            document.getElementById("filter-dropdown-menu").classList.remove("show");
            document.getElementById("filter").classList.remove("show");
        }
    }

    applyFilter(app_id, status, folder_id, user_id) {

        this.curr_app_id = app_id;
        this.curr_status = status;
        this.curr_folder = folder_id;
        this.curr_user = user_id;
        // this.getAllGuides(app_id)
        this.close()
    }

    populateSubFolder(folder, currParentFolders) {
        if (currParentFolders.find(element => element['category_id'] === folder['category_id'])['subFolder'] == undefined) {
            this.getSubFolders(this.curr_app_id, folder['category_id'], currParentFolders)
        } else {
            this.tableData = currParentFolders.find((element) => element['category_id'] == folder['category_id'])['subFolder'];
        }

        this.navArray.push(folder['category_title'])
        this.curr_folder = folder['category_id']
    }

    populateGuideView(category_id) {        
        this.curr_folder = category_id
        this.curr_view = "guides_view"
        this.enableExportCopy = false;
        this.selectedOption = "all"
        this.globalChecked = false;
        //console.log(this.navArray);
        this.navArray.push(this.tableData.find(element => element['category_id'] === category_id)['category_title'])
        // tour/list?application_id=320&category_id=3782&limit=50&offset=0&organization_id=213
        this.getGuides(this.curr_app_id, category_id)
    }

    goBack() {
        //console.log(this.navArray);
        if (this.curr_view === "guides_view") {
            this.globalChecked = false;
        }
        
        if (this.navArray.length == 1){
            return;
        }else if(this.navArray.length == 2){
            this.tableData = this.folders;
            this.curr_view = 'folder_view'
            this.curr_folder = '';
        }else if (this.navArray.length > 2) {
            this.curr_folder = this.tableData[0]['parent_id']
            this.recurse(this.folders, this.curr_folder);
            this.selectedOption = "all"
            this.enableExportCopy = false
        }
        this.navArray.pop()
    }

    recurse(subFolder : any[], parentId){        
        subFolder.forEach(element => {            
            if(element['category_id'] == parentId){
                if(this.curr_view == 'guides_view'){
                    this.tableData = element['subFolder'];
                    this.curr_view = 'folder_view';
                }else{
                    this.tableData = subFolder;
                }
                return;
            }else if(element['has_child'] !== '0' && element['has_tour'] === '0' && element['subFolder'] !== undefined){
                this.recurse(element['subFolder'], parentId);
            }            
        });
    }

    getCheckedStatus(index) {
        return this.tours[index] ? this.tours[index]['checked'] : false;
    }

    onCheckboxClick(checked, index) {
        //console.log(checked, index, "checked click");
        this.tours[index]['checked'] = checked;
        let uncheckedCount = 0
        this.globalChecked = false;
        if(!checked) {
            this.checkToEnableExportCopy()
        }else{
            this.enableExportCopy = true;
        }

    }

    checkToEnableExportCopy(){
        let uncheckedCount = 0;
        for(let tour of this.tours){
            if(tour['checked']){
                this.enableExportCopy = true;
                break;
            }else{
                uncheckedCount++
            }
        }
        if(uncheckedCount === this.tours.length){this.enableExportCopy = false}
    }

    selectCategoryChanged(event: any) {
        this.globalChecked = true;
        if(event.target.value == "clear"){
            this.selectedOption = "all"
            this.globalChecked = false;
            this.tours.forEach((tour) => {
                tour['checked'] = false
            })
            let sel = (document.getElementById('dropdownFilter') as HTMLInputElement)
            sel.value = 'all';
            this.enableExportCopy = false;
        }else{
            this.selectedOption = event.target.value;
        }
        if(this.globalChecked){
            this.onGlobalChecked(this.globalChecked)
        }
    }

    exportGuides(category_id?, category_title?) {
        let req;
        if (!category_id || !category_id) {
            let guides = []
            let description = ""
            this.tours.forEach((element) => {
                if (element['checked'] === true && element['disabled'] === false) {
                    guides.push(element['tour_id'])
                }
            })
            if (this.tours.length === guides.length) {
                description = "Exporting : " + this.navArray[this.navArray.length - 1]
                guides = []
            } else {
                description = "Exporting: " + this.navArray[this.navArray.length - 1] + " ( " + guides.length + " Guides )";
            }
            req = {
                data_to_export: [{
                    category: this.curr_folder,
                    tours: guides
                }],
                description: description,
                organization_id: this.curr_org_id
            }
        }else{
            let guides = []
            let description = "Exporting " + category_title;
            req = {
                data_to_export: [{
                    category: category_id,
                    tours: guides
                }],
                description: description,
                organization_id: this.curr_org_id
            }
        }

        this.guidesService.exportGuides(req).subscribe((response) => {
            if(response['error'] == false){
                this.toastService.showToast=true;
                this.toastService.toastType="export_guides";
                if(this.toastService.showToast){
                    this.toastService.showToastMessage(this.toastService.toastType);
                }
                
            }else{
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                //console.log(errorResponse);                
                $("#errorModal").modal("show");                
            }
        })

    }

    copyGuides() {
        let guides = []
        this.tours.forEach((element) => {
            if (element['checked'] === true && element['disabled'] === false) {
                guides.push(parseInt(element['tour_id']))
            }
        })
        this.copiedGuides = {
            application_id: this.curr_app_id,
            from_category_ids: JSON.stringify([parseInt(this.curr_folder)]),
            to_category_id: "",
            tour_ids: JSON.stringify(guides)
        }

        this.toastService.showToast=true;
        this.toastService.toastType="copied_guides";
        if(this.toastService.showToast){
            this.toastService.showToastMessage(this.toastService.toastType);
          }

    }
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    pasteGuide() {
        this.copiedGuides['to_category_id'] = this.curr_folder;

        this.guidesService.pasteGuides(this.copiedGuides).subscribe((response) => {
            if(response['error'] == false){
                this.toastService.showToast=true;
                this.toastService.toastType="pasted_guides";
                if(this.toastService.showToast){
                this.toastService.showToastMessage(this.toastService.toastType);
            }
            this.copiedGuides = undefined
            this.isDropdownOpen = false;
            }else{
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                //console.log(errorResponse);                
                $("#errorModal").modal("show");
            }
        })
    }

    openFileInput() {
        this.fileInput.nativeElement.click();
    }

    handleFileChange(event: any) {
        const file = event.target.files[0]
        // const file = event.target.files[0];
        if (file) {
            this.guidesService.getUploadedFile(this.curr_org_id).subscribe((response) => {
                let uploadData = response["data"];
                let self = this;

                const blobService = AzureStorage.Blob.createBlobServiceWithSas(
                    uploadData["upload_url"]["blob_uri"],
                    uploadData["upload_url"]["signature"]
                );
                let customBlockSize = 0;

                if (file) {
                    customBlockSize =
                        file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
                    blobService.singleBlobPutThresholdInBytes = customBlockSize;
                }
                blobService.createBlockBlobFromBrowserFile(
                    "json",
                    uploadData["upload_url"]["file_name"].split("/")[1],
                    file,
                    {
                        blockSize: customBlockSize,
                    },
                    function (error, result, response) {
                        if (error) {
                            alert("Your JSON is not uploaded, Please try again");
                        } else {
                            let request = {
                                file_id: uploadData["upload_url"]["file_id"],
                                application_id: self.curr_app_id,
                                description: "Importing " + file.name
                            };
                            if(self.curr_folder !== ''){
                                request['category_id'] = self.curr_folder
                            }
                            self.guidesService.importGuides(request).subscribe((response) => {
                                if(response['error'] == false){
                                    self.toastService.showToast=true;
                                    self.toastService.toastType="import_guides";
                                    self.toastService.showToastMessage(self.toastService.toastType);
                                }else{
                                    let errorResponse = self.errorResponseService.getErrorResponseMessage(response['message'][0]);
                                    self.userService.errorMessage = errorResponse;
                                    //console.log(errorResponse);                
                                    $("#errorModal").modal("show");                
                                }
                                
                            });
                        }
                    }
                );
            })
        }
        this.isDropdownOpen = false;
    }

    changeTab(tabName) {
        this.curr_tab = tabName
        if (tabName == "import_guides") {
            this.getImportedGuides()
        } else if (tabName === "export_guides") {
            this.getExportedGuides();
        }
    }

    getImportedGuides() {
        this.importedTasks = []
        this.guidesService.getImportedGuides(this.curr_org_id).subscribe((response) => {
            //console.log(response);
            this.importedTasks = response['data']['tasks']
        })
    }

    getExportedGuides() {
        this.exportedTasks = []
        this.guidesService.getExportedGuides(this.curr_org_id).subscribe((response) => {
            //console.log(response);
            this.exportedTasks = response['data']['tasks']

        })
    }

    downloadFile(url, filename): void {
        // console.log(url, filename);
        this.guidesService.downloadfile(url).subscribe((blob) => {
            let task_filename = filename.split('/')
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = task_filename[task_filename.length - 1];
            a.click();
            URL.revokeObjectURL(objectUrl);
        })
    }

    onGlobalChecked(checked) {
        this.globalChecked = checked;
        if (this.selectedOption == "all") {
            this.tours.forEach((tour) => {
                tour['checked'] = this.globalChecked;
            })
            this.checkToEnableExportCopy();
        } else if (this.selectedOption === "published") {
            if (checked) {
                this.tours.forEach((tour) => {
                    if (tour['is_published'] === "1") {
                        tour['checked'] = this.globalChecked;
                    } else {
                        tour['checked'] = !this.globalChecked;
                    }
                })
            }else{
                this.tours.forEach((tour) => {
                    tour['checked'] = false;
                })
            }
            this.checkToEnableExportCopy();
        } else if (this.selectedOption === "unpublished") {
            if (checked) {
                this.tours.forEach((tour) => {
                    if (tour['is_published'] === "0") {
                        tour['checked'] = this.globalChecked;
                    } else {
                        tour['checked'] = !this.globalChecked;
                    }
                })
            }else{
                this.tours.forEach((tour) => {
                    tour['checked'] = false;
                })
            }
            this.checkToEnableExportCopy();
        }

    }
    search()  {
        if(this.search_text == '') {
            this.tableData = this.folders; 
            return; }       
        const lowercaseSearch = this.search_text.toLowerCase();
        this.tableData = this.folders.filter((element) =>  element['category_title'].toLowerCase().includes(lowercaseSearch)
        );
        
      }

    refresh() {
        if (this.curr_tab == "import_guides") {
            this.getImportedGuides()
        } else if (this.curr_tab === "export_guides") {
            this.getExportedGuides();
        }
    }
}
