import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../common/services/user.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { Title } from '@angular/platform-browser';
import { CustomizationCentre, CustomizationCentreService } from "../common/services/customization.service";
import { ModalLibraryComponent } from "./modal-library/modal-library.component";
import { ModalStepThemeComponent } from "./modal-step-theme/modal-step-theme.component";
import { ModalRuleComponent } from "./modal-rule/modal-rule.component";
import organizationDefault from "./organizationDefault";
import { ToastModalService } from "../common/services/toast-modal.service";
import { environment } from '../../environments/environment';
import { ModalTutorialComponent } from "./modal-tutorial/modal-tutorial.component";
declare var bootstrap: any;

@Component({
  selector: "app-customzation-centre",
  templateUrl: "./customzation-centre.component.html",
  styleUrls: ["./customzation-centre.component.scss"],
})
export class CustomzationCentreComponent implements OnInit {

  @ViewChild('modallibrary', { static: false }) modal: ModalLibraryComponent
  @ViewChild('modalsteptheme', { static: false }) modalStepTheme: ModalStepThemeComponent
  @ViewChild('modalRules', { static: false }) modalRule: ModalRuleComponent
  @ViewChild('modalTutorial', { static: false }) modalTutorial: ModalTutorialComponent


  breadcrumb;
  admin_data = adminLabels;
  search = "";
  customizations: CustomizationCentre[] = [];
  loader = false;
  appList = [];
  delayTimeArr = ["12 hours", "24 hours", "1 week", "2 weeks", "4 weeks"];
  automationDelaySteps = [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  showAllGuides = "";
  organizationSettings = {};
  globalAppObj = new Map();
  defaultChatGreeting = this.admin_data.defaultChatbotGreeting;
  defaultChatEnd = this.admin_data.defaultChatbotEnd;
  defaultChatError = this.admin_data.defaultChatbotError;
  cdn_signature;
  curr_org_id;
  defaultOrgVal = organizationDefault;
  isAppAdmin = false;
  assetUrl = "/guideme-assests/";
  isMirrorApplication = "0";
  userData = {};
  preRenderApp = [];
  firstTimeNote: Boolean = true;
  activeSubmenu = 'branding';

  constructor(
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    public customization: CustomizationCentreService,
    private toastService: ToastModalService,
  ) {
    this.userService.getUserData();
    this.titleService.setTitle(
      this.admin_data.customizationCentreTitle + "Cornerstone Guide"
    );
    this.isAppAdmin = this.userService.user.role === "app_admin";
    this.userService.activeTab = "customizationCentre";
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.userService.setBreadcrumb([
          {
            name: this.admin_data.customizationCentre,
            route: null,
          },
        ]);
        this.breadcrumb = this.userService.breadCrumbs;
      });

  }


  ngOnInit() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
    this.userService.getUser(this.userService.user.user_id).subscribe(res => {
      this.loader = false;
      this.userData = res["data"]["user"];
      if (this.userData["settings"] != undefined) {
        let settingObj = JSON.parse(this.userData["settings"]);
        if (settingObj["app_view"] != undefined && settingObj["app_view"][this.userService.organization_id] != undefined) {
          this.preRenderApp = settingObj["app_view"][this.userService.organization_id];
          this.setTableHeaders();
        } else {
          this.setTableHeaders();
        }
      } else {
        this.setTableHeaders();
      }
    });

    this.customization.selectedSubmenu$.subscribe(submenu => this.activeSubmenu = submenu);
  }


  ngAfterViewChecked() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }


  setTableHeaders() {
    this.loader = false;
    this.route.paramMap.subscribe((params) => {
      if (params['params']['org_id']) {
        this.curr_org_id = params['params']['org_id'];
        this.customization.getSignature(this.curr_org_id).subscribe((response) => {
          this.cdn_signature = response["data"]["cdn_signature"];
          this.customization.cdn_signature = response["data"]["cdn_signature"];
        });
        this.userService.organization_id = this.curr_org_id;
        this.customization.getApplications(1000, 0, params['params']['org_id']).subscribe((response) => {
          let data = [];
          this.appList = [];
          this.globalAppObj.clear();
          this.customization.apps = [];
          data = response["data"]["applications"];
          data.forEach((row) => {
            let app_settings: CustomizationCentre = {};
            app_settings.appId = row["application_id"];
            app_settings.appTitle = row["title"];
            app_settings.image_id = row["image_id"];
            app_settings.description = row["description"];
            app_settings.isDefault = row["is_default"];
            app_settings.settings = JSON.parse(row["settings"]);
            if (this.getPropertyValue(app_settings.settings, "sandbox") != true) {
              this.globalAppObj.set(row["application_id"], app_settings);
              let tempObj = this.getAppObj(row["application_id"], row["title"]);
              if (this.preRenderApp.length == 0) {
                this.preRenderApp.push(app_settings.appId)
              }
              this.appList.push(tempObj);
            }
          });
          this.setSettings(params['params']['org_id']);
        })
      }
    })
  }


  setSettings(org_id) {
    let cb = this.customization.getSettings(org_id);
    cb.subscribe(
      (data) => {
        this.customization.org.appId = "0";
        this.customization.org.appTitle = this.admin_data.label10
        this.customization.org.admin_settings = JSON.parse(data["data"]["organization"]["admin_settings"]);
        this.customization.org.settings = JSON.parse(data["data"]["organization"]["settings"]);
        this.customization.org.description = data["data"]["organization"]["about"];
        this.customization.org.orgName = data["data"]["organization"]["name"];
        this.customization.org.image_id = data["data"]["organization"]["image_id"];
        this.isMirrorApplication = data["data"]["organization"]["has_mirror_apps"];
        this.showAllGuides = this.customization.org.admin_settings["all_guide"];
        if (this.userService.organization != undefined && this.userService.organization != null) {
          this.userService.organization.name = data["data"]["organization"]["name"];
          this.userService.organization.settings = data["data"]["organization"]["settings"];
        }
        let isApp0AddReq = false;
        this.updateOrgObjWithDef(organizationDefault, this.customization.org, isApp0AddReq).then(res => {
          let tempObj = JSON.parse(JSON.stringify(res));
          this.customization.apps.splice(0, 0, tempObj);
          this.setRendering();
          this.loader = true;
        }, (err) => {
          this.loader = true;
        });

      },
      (error) => { }
    );
  }

  updateOrgObjWithDef = async (defaultObj, orgObj, isApp0AddReq) => {
    const processKeys = async (defaultObj, orgObj, isApp0AddReq) => {
      for (const key of Object.keys(defaultObj)) {
        if (typeof defaultObj[key] === 'object' && defaultObj[key] !== null) {
          if (!orgObj.hasOwnProperty(key)) {
            orgObj[key] = JSON.parse(JSON.stringify(defaultObj[key]));
            isApp0AddReq = true;
          } else {
            await processKeys(defaultObj[key], orgObj[key], isApp0AddReq);
          }
        } else {
          if (!orgObj.hasOwnProperty(key) || (typeof orgObj[key] === "string" && orgObj[key] === "")) {
            orgObj[key] = defaultObj[key];
            isApp0AddReq = true;
          }
        }
      }
    };

    await processKeys(defaultObj, orgObj, isApp0AddReq);
    return orgObj;
  };


  addAppToChanged(appId, app: CustomizationCentre) {
    if (this.customization.changedApps.length >= 1) {
      const pos = this.customization.changedApps.findIndex((app) => {
        return app.appId === appId
      })
      if (pos > -1) {
        this.customization.changedApps.splice(pos, 1)
        this.customization.changedApps.push(app)

      } else {
        this.customization.changedApps.push(app)
      }
    }
    else {
      this.customization.changedApps.push(app)
    }
  }

  uploadImage(appID, event, imageKey, idKey) {
    var allowedImageExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
    const file: File = event.target.files[0];
    if (file && allowedImageExtensions.includes(file.type)) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("organization_id", this.curr_org_id);
      this.customization.getImageId(formData).subscribe((response) => {
        const pos = this.customization.apps.map(app => app.appId).indexOf(appID);
        this.customization.apps[pos].settings[imageKey] = response["data"]["image_path"];
        if (idKey) {
          if (idKey.split(".").length == 1)
            this.customization.apps[pos].settings[idKey] = response["data"]["image_id"];
          else
            this.setNestedVal(pos, idKey, response["data"]["image_id"]);
        }
        this.addAppToChanged(appID, this.customization.apps[pos]);
      });
    } else {
      event.target.value = "";
      this.toastService.showToastMessage("invalid_image_type")
    }
  }

  setNestedVal(pos, key, val) {
    let keys = key.split(".");
    if (!this.hasOwnProperty(this.customization.apps[pos], "settings." + [keys[0]])) {
      this.customization.apps[pos].settings[keys[0]] = {};
    }
    this.customization.apps[pos].settings[keys[0]][keys[1]] = val
  }

  uploadBrandLogo(appID, event) {
    this.uploadImage(appID, event, "logo", null);
  }

  uploadBrandWatermark(appID, event) {
    this.uploadImage(appID, event, "watermark_video", null);
  }

  uploadWidgetIcon(appID, event) {
    this.uploadImage(appID, event, "widget_icon_path", "widget_icon_pos.widget_icon_id");
  }

  uploadChatIcon(appID, event) {
    this.uploadImage(appID, event, "chat_icon_path", "chat_icon_pos.chat_icon_id");
  }

  uploadFirstSlideImage(appID, event) {
    this.uploadImage(appID, event, "first_slide", "first_slide_id");
  }

  uploadLastSlideImage(appID, event) {
    this.uploadImage(appID, event, "last_slide", "last_slide_id");
  }

  getPropValue = (o: object, prop: string) => {
    if (this.hasOwnProperty(o, prop)) {
      let propValue = this.getPropertyValue(o, prop);
      if (JSON.stringify(propValue) != "") {
        return propValue;
      }
    }
    return this.getPropertyValue(this.customization.apps[0], prop);
  }

  isOrgValue = (o: object, prop: string) => {
    let first = this.getPropValue(o, prop);
    let second = this.getPropertyValue(this.customization.org, prop);
    return first == second;
  }

  isDefValue = (i: number, prop: string) => {
    let first = this.getPropValue(this.customization.apps[i], prop);
    let second = this.getPropertyValue(this.defaultOrgVal, prop);
    if (typeof first == "object") {
      return this.deepCompareObjects(first, second);
    } else {
      return first == second;
    }
  }

  deepCompareObjects = (obj1, obj2) => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const value1 = obj1[key];
        const value2 = obj2[key];
        if (typeof value1 === 'object' && typeof value2 === 'object') {
          if (!this.deepCompareObjects(value1, value2)) {
            return false;
          }
        } else {
          if (value1 !== value2) {
            return false;
          }
        }
      }
    }
    return true;
  }

  resetDefault = (prop: string) => {
    let val = this.getPropertyValue(this.defaultOrgVal.settings, prop);
    this.customization.apps[0].settings[prop] = val;
    this.addAppToChanged(this.customization.apps[0].appId, this.customization.apps[0]);
  }

  resetDefaultNotif = (prop: string) => {
    let val = this.getPropertyValue(this.defaultOrgVal.settings.notifications, prop);
    this.customization.apps[0].settings.notifications[prop] = val;
    this.addAppToChanged(this.customization.apps[0].appId, this.customization.apps[0]);
  }

  resetDefaultSelector = (prop: string) => {
    let val = this.getPropertyValue(this.defaultOrgVal.settings.selectorStyle, prop);
    this.customization.apps[0].settings.selectorStyle[prop] = val;
    this.addAppToChanged(this.customization.apps[0].appId, this.customization.apps[0]);
  }

  resetDefaultUserLabel = (prop: string) => {
    if (prop == "helpUrl") {
      this.customization.apps[0].settings[prop] = this.defaultOrgVal.settings.helpUrl;
    } else {
      let val = this.getPropertyValue(this.defaultOrgVal.settings.userLabels, prop);
      this.customization.apps[0].settings.userLabels[prop] = val;
    }
    this.addAppToChanged(this.customization.apps[0].appId, this.customization.apps[0]);
  }

  showResetButton = (i: number, prop) => {
    if (i == 0) {
      if (this.isDefValue(i, prop)) {
        return false;
      }
    }
    else {
      let app = this.customization.apps[i];
      if (this.isDefValue(i, prop) || this.isOrgValue(app, prop)) {
        return false;
      }
    }
    return true;
  }

  showResetButtonForFeedback = (i: number) => {
    let app = this.customization.apps[i];
    if (i == 0) {
      if (this.getPropValue(app, 'settings.feedback_type') == "email") {
        if (this.isDefValue(i, 'settings.feedback_email')) {
          return false;
        }
      }
      if (this.getPropValue(app, 'settings.feedback_type') == "url") {
        if (this.isDefValue(i, 'settings.feedback_url')) {
          return false;
        }
      }
    } else {
      if (this.getPropValue(app, 'settings.feedback_type') == "email") {
        if (this.isDefValue(i, 'settings.feedback_email') || this.isOrgValue(app, 'settings.feedback_email')) {
          return false;
        }
      }
      if (this.getPropValue(app, 'settings.feedback_type') == "url") {
        if (this.isDefValue(i, 'settings.feedback_url') || this.isOrgValue(app, 'settings.feedback_url')) {
          return false;
        }
      }
    }
    return true;
  }

  resetFeedback = (i: number) => {
    let app;
    if (i == 0) app = this.defaultOrgVal;
    else app = this.customization.apps[0]
    if (this.customization.apps[i].settings["feedback_type"] == "email") {
      this.customization.apps[i].settings["feedback_email"] = app.settings.feedback_email;
    } else if (this.customization.apps[i].settings["feedback_type"] == "url") {
      this.customization.apps[i].settings["feedback_url"] = app.settings.feedback_url;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeDefaultPagePlayer = (i: number, event: Event) => {
    this.customization.apps[i].settings.defaultGuideView = (event.target as HTMLInputElement).value == "true";
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeSwitchStatus = (i: number, prop: string) => {
    if (this.hasOwnProperty(this.customization.apps[i], ("settings." + prop))) {
      this.customization.apps[i].settings[prop] = !this.customization.apps[i].settings[prop];
    } else {
      this.customization.apps[i].settings[prop] = !this.customization.apps[0].settings[prop];
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeUserLabel = (i: number, prop: string, event: Event) => {
    if (this.hasOwnProperty(this.customization.apps[i], ("settings.userLabels." + prop))) {
      this.customization.apps[i].settings.userLabels[prop] = (event.target as HTMLInputElement).value;
    } else {
      let tempObj = {
        ...this.customization.apps[i].settings.userLabels
      }
      tempObj[prop] = (event.target as HTMLInputElement).value;
      this.customization.apps[i].settings.userLabels = tempObj;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeColor = (i: number, prop: string, event: Event) => {
    let color = (event.target as HTMLInputElement).value;
    if (prop === "notifThemeColor") {
      if (!this.hasOwnProperty(this.customization.apps[i].settings, "notifications")) {
        this.customization.apps[i].settings.notifications = {};
      }
      this.customization.apps[i].settings.notifications[prop] = color;
    } else if (prop === "borderColor") {
      if (!this.hasOwnProperty(this.customization.apps[i].settings, "selectorStyle")) {
        this.customization.apps[i].settings.selectorStyle = {};
      }
      this.customization.apps[i].settings.selectorStyle[prop] = color;
    } else {
      this.customization.apps[i].settings[prop] = color;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeFeedback = (i: number, prop: string, event: Event) => {
    this.changeSelect(i, prop, event);
  }

  changeSelect = (i: number, prop: string, event: Event) => {
    if (prop == "notificationDelay" || prop == "borderWidth") {
      this.enforceMinMax(event);
    }
    if (prop == "widget_icon_zindex") {
      let el = (event.target) as HTMLInputElement;
      if (el.value !== 'Default' && isNaN(Number(el.value))) {
        let s: string = el.value;
        let replaceString = [];
        for (let i = 0; i < el.value.length; i++) {
          if (i == 0 && (s[i] === '-' || !isNaN(Number(s[i])))) {
            replaceString.push(s[i]);
          } else if (!isNaN(Number(s[i]))) {
            replaceString.push(s[i]);
          }
        }
        el.value = replaceString.join('');
      }
      if (Number(el.value) > 2147483648) el.value = 2147483646 + '';
      if (Number(el.value) < -2147483648) el.value = -2147483646 + '';
    }
    let el = (event.target as HTMLInputElement);
    if (prop === "borderWidth") {
      if (!this.hasOwnProperty(this.customization.apps[i].settings, "selectorStyle")) {
        this.customization.apps[i].settings.selectorStyle = {};
      }
      this.customization.apps[i].settings.selectorStyle[prop] = el.value;
    } else {
      this.customization.apps[i].settings[prop] = el.value;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeChatLabel = (i: number, prop: string, event: Event) => {
    if (this.hasOwnProperty(this.customization.apps[i], ("settings.chatLabels." + prop))) {
      this.customization.apps[i].settings.chatLabels[prop] = (event.target as HTMLInputElement).value;
    } else {
      let tempObj = {
        ...this.customization.apps[i].settings.chatLabels
      }
      tempObj[prop] = (event.target as HTMLInputElement).value;
      this.customization.apps[i].settings.chatLabels = tempObj;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeChatWidgetPostion = (i: number, event: Event, type: string) => {
    let prop = (event.target as HTMLInputElement).value;
    let isChat = type === "chat" ? true : false;

    let posArr = prop.split("-");
    if (isChat) {
      this.customization.apps[i].settings.chatIconPos = prop;
      this.customization.apps[i].settings.showChatIconTop = false;
      this.customization.apps[i].settings.showChatIconBottom = false;
      this.customization.apps[i].settings.showChatIconLeft = false;
      this.customization.apps[i].settings.showChatIconRight = false;
      if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos')) {
        this.customization.apps[i].settings.chat_icon_pos = {};
      }
      if (posArr.includes('top')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_top_pos'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_top_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_top_pos_unit'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_top_pos_unit = "px";
        this.customization.apps[i].settings.showChatIconTop = true;
      }
      if (posArr.includes('bottom')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_bottom_pos'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_bottom_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_bottom_pos_unit'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_bottom_pos_unit = "px";
        this.customization.apps[i].settings.showChatIconBottom = true;
      }
      if (posArr.includes('left')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_left_pos'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_left_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_left_pos_unit'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_left_pos_unit = "px";
        this.customization.apps[i].settings.showChatIconLeft = true;
      }
      if (posArr.includes('right')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_right_pos'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_right_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.chat_icon_pos.chat_icon_right_pos_unit'))
          this.customization.apps[i].settings.chat_icon_pos.chat_icon_right_pos_unit = "px";
        this.customization.apps[i].settings.showChatIconRight = true;
      }
    }
    else {
      this.customization.apps[i].settings.widgetIconPos = prop;
      this.customization.apps[i].settings.showWidgetIconTop = false;
      this.customization.apps[i].settings.showWidgetIconBottom = false;
      this.customization.apps[i].settings.showWidgetIconLeft = false;
      this.customization.apps[i].settings.showWidgetIconRight = false;

      if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos')) {
        this.customization.apps[i].settings.widget_icon_pos = { ...this.customization.apps[i].settings.widget_icon_pos };
      }
      if (posArr.includes('top')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_top_pos'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_top_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_top_pos_unit'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_top_pos_unit = "px";
        this.customization.apps[i].settings.showWidgetIconTop = true;
      }
      if (posArr.includes('bottom')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_bottom_pos'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_bottom_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_bottom_pos_unit'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_bottom_pos_unit = "px";
        this.customization.apps[i].settings.showWidgetIconBottom = true;
      }
      if (posArr.includes('left')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_left_pos'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_left_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_left_pos_unit'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_left_pos_unit = "px";
        this.customization.apps[i].settings.showWidgetIconLeft = true;
      }
      if (posArr.includes('right')) {
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_right_pos'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_right_pos = 100;
        if (!this.hasOwnProperty(this.customization.apps[i], 'settings.widget_icon_pos.widget_icon_right_pos_unit'))
          this.customization.apps[i].settings.widget_icon_pos.widget_icon_right_pos_unit = "px";
        this.customization.apps[i].settings.showWidgetIconRight = true;
      }
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  showHidePosition = (app, prop, isChat=false) => {
    let val;
    if(isChat) {
      val = this.getPropValue(app, "settings.chatIconPos");
    } else {
      val = this.getPropValue(app, "settings.widgetIconPos");
    }
    let propArr = val.toLowerCase().split("-");
    return propArr.includes(prop.toLowerCase());
  }

  changeChatWidgPosition = (i: number, prop: string, selectedVal: string, type: string) => {
    let isChat = type === "chat" ? true : false;
    if (isChat) {
      this.chatIconPosHelper(i, prop, selectedVal);
      let updateProp = prop.replace("_unit", "");
      this.customization.apps[i].settings.chat_icon_pos[updateProp] = 0;
    } else {
      this.widgetIconPosHelper(i, prop, selectedVal);
      let updateProp = prop.replace("_unit", "");
      this.customization.apps[i].settings.widget_icon_pos[updateProp] = 0;
    }
  }

  changeChatWidgPosValue = (i: number, prop: string, event: Event, type: string) => {
    let tempVal = (event.target as HTMLInputElement);
    if (isNaN(parseInt(tempVal.value))) {
      tempVal.value = "0";
    }
    this.enforceMinMax(event);
    if (type == "chat")
      this.chatIconPosHelper(i, prop, (event.target as HTMLInputElement).value);
    else
      this.widgetIconPosHelper(i, prop, (event.target as HTMLInputElement).value);
  }

  chatIconPosHelper = (i: number, prop: string, el: any) => {
    if (this.hasOwnProperty(this.customization.apps[i], ("settings.chat_icon_pos." + prop))) {
      this.customization.apps[i].settings.chat_icon_pos[prop] = el;
    } else {
      let tempObj = {
        ...this.customization.apps[i].settings.chat_icon_pos
      }
      tempObj[prop] = el;
      this.customization.apps[i].settings.chat_icon_pos = tempObj;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  widgetIconPosHelper = (i: number, prop: string, el: any) => {
    if (this.hasOwnProperty(this.customization.apps[i], ("settings.widget_icon_pos." + prop))) {
      this.customization.apps[i].settings.widget_icon_pos[prop] = el;
    } else {
      let tempObj = {
        ...this.customization.apps[i].settings.widget_icon_pos
      }
      tempObj[prop] = el;
      this.customization.apps[i].settings.widget_icon_pos = tempObj;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  reset = (i: number, prop: string) => {
    let props = prop.split(".");
    let updatedVal = this.getPropertyValue(this.customization.org.settings, prop);
    if (props.length == 1) {
      this.customization.apps[i].settings[prop] = updatedVal;
    } else if (props.length == 2) {
      if (!this.hasOwnProperty(this.customization.apps[i], "settings." + props[0])) {
        this.customization.apps[i].settings[props[0]] = {};
      }
      this.customization.apps[i].settings[props[0]][props[1]] = updatedVal;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i])
  }

  resetMulti = (i: number, props: string) => {
    props.split(",").forEach(prop => {
      this.reset(i, prop.trim());
    })
  }

  getChangedApp = (appId: String) => {
    return this.customization.changedApps.filter(app => appId === app.appId)[0];
  }

  hasOwnProperty = (o: object, prop: string) => {
    let tempObj = { ...o };
    for (let level of prop.split(".")) {
      if (!tempObj.hasOwnProperty(level)) {
        return false;
      };
      tempObj = { ...tempObj[level] };
    }
    return true;
  }

  getPropertyValue = (o: object, prop: string) => {
    if (o == undefined) return;
    const [head, ...rest] = prop.split(".");
    if (rest.length && o.hasOwnProperty(head)) {
      return this.getPropertyValue(o[head], rest.join("."));
    } else {
      return o.hasOwnProperty(head) ? o[head] : "";
    }
  }

  changeNotificationDelay(index, event) {
    this.customization.apps[index].settings.notificationDelay = event.target.value;
  }

  openModal(appId, $event, componentName) {
    this.modal.appId = appId;
    this.modal.activeComponent = componentName;
    this.modal.openPopup($event);
  }

  openModalStepTheme(appId, logoSrc) {
    this.modalStepTheme.openPopup(appId, logoSrc);
  }

  openModalRules(app, appId, forProp) {
    switch (forProp) {
      case "widgetIcon": {
        this.modalRule.parentRulesSections = this.getRulesInSections(app, 'widgetIcon')
        this.modalRule.openPopup(appId);
        this.modalRule.forProperty = "widgetIcon";
        break;
      }
      case "chatbotIcon": {
        this.modalRule.parentRulesSections = this.getRulesInSections(app, 'chatbotIcon')
        this.modalRule.openPopup(appId);
        this.modalRule.forProperty = "chatbotIcon";
        break;
      }
    }

  }

  openTutorialModal(app, appId) {
    this.modalTutorial.openTutorialModal(app, appId);
  }

  getRulesInSections(app, forProp) {
    if (app.settings) {
      let andRules = []
      let sectionIndex = 0;
      let rulesSections = [];
      let rules;
      switch (forProp) {
        case 'widgetIcon': {
          rules = app.settings.rules;
          break;
        }
        case 'chatbotIcon': {
          rules = app.settings.chatBotrules;
          break;
        }
      }
      if (rules === undefined || rules.length == 0) {
        andRules.push({
          type: "",
          name: "",
          condition: "",
          value: " ",
          logical_condition: "",
          showValueField: true
        })
        rulesSections.push(andRules);
        return rulesSections;
      }
      for (let i = 0; i < rules.length; i++) {
        if (rules[i].logical_condition === '||') {
          if (i > 0) { rulesSections.push(JSON.parse(JSON.stringify(andRules))) }
          andRules = [];
          andRules.push(JSON.parse(JSON.stringify(rules[i])))
        } else {
          andRules.push(JSON.parse(JSON.stringify(rules[i])))
        }
      }

      if (andRules.length > 0) {
        rulesSections.push(JSON.parse(JSON.stringify(andRules)))
      }
      return rulesSections;
    }
  }

  getPropValWithDefault = (app, prop, def) => {
    let propVal = this.getPropValue(app, prop);
    if (propVal === "") {
      return def;
    }
    return propVal;
  }

  enforceMinMax = (event) => {
    let el = (event.target as HTMLInputElement);
    if (el.value != "") {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = el.min;
      }
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = el.max;
      }
    }
  }

  enforceMinMaxWithValUpdate = (i, prop, isWidg, event) => {
    this.enforceMinMax(event);
    if (isWidg && this.hasOwnProperty(this.customization.apps[i], "settings.widgetIconSize")) {
      this.customization.apps[i].settings.widgetIconSize[prop] = (event.target as HTMLInputElement).value;
    } else if (this.hasOwnProperty(this.customization.apps[i], "settings.chatIconSize")) {
      this.customization.apps[i].settings.chatIconSize[prop] = (event.target as HTMLInputElement).value;
    }
  }


  getPopupStyles(i, property) {
    switch (property) {
      case "type": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.type");
      } case "stepTitleColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.stepTitleColor");
      } case "bgColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.bgColor");
      } case "borderRadius": {
        return (this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.borderRadius") + "px");
      } case "stepPopupWidth": {
        return (this.getPropValue(this.customization.apps[i], "settings.stepPopupWidth") + "px");
      } case "borderColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.borderColor");
      } case "borderWidth": {
        return (this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.borderWidth") + "px");
      } case "stepTitleFontSize": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.stepTitleFontSize");
      } case "stepTitleFontWeight": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.stepTitleFontWeight");
      } case "stepTitleFontFamily": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.stepTitleFontFamily");
      } case "stepDescColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.stepDescColor");
      } case "stepDesFontSize": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.stepDesFontSize");
      } case "stepDesFontFamily": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.stepDesFontFamily");
      } case "nextBtnColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.nextBtnColor");
      } case "nextBtnBackground": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.nextBtnBackground");
      } case "prevBtnColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.prevBtnColor");
      } case "prevBtnBackground": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.prevBtnBackground");
      } case "closeIconColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.closeIconColor");
      } case "audioIconColor": {
        return this.getPropValue(this.customization.apps[i], "settings.popupDesign.current.audioIconColor");
      }
    }

  }

  changePrefColor = (i, ind, event) => {
    let color = (event.target as HTMLInputElement).value;
    let index = 0;
    if (this.hasOwnProperty(this.customization.apps[i], "settings.stepDefaultColorArray")) {
      index = i;
    }
    let updatedObj = JSON.parse(JSON.stringify(this.customization.apps[index].settings.stepDefaultColorArray));
    updatedObj[ind] = color;
    this.customization.apps[i].settings["stepDefaultColorArray"] = updatedObj;
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeSlideText = (i: number, prop: string, event: Event) => {
    this.customization.apps[i].settings[prop] = (event.target as HTMLInputElement).value;
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeOrgLabel(property, $event) {
    this.customization.apps[0].settings.userLabels[property] = $event.target.value
    this.addAppToChanged('0', this.customization.apps[0])
  }

  saveSettings($event) {
    let tempAppIdArr = [];
    this.appList.forEach(app => {
      if (app.checked) tempAppIdArr.push(app.app_id);
    });
    let newChangedArr = this.customization.changedApps.filter(app => app.appId == '0' || tempAppIdArr.includes(app.appId));
    this.customization.changedApps = JSON.parse(JSON.stringify(newChangedArr));
    for (let i = 0; i < this.customization.changedApps.length; i++) {
      let tempChangedApp = this.customization.changedApps[i];
      if (this.hasOwnProperty(tempChangedApp, "settings.popupDesign")) {
        if (tempChangedApp.settings.popupDesign.type === "default") {
          tempChangedApp.settings.popupDesign.default = { ...tempChangedApp.settings.popupDesign.current }
        } else {
          tempChangedApp.settings.popupDesign.classic = { ...tempChangedApp.settings.popupDesign.current }
        }
        this.customization.changedApps[i] = tempChangedApp;
      }
    }

    this.loader = false;
    this.customization.saveAllSettings().subscribe(result => {
      this.setTableHeaders();
    });
  }

  discardSettings($event) {
    this.customization.changedApps.length = 0;
    this.setTableHeaders();
  }

  changeAllGuideStatus = (i: number, prop: string) => {
    this.customization.apps[i].settings[prop] = !this.customization.apps[i].settings[prop];
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  changeSendFeedbackOption = (i: number, prop: string) => {
    this.customization.apps[i].settings[prop] = !this.customization.apps[i].settings[prop];
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  getStepPrevLabelVal = (o, prop, isOrg) => {
    if (this.hasOwnProperty(o, prop)) {
      return this.getPropertyValue(o, prop);
    } else {
      if (this.hasOwnProperty(o, 'settings.popupDesign.type')) {
        let designType = this.getPropertyValue(o, 'settings.popupDesign.type');
        if (designType == 'classic')
          return this.admin_data.manageContentVariable27
        return this.admin_data.prev;
      } else {
        if (isOrg) {
          return this.admin_data.prev;
        }
        return this.getStepPrevLabelVal(this.customization.apps[0], prop, true);
      }
    }
  }

  setGetSetting = (i: number, prop: string) => {
    let propVal = this.getPropertyValue(this.customization.apps[i].settings, prop);
    if (typeof propVal != "boolean") {
      this.customization.apps[i].settings[prop] = this.customization.apps[0].settings[prop];
      this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
    }
    return this.customization.apps[i].settings[prop] == true;
  }

  changeIconType = (i: number, propValue: string, isWidget: boolean) => {
    if (isWidget) {
      this.customization.apps[i].settings.widgetIconType = propValue;
    } else {
      this.customization.apps[i].settings.chatIconType = propValue;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  updateIconSize = (i: number, prop: string, event: Event, isWidget: boolean) => {
    if (isWidget) {
      if (!this.hasOwnProperty(this.customization.apps[i], "settings.widgetIconSize")) {
        this.customization.apps[i].settings.widgetIconSize = {};
      }
      this.customization.apps[i].settings.widgetIconSize[prop] = (event.target as HTMLInputElement).value;
    } else {
      if (!this.hasOwnProperty(this.customization.apps[i], "settings.chatIconSize")) {
        this.customization.apps[i].settings.chatIconSize = {};
      }
      this.customization.apps[i].settings.chatIconSize[prop] = (event.target as HTMLInputElement).value;
    }
    this.addAppToChanged(this.customization.apps[i].appId, this.customization.apps[i]);
  }

  getIconDimension = (app, prop) => {
    let val = this.getPropValue(app, prop);
    val = val < 50 ? 50 : val;
    val = val > 200 ? 200 : val;
    return (val + 'px');
  }

  getIconHeight = (app, prop1, prop2, prop3) => {
    if (this.getPropValue(app, prop1) == 'circular') {
      return this.getIconDimension(app, prop2);
    } else {
      return this.getIconDimension(app, prop3);
    }
  }

  getBorderRadius = (app: object, prop: string) => {
    return this.getPropValue(app, prop) == 'circular' ? '50%' : '0'
  }

  validateInput(event: Event) {
    this.userService.validateInput(event);
  }

  getImageSrc = (i, prop) => {
    let propValue = this.getPropValue(this.customization.apps[i], "settings." + prop);
    let defValue = this.getPropertyValue(this.defaultOrgVal, "settings." + prop);
    if (propValue == defValue) {
      return environment.CDN_BASE_URL + this.assetUrl + propValue;
    } else {
      let isDef = false;
      try {
        if (propValue.includes(this.assetUrl)) {
          isDef = true;
        }
        let url = new URL(propValue);
        return environment.CDN_BASE_URL + url.pathname + (isDef ? '' : this.cdn_signature);
      } catch (error) {
        return environment.CDN_BASE_URL + this.assetUrl + defValue;
      }
    }
  }

  getAppObj = (id, name) => {
    return { "app_id": id, "app_name": name, "checked": false };
  }

  setRendering = async () => {
    let newAppList;
    const innerCall = async () => {
      newAppList = JSON.parse(JSON.stringify(this.appList));
      for (let i = 0; i < this.appList.length; i++) {
        if (this.preRenderApp.includes(this.appList[i].app_id)) {
          newAppList[i].checked = true;
          this.addToApps(this.appList[i].app_id);
        }
      }
    }
    await innerCall();
    this.appList = newAppList;
    newAppList = null;
  }

  updateAppList = (event: any) => {
    if (event.checked) {
      this.addToApps(event.app_id);
      this.preRenderApp.push(event.app_id);
    } else {
      let index = this.customization.apps.findIndex(app => app.appId == event.app_id);
      if (index != undefined) {
        this.customization.apps.splice(index, 1);
      }

      let index_render = this.preRenderApp.indexOf(event.app_id);
      if (index_render != undefined) {
        this.preRenderApp.splice(index_render, 1);
      }
    }
    this.updateAppView();
  }

  addToApps = (app_id) => {
    let isAdd = true;
    //this loop is to prevent intermittent issue of apps getting double while navigating from customization center
    for (let i = 0; i < this.customization.apps.length; i++) {
      if (this.customization.apps[i].appId.includes(app_id)) {
        isAdd = false;
        break;
      }
    }
    if (isAdd) {
      let tempObj = this.globalAppObj.get(app_id);
      this.customization.apps.push(tempObj);
    }
  }

  showNoAssignedError = () => {
    if (this.loader) {
      if (this.customization.apps.length == 0) {
        return true;
      } else if ((this.customization.apps.length == 1 && this.customization.apps[0].appId == "0")) {
        return true;
      }
    }
    return false;
  }

  updateAppView = () => {
    let userSettingObj = JSON.parse(this.userData["settings"]);
    let tempAppIdArr = [];
    this.appList.forEach(app => {
      if (app.checked) tempAppIdArr.push(app.app_id);
    });
    let tempObj;
    if (userSettingObj["app_view"] == undefined) {
      tempObj = {};
    } else {
      tempObj = JSON.parse(JSON.stringify(userSettingObj["app_view"]));
    }
    tempObj[this.userService.organization_id] = tempAppIdArr
    userSettingObj["app_view"] = tempObj;
    let userObj = JSON.parse(JSON.stringify(this.userData));
    userObj["settings"] = JSON.stringify(userSettingObj);
    this.userService.editUser(userObj).subscribe(response => {
      this.userData["settings"] = JSON.stringify(userSettingObj);
    })
  }

  getStyle = (i, prop) => {
    let app = this.customization.apps[i];
    let prefixConst = "settings.tutorial_guide.";
    if (prop != "shadow") {
      return this.getPropValue(app, prefixConst + prop);
    } else {
      return this.getPropValue(app, prefixConst + 'outerBox.shadow.horizontal') + 'px '
        + this.getPropValue(app, prefixConst + 'outerBox.shadow.vertical') + 'px '
        + this.getPropValue(app, prefixConst + 'outerBox.shadow.blur') + 'px '
        + this.getPropValue(app, prefixConst + 'outerBox.shadow.spreadRadius') + 'px '
        + this.getPropValue(app, prefixConst + 'outerBox.shadow.color')
        + (this.getPropValue(app, prefixConst + 'outerBox.shadow.inset') == true ? ' inset' : '');
    }
  }

  getModalHeight = (ind) => {
    let elem = document.getElementById("navigationWrapper_" + ind);
    if (elem != undefined) {
      return (elem.clientHeight + 120) + 'px';
    }
    return "150px";
  }

  getModalWidth = (ind) => {
    let elem = document.getElementById("navigationWrapper_" + ind);
    if (elem != undefined) {
      return (elem.clientWidth + 120) + 'px';
    }
    return "150px";
  }

  prevSnoozeValReq = (i) => {
    let prevValue = this.getPropValue(this.customization.apps[i], "settings.notificationsTime");
    if (!this.delayTimeArr.includes(prevValue)) {
      return true;
    }
    return false;
  }

  getVisibilityBasedOnMirAppNAppAdm = (i, defVal) => {
    if (this.isAppAdmin && i == 0) {
      return false;
    } else if (!this.isAppAdmin && i == 0) {
      return true;
    } else if (this.isMirrorApplication == "0" || defVal == "1") {
      return true;
    } else {
      return false;
    }
  }

  getPadding = (i) => {
    let currentObj = this.getPropValue(this.customization.apps[i], "settings.popupDesign.current");
    if (currentObj.padding == undefined) {
      return "0";
    }
    let padding = this.getPadUtil(currentObj, "top") + this.getPadUtil(currentObj, "right") + this.getPadUtil(currentObj, "bottom") + this.getPadUtil(currentObj, "left");
    return padding;
  }

  getPadUtil = (obj, prop) => {
    if (obj.padding[prop] == undefined) return "0px ";
    return obj.padding[prop] + "px ";
  }

  hideNote = () => {
    this.firstTimeNote = false;
  }

  validateWidgetZIndexOnfocusOut = (event, i: number, app) => {
    let el = (event.target) as HTMLInputElement;
    if (el.value === '-' || el.value === '') el.value = "Default";
    this.changeSelect(i, 'widget_icon_zindex', event);
  }

  triggerFileInput(fileInput: HTMLInputElement) {
    fileInput.click();
  }
}

// onScroll($event) {
//   let scrollableDiv = document.getElementById('tableContainer');
//   let rows = Array.from(scrollableDiv.getElementsByTagName('tr'));
//   if (window.scrollY > 80) {
//     window.scrollTo({
//       top: 80,
//       left: 0
//     });
//   }
//   const scrollTop = scrollableDiv.scrollTop;
//   const scrollHeight = scrollableDiv.scrollHeight;
//   const clientHeight = scrollableDiv.clientHeight;
//   let cumulativeHeight = 0;
//   let currentRow = 1;
//   for (const row of rows) {
//     cumulativeHeight += row.offsetHeight;
//     if (cumulativeHeight > scrollTop) {
//       break;
//     }
//     currentRow++;
//   }

//   if (currentRow < 3) {
//     this.userService.activeClass = "branding"

//   }
//   else if (currentRow < 8) {
//     this.userService.activeClass = "widget"
//     this.currentActiveClass = "widget"
//   }
//   else if (currentRow < 12) {
//     this.userService.activeClass = "videogif"
//     this.currentActiveClass = "videogif"
//   }
//   else if (currentRow < 24) {
//     this.userService.activeClass = "step"
//     this.currentActiveClass = "step"
//   }
//   else if (currentRow < 28) {
//     this.userService.activeClass = "notification"
//     this.currentActiveClass = "notification"
//   }
//   else if (currentRow < 34) {
//     this.userService.activeClass = "chatbot"
//     this.currentActiveClass = "chatbot"
//   }
//   else if (currentRow < 47 && !(Math.ceil(scrollTop + clientHeight) == scrollHeight)) {
//     this.userService.activeClass = "features"
//     this.currentActiveClass = "features"
//   } else {
//     this.userService.activeClass = 'labels'
//     this.currentActiveClass = "labels"
//   }
// }