import adminLabels from "../ApplicationStrings/trans_utils";

export const ADMIN_SETTINGS_FORMDATA = {
  attributes: [
    // {
    //   type: "checkbox",
    //   name: "doitforme",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
    //   value: "",
    //   checked: false,
    //   formtype: "inline",
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelDoItForMe,
    //     class: "mb-0 font14",
    //     inputclass: "primary",
    //   },
    //   placeholder: "DoitForme",
    //   tooltip : adminLabels.tooltipDoItForMe,
    // },
    // {
    //   type: "checkbox",
    //   name: "chatbot",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelMyChat,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipMyChat,
    // },
    // {
    //   type: "checkbox",
    //   name: "myteacher",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelTeachMe,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipTeachMe,
    // },
    // {
    //   type: "checkbox",
    //   name: "onboarding_guides",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelTutorialGuides,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipTutorialGuides,
    // },
    {
      type: "checkbox",
      name: "guide_translation",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.labelGuideTranslation,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipGuideTranslation
    },//0
    {
      type: "checkbox",
      name: "current_page_beacon",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.labelShowBeaconGuidesOnCurrentPage,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipShowBeaconGuidesOnCurrentPage,
    },//1
    {
      type: "checkbox",
      name: "guide_segmentation",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.labelGuideSegmentation,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipGuideSegmentation,
    },//2
    {
        type: "checkbox",
        name: "show_tooltips_during_workflow_guide",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
        value: "",
        checked: true,
        validators: [],
        ui: {
            label: adminLabels.showtooltipsWithWorkflowTutorial,
            class: "mb-0",
            inputclass: "primary",
        },
        tooltip: adminLabels.showtooltipsWithWorkflowTutorialTooltip
    },//19
    // {
    //   type: "checkbox",
    //   name: "all_guide",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelShowCurrentPageGuidesOnly,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipShowCurrentPageGuidesOnly,
    // },
    // {
    //   type: "checkbox",
    //   name: "tooltip_enhancements",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelTooltipEnhancements,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipTooltipEnhancements,
    // },
    {
      type: "checkbox",
      name: "isPITrackingEnabled",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox topMargin my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.labelUserInformationTracking,
        class: "mb-0",
        inputclass: "primary",
      },
        tooltip: adminLabels.tooltipUserInformationTracking,
        history: true,
    },//3
    {
      type: "checkbox",
      name: "sso",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox topMargin my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.labelSSO,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipSSO,
    },//4
    {
      type: "checkbox",
      name: 'myBotEnabled',
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.labelGuideAutomation,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipGuideAutomation,
    },
    {
      type: "checkbox",
      name: "mediafile_creation",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox topMargin my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.labelMediaFileCreation,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipMediaFileCreation,
    },
    {
      type: "checkbox",
      name: "create_tooltip_media_file",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label :  adminLabels.mediaFileCreationForTooltipGuides,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipMediaFileCreationTooltipGuides,
    },//5
    {
        type: "checkbox",
        name: "media_file_notifications",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
        value: "",
        checked: false,
        validators: [],
        ui: {
          label : adminLabels.enableMediaNotification,
          class: "mb-0",
          inputclass: "primary",
        },
        tooltip : adminLabels.tooltipEnableInsights
      },//6
    // {
    //   type: "checkbox",
    //   name: "youtube_setting",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelYoutubeSettings,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipYoutubeSettings,
    // },
    // {
    //   type: "checkbox",
    //   name: "enable_org_and_guide_json_caching",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label :  adminLabels.labelEnableOrgAndGuidesJSONCaching,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipEnableOrgAndGuidesJSONCaching,
    // },//7
    {
      type: "checkbox",
      name: "api_connector",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox topMargin my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label : adminLabels.labelAPIConnector,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipAPIConnector
    },//9
    {
      type: "checkbox",
      name: 'efficient_rule_mode',
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label : adminLabels.labelLoadContextChangeOnPageClick,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipLoadContextChangeOnPageClick,
    },//10
    // {
    //   type: "checkbox",
    //   name: 'task_list',
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label : adminLabels.labelTaskList,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipTaskList
    // },
    // {
    //   type: "checkbox",
    //   name: 'media_files_themes',
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
    //   value: "",
    //   checked: false,
    //   validators: [],
    //   ui: {
    //     label : adminLabels.labelMediaFileTheme,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipMediaFileTheme
    // },
    {
      type: "checkbox",
      name: "display_publish_date",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label : adminLabels.labelDisplayGuidePublishingDate,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipDisplayGuidePublishingDate
    },//11
    // {
    //   type: "checkbox",
    //   name: "maintenance_mode",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
    //   value: "",
    //   checked: false,
    //   formtype: "inline",
    //   validators: [],
    //   ui: {
    //     label : adminLabels.labelMaintenanceMode,
    //     class: "mb-0",
    //     inputclass: "primary",
    //   },
    //   tooltip : adminLabels.tooltipMaintenanceMode
    // },//12
    {
      type: "checkbox",
      name: "enable_guideme_as_secondary_option",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
      value: "",
      checked: false,
      formtype: "inline",
      validators: [],
      ui: {
        label : adminLabels.labelEnableGuidemeAsSecondaryOption,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipEnableGuidemeAsSecondaryOption
    },//13
    // {
    //   type: "checkbox",
    //   name: "hash_player_pii",
    //   class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
    //   value: "",
    //   checked: false,
    //   formtype: "inline",
    //   validators: [],
    //   ui: {
    //     label : adminLabels.labelHashPlayerUsersData,
    //     class: "mb-0",
    //     inputclass: "primary",
    //     message:adminLabels.message1
    //   },
    //   tooltip : adminLabels.tooltipHashPlayerUsersData
    // },//14
    {
      type: "checkbox",
      name: "on_premise_deployment",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label : adminLabels.labelAllowOnPremiseDeployment,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipAllowOnPremiseDeployment
    },//15
    {
      type: "text",
      name: "ftp_endpoint",
      value: "",
      formtype: "admin_inline",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
      validators: [
        {
          required: true
        },
        {
          regex:`(ftp)://[\\w-]+(\\.[\\w-]+)+([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?`
        }
      ],
      disable:false,
      tooltip : adminLabels.tooltipFTPEndpointToSaveContent,
      ui: {
        label : adminLabels.labelFTPEndpointToSaveContent,
        class: "mb-0",
        inputclass: "form-control inline-field",
        maxLength: 5
      },
    },//16
    {
        type: "checkbox",
        name: "self_hosted_player",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox topMargin my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
        value: "",
        checked: false,
        validators: [],
        ui: {
          label : adminLabels.selfHostedPlayer,
          class: "mb-0",
          inputclass: "primary",
        },
        tooltip : adminLabels.tooltipEnableInsights
      },//17
    {
      type: "checkbox",
      name: "display_accept_cookie_popup",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
      value: "",
      checked: true,
      validators: [],
      ui: {
        label : adminLabels.labelDisplayAcceptCookiePopup,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipDisplayAcceptCookiePopup
        },//18    
    {
      type: "checkbox",
      name: "keyboard_shortcuts",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
      value: "",
      checked: true,
      validators: [],
      ui: {
        label : adminLabels.keyboardShortcuts,
        class: "mb-0",
        inputclass: "primary",
      },
        tooltip: adminLabels.keyboardShortcutsTooltip
    },
    {
      type: "checkbox",
      name: "autobot",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
      value: "",
      checked: true,
      validators: [],
      ui: {
        label : adminLabels.autobot,
        class: "mb-0",
        inputclass: "primary",
      },
    },
    {
        type: "checkbox",
        name: "auto_generate_keywords",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
        value: "",
        checked: false,
        validators: [],
        ui: {
            label: adminLabels.auto_generate_keywords,
            class: "mb-0",
            inputclass: "primary",
        },
    },
    {
      type: "number",
      name: "refresh_token_expiry",
      value: "",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2 font14 topMargin",
      validators: [
        {
          required : true,
          pattern:"^[0-9]*$",
          min: 3600
        },
      ],
      ui: {
        label : adminLabels.labelSetRefreshTokensExpiryTime,
        class: "font-weight-bold font14",
        inputclass: "form-control",
        min: 3600,
        maxLength: 10
      },
      tooltip : adminLabels.tooltipSetRefreshTokensExpiryTime
    },//refresh_token_expiry //20
    {
        type: "checkbox",
        name: "insights",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 font14",
        value: "",
        checked: false,
        validators: [],
        ui: {
          label : adminLabels.labelEnableInsights,
          class: "mb-0",
          inputclass: "primary",
          message:adminLabels.message2
        },
        tooltip: adminLabels.tooltipEnableInsights,
        history: true,
      },//21 insights
    {
      type: "checkbox",
      name: "guide_insights",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-1 my-xl-1 mr-0  font14 ",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label : adminLabels.labelGuideInsights,
        class: "mb-0",
        inputclass: "primary",
      },
        tooltip: adminLabels.tooltipGuideInsights,
        history: true,
    },//22 guide_insights
    {
      type: "checkbox",
      name: "real_time_stats",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-1 my-xl-1 mr-0 font14",
      value: "",
      checked: false,
      validators: [],
      ui: {
        label : adminLabels.labelRealTimeInsights,
        class: "mb-0",
        inputclass: "primary",
      },
        tooltip: adminLabels.tooltipRealTimeInsights,
        history: true,
    },//23 real_time_stats
    {
      type: "heading",
      name: "app_insights",
      class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 topMargin bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 padding-left-10 font14 font-weight-bolder bg-checkbox mt-2",
      value: "",
      checked: false,
      validators: [],
      text_class: "heading_text",
      ui: {
        label : adminLabels.labelAppInsights,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipAppInsights
    }, //24 ap_insights heading
    {
      type: "heading",
      name: "application_name",
      class: "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 font14 font-weight-bold bg-checkbox",
      value: "",
      checked: false,
      validators: [],
      text_class: "heading_text pl-2",
      ui: {
        label : adminLabels.applicationsTableHeaderApplicationName,
        class: "mb-0 pl-2",
        inputclass: "primary",
      },
      tooltip : ""
    },//25 application_name
    {
      type: "heading",
      name: "page_tracking",
      class: "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 font14 font-weight-bold bg-checkbox",
      value: "",
      checked: false,
      validators: [],
      text_class: "heading_text",
      ui: {
        label : adminLabels.pageTracking,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipPageTracking
    },//26 page_tracking
    {
      type: "heading",
      name: "feature_tracking",
      class: "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 font14 font-weight-bold bg-checkbox",
      value: "",
      checked: false,
      validators: [],
      text_class: "heading_text",
      ui: {
        label : adminLabels.featureTracking,
        class: "mb-0",
        inputclass: "primary",
      },
      tooltip : adminLabels.tooltipFeatureTracking
    },//27 feature_tracking
    {
      type: "select",
      name: "insights_priority",
      value: "",
      class: "col-12 col-sm-12 col-md-11 col-lg-12 col-xl-12 py-2 topMargin bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 mr-lg-4 mr-xl-4 pb-2 pb-sm-2 pb-md-2 pb-lg-2 pb-xl-2 rounded-bottom font14",
      options: [
        {
          value: "high",
          name: adminLabels.high,
        },
        {
          value: "medium",
          name: adminLabels.medium,
        },
        {
          value: "low",
          name: adminLabels.low,
        },
      ],
      validators: [
        {
          required: true,
        },
      ],
      ui: {
        label : adminLabels.labelInsightsPriority,
        class: "font-weight-bold",
        inputclass: "form-control",
      },
      tooltip : adminLabels.tooltipInsightsPriority
    },//28 insights_priority
    {
        type: "checkbox",
        name: "enable_dap",
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 topMargin pt-0 pb-4 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-1 my-xl-1 mr-0 font14",
        value: "",
        checked: false,
        validators: [],
        ui: {
            label: adminLabels.enableDAP,
            class: "mb-0",
            inputclass: "primary",
        },
        tooltip: adminLabels.enableDAPTooltip
    },// 29 enable dap
    {
        type: "checkbox",
        name: "enable_user_session_tracking",
        class: "col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 pt-0 pb-4 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-1 my-xl-1 mr-0 font14",
        value: "",
        checked: false,
        validators: [],
        ui: {
            label: adminLabels.enableUserSessionTracking,
            class: "mb-0",
            inputclass: "primary",
        },
        tooltip: ""
    },// 31 enable user session
    {
      type: "number",
      name: "guide_limit",
      value: "",
      formtype: "admin_inline",
      class: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2 font14",
      validators: [
        {
          required: true,
          pattern:"^[0-9]*$"
        },
      ],
      ui: {
        label : adminLabels.labelMaxGuideLimit,
        class: "font-weight-bold",
        inputclass: "form-control",
        maxLength: 5
      },
    },
    {
      type: "number",
      name: "app_limit",
      value: "",
      class: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2 font14",
      validators: [
        {
          required: true,
        },
      ],
      ui: {
        label : adminLabels.labelMaxApplicationLimit,
        class: "font-weight-bold",
        inputclass: "form-control",
        maxLength: 3
      },
    },
    {
      type: "number",
      name: "step_limit",
      value: "",
      class: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2 font14",
      validators: [
        {
          required: true,
        },
      ],
      ui: {
        label : adminLabels.labelMaxStepLimit,
        class: "font-weight-bold",
        inputclass: "form-control",
        maxLength: 4
      },
    },
    {
      type: "number",
      name: "max_admin_users",
      value: "",
      class: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2 font14",
      validators: [
        {
          required: true,
        },
      ],
      ui: {
        label : adminLabels.labelMaxAdminUsers,
        class: "font-weight-bold",
        inputclass: "form-control",
        maxLength: 4
      },
    },
    {
      type: "number",
      name: "max_creator_users",
      value: "",
      class: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2 font14",
      validators: [
        {
          required: true,
        },
      ],
      ui: {
        label : adminLabels.labelMaxCreatorUsers,
        class: "font-weight-bold",
        inputclass: "form-control",
        maxLength: 4
      },
    },
    {
      type: "number",
      name: "max_player_users",
      value: "",
      class: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2 font14",
      validators: [
        {
          required: true,
        },
      ],
      ui: {
        label : adminLabels.labelMaxPlayerUsers,
        class: "font-weight-bold font14",
        inputclass: "form-control",
        maxLength: 6
      },
    },
  ],
  language_settings: {
    default: {
      language: "",
      name: "",
      voice: "",
    },
    translations: [],
  },

  insight_settings: [
  ]
};
