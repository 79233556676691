<!-- <div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view" 
    [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}"> -->
        <!-- <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb> -->
        <ng-container>
            <div class="font14">
                <b>{{this.admin_data.note}}</b>
                <br>
                {{this.admin_data.domainMessage}}
                <br>
                <br>
              </div>
        <div class="d-flex justify-content-end my-3">
            <div class="col-sm-8 col-md-6 col-lg-5 col-xl-5 p-0 text-right">
                <button type="button" class="btn btn-primary ml-2" (click)="showAddDomainModal()">{{this.admin_data.domainsAddDomainButtonText}}</button>
            </div>
        </div>
        <app-table [data]="domain_list" [titles]="domainService.appTitles" [dataKeys]="domainService.appDataKeys" [widths]="domainService.col_widths"
            (delete)="showModal($event)" (edit)="getDomainData($event)"></app-table>
        <div class="modal fade" id="addDomainModal" tabindex="-1" role="dialog" aria-labelledby="addDomainModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addDomainModalLabel">{{this.admin_data.domainsAddDomainButtonText}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <app-form [formData]="domain_formData" [formGroup]="formGroup"></app-form>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <!-- <button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
                            (click)="closeAddDomainModal()">{{this.admin_data.cancelButtonText}}</button> -->
                        <app-button [buttonData]="domainService.cancel_button" (clickhandler)="closeAddDomainModal()"></app-button>
                        <!-- <button type="button" class="btn btn-primary minWidth-120" (click)="addDomain()"
                            [disabled]="!formGroup.valid">Add Domain</button> -->
                        <app-button  [disabled]="!formGroup.valid" [buttonData]="domainService.isEdit?domainService.update_button:domainService.add_button" (clickhandler)="domainService.isEdit?updateDomain():addDomain()"></app-button>
                    </div>
                </div>
            </div>
        </div>
        <app-pagination [offset]="offset" [totalRecords]="paginationService.total" [currentPage]="paginationService.currentPage"
            [limit]="limit" (gotoFirstPage)="gotoFirstPage()" (gotoLastPage)="gotoLastPage($event)"
            (gotoPreviousPage)="prependOffsetAndGetDomainList()" (gotoNextPage)="appendOffsetAndGetDomainList()"
            (changeLimit)="changeLimit($event)" (gotoAction)="gotoPage($event)"></app-pagination>
        <ng-container *ngIf="domain_list">
            <ng-container *ngIf="domain_list.length>0">
                <app-delete-modal [delete_elem]="domain_list[deleteIndex]['domain']" (onDelete)="deleteDomain($event)">
                </app-delete-modal>
            </ng-container>
        </ng-container>
    </ng-container>
    <!-- </div>
</div> -->