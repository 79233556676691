<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>

        <section class="component-section">

			<div class="sectionBody">
                <div class="d-flex mb-3">
                    <div *ngIf="keyId != '';else generateCredentialsHeading" class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{ this.admin_data.editCredentials }}
                    </div>
                    <ng-template #generateCredentialsHeading>
                        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                        {{ this.admin_data.apiAndServicesGenrateCrendentialsButton }}
                        </div>
                </ng-template>
                </div>


                <div class="">
                    <div class="alert alert-secondary mb-4 font14" role="alert">
                        <b>{{this.admin_data.generateCredentialsVariable1}}</b>
                        <br>
                        <b>{{this.admin_data.generateCredentialsVariable2}}</b> 
                        {{this.admin_data.generateCredentialsVariable3}}
                        <br>
                        <b>{{this.admin_data.generateCredentialsVariable4}}</b>
                        {{this.admin_data.generateCredentialsVariable5}}
                        <br>
                        <b>{{this.admin_data.generateCredentialsVariable6}}</b> 
                        {{this.admin_data.generateCredentialsVariable7}}
                    </div>
                    <form [formGroup]="formGroup">
                        <div class="form-group">
                            <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                                <ng-container *ngFor="let form of formData;let j=index">
                                    <div class="{{form.class}}" *ngIf="form.type=='text'">
                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                        <input class="{{form.ui.inputclass}}" type="{{form.type}}" autocomplete="off"
                                            formControlName="{{form.name}}"
                                            pattern="^(?!\s*$).+">
                                            <div class="height12px font12">
                                                <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                    <div *ngIf="formGroup['controls'][form.name].invalid" class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                                </ng-container>
                                            </div>
                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='tag-input'">
                                        <div>
                                            <label class="{{form.ui.class}}">{{form.ui.label}}:</label>
                                            <tag-input formControlName="{{form.name}}" [ngModel]="form['selectedItems']"
                                                [onlyFromAutocomplete]="true">
                                                <tag-input-dropdown [autocompleteItems]="form['items']"
                                                    [showDropdownIfEmpty]="true" [dynamicUpdate]="false">
                                                </tag-input-dropdown>
                                            </tag-input>
                                        </div>
                                        <div class="height12px font12">
                                            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                <div *ngIf="formGroup['controls'][form.name].invalid" class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='password'">
                                        <div>
                                            <label class="{{form.ui.class}}">{{form.ui.label}}:</label>
                                            <input class="{{form.ui.inputclass}}" type="{{form.type}}" autocomplete="off"
                                                formControlName="{{form.name}}">
                                        </div>
                                        <div class="height12px font12">
                                            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                <div *ngIf="formGroup['controls'][form.name].invalid" class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='select'">
                                        <label class="{{form.ui.class}}">{{form.ui.label}}:</label>
                                        <select class="{{form.ui.inputclass}}" formControlName="{{form.name}}">
                                            <option *ngFor="let option of form['options']" value="{{option.value}}">
                                                {{option.name}}</option>
                                        </select>
                                        <div class="height12px font12">
                                            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                <div *ngIf="formGroup['controls'][form.name].invalid" class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='textarea'">
                                        <label class="{{form.ui.class}}">{{form.ui.label}}:</label>
                                        <textarea class="{{form.ui.inputclass}} resize-none" formControlName="{{form.name}}"
                                            rows="6" cols="50"></textarea>
                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='radio'" class = "d-inline-flex">
                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                        <ng-container *ngFor="let val of form.value;let m=index">
                                            <div class="custom-control-inline mx-4">
                                                <input type="radio" class="input-radio-custom" type="{{form.type}}"
                                                    id="{{val}}" formControlName="{{form.name}}" name="{{form.name}}"
                                                    (keyup)="seeChanges()" (change)="getValue(val)" [value]="val" [checked]="this.provisioning === val" >
                                                <label class="ml-2"
                                                    for="{{val}}">{{form.value_labels[m]}}</label>
                                            </div>
                                        </ng-container>
                                        <div class="height12px font12">
                                            <ng-container *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                <div *ngIf="formGroup['controls'][form.name].invalid" class="error color fs-small">
                                                    {{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='checkbox'">
                                        <label class="{{form.ui.class}}">{{form.ui.label}}:</label>
                                        <label class="switch" (click)="toggle(j)">
                                            <input class="{{form.ui.inputclass}}" formControlName="{{form.name}}"
                                                type="{{form.type}}" [value]="form.checked==true?1:0"
                                                [checked]="formGroup.get(form.name).value=='1'?true:false">
                                            <span class="slider round" (click)="$event.stopPropagation()"></span>
                                        </label>
                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='file'">
                                        <label class="{{form.ui.class}} float-left">{{form.ui.label}}:</label>
                                        <div class="{{form.otherdivclass}} float-left border border-dark text-center">
                                            <div class="h-100 w-30 m-auto">
                                                <img class="{{form.fileclass}}" [src]="form.source">
                                            </div>
                                            <div class="{{form.ui.inputclass}}">
        
                                                <input id="fileInput{{j}}" class="m-auto d-none" type="{{form.type}}"
                                                    formControlName="{{form.name}}" name="{{form.name}}" (keyup)="seeChanges()"
                                                    value="{{val}}" (change)="showFile($event.target.files,form)">
                                                <button type="button" class="btn btn-secondary btn-choose-img m-auto bg-white"
                                                    (click)="clickFileInput(j)">{{this.admin_data.generateCredentialsVariable8}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="form.type=='button'">
                                        <div class="float-left w-50px mr-100px" *ngIf="form.ui.label=='Save'">
                                            <input type="{{form.type}}" class="{{form.class}}" value="{{form.ui.label}}"
                                                [disabled]="formGroup.invalid">
                                        </div>
                                        <div class="float-left w-50px mr-100px" *ngIf="form.ui.label=='Cancel'">
                                            <input type="{{form.type}}" class="{{form.class}}" value="{{form.ui.label}}">
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
        
                            <ng-container *ngIf="formData[19]">
                                <div *ngIf="formData[19].formtype=='admin_inline'">
                                    <h4>{{this.admin_data.limitSettings}}</h4>
                                    <hr>
                                    <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                                        <ng-container *ngFor="let form of formData.slice(19,formData.length);let j=index">
                                            <div class="{{form.class}}" *ngIf="form.type=='inline_text'">
                                                <div>
                                                    <label class="{{form.ui.class}} pr-0">{{form.ui.label}}:</label>
                                                    <input class="{{form.ui.inputclass}} p-none" type="{{form.type}}" autocomplete="off"
                                                        formControlName="{{form.name}}">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="this.keyId === ''" class="form-group my-2">
                                <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                                        <label class="font-weight-bold font14">{{this.admin_data.generateCredentialsVariable15}}</label>
                                        <!-- <ng-multiselect-dropdown formControlName="applications" class="col-lg-8 font14"
                                            [placeholder]="'Select Applications'" [data]="dropdownList"
                                            [(ngModel)]="selectedItems" [settings]="dropdownSettings">
                                            {{this.admin_data.generateCredentialsVariable15}}
                                        </ng-multiselect-dropdown> -->
                                        <app-multi-checkbox-dropdown 
                                            placeholder="Select Applications" 
                                            formControlName="applications" 
                                            className="width-100-p"
                                            className2="width-100-p"
                                            componentName="generateCredentials" 
                                            [appList]="dropdownList"
                                            (updateAppList)="updateAppList($event)" 
                                            selectAll="true" 
                                            position="up"
                                            [preSelection]="initalSelectedApps"></app-multi-checkbox-dropdown>
                                        <ng-container
                                            *ngIf="formGroup.get('applications').touched || formGroup.get('applications').dirty">
                                            <div *ngIf="formGroup['controls']['applications'].invalid"
                                                class="col-lg-3 offset-2 font12">
                                                <div class="text-danger">{{this.admin_data.generateCredentialsVariable16}}</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-flex justify-content-center">
                            <!-- <button class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                                (click)="unassign()">{{this.admin_data.cancelButtonText}}</button> -->
                            <app-button [buttonData]="apiAndServices.cancel_button" (clickhandler)="unassign()"></app-button>
                            <app-button *ngIf="this.keyId !== ''" [disabled]="!formGroup.valid || !editedSecret" [buttonData]="apiAndServices.edit_button" (clickhandler)="editSecretKey()"></app-button>
                            <!-- <button type="button" class="btn btn-primary minWidth-120 mx-2" (click)="generateSecretKey()"
                                [disabled]="!formGroup.valid">Generate</button> -->
                            <app-button *ngIf="this.keyId === ''" [disabled]="!formGroup.valid" [buttonData]="apiAndServices.add_button" (clickhandler)="generateSecretKey()"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</div>