//@ts-nocheck
import { Component, Input, OnInit, HostListener } from "@angular/core";
import { UserService, User } from "../../../common/services/user.service";
import { DBUser, DashboardService } from "../../../common/services/dashboard.service";
import { OrganisationService } from "../../../common/services/organisation.service";
import { ApplicationService } from "../../../common/services/application.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { RoleService } from "../../../common/services/role.service";
import { BehaviorSubject,  debounceTime, filter } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { PaginationService } from '../../../pagination.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../../../common/services/toast-modal.service";
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";

declare var $: any;

@Component({
    selector: "app-users",
    templateUrl: "./users.component.html",
    styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
    limit = 10;
    offset = 0;
    users: DBUser[];

    pages = [1, 2, 3];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    deleteIndex = 0;
    unlockIndex = 0;
    delete_user_id = 0;
    unlock_user_id = {};
    searchInputApp;
    allRoles = [];
    org_id;
    selectedOrg;
    search_text = "";
    is_suspend_filter;
    role_filter;
    org_role_filter;
    is_global = false;
    is_local = false;
    table_titles: string[] = [];
    table_title_keys: string[] = [];
    col_widths: string[] = [];
    is_searching=false;

    disableNext = false;
    disablePrev = false;
    showPagination = false;
    prevGetUserListCall;
    pageLimitSet = false;
    lockModal = false;
    activeModal = false;
    breadcrumb;
    selected_user_id;
    csv = '"FIRST NAME","LAST NAME","EMAIL ID","USER ROLE","APP ACCESS ROLE"';

    admin_data = adminLabels ;
    lastSearchedValue = "";

    private gotoPageSubject = new BehaviorSubject<number>(1);

    @Input() source;

    constructor(
        public userService: UserService,
        public dbService: DashboardService,
        private organisationService: OrganisationService,
        private application: ApplicationService,
        private router: Router,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private titleService: Title,
        public paginationService: PaginationService,
		private errorResponseService : ErrorResponseService,
        private toastService: ToastModalService
    ) {
        // @Input() source;

 
        console.log(this.userService)
        this.titleService.setTitle( this.admin_data.usersBreadCrumbTitle + " | " + "Cornerstone Guide");
        this.userService.getUserData();
        this.paginationService.initializeValues();
        this.organisationService.getCDNData()
        this.userService.activeTab = 'users';
        this.table_title_keys = this.dbService.userKeys;
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            this.col_widths = this.dbService.col_widths;
            this.is_global = false;
            this.is_local = false;

            if((!this.org_id) && (this.userService.user.role == "superadmin" || this.userService.user.role == "partner_admin" ))
            {
                this.is_global = true;
            }
            if((this.org_id) && (this.userService.user.role == "superadmin" || this.userService.user.role == "partner_admin" || this.userService.user.role == "mg_admin" ||  this.userService.user.role == "owner"))
            {
                this.is_local = true;
            }

            if (this.is_local) {


                this.organisationService.getOrganization(this.org_id).subscribe(
                    (response) => {
                        let organization = response["data"]["organization"];

                        this.userService.organization = organization["name"];
                        this.userService.organization_id =
                            organization["organization_id"];
                        // this.userService.user.cdn_signature = organization["cdn_signature"];
                        this.userService.organization = organization;
                        let admin_settings = JSON.parse(
                            this.userService.organization.admin_settings
                        );
                        this.userService.enableSegments =
                            admin_settings["guide_segmentation"] == "1" ? true : false;
                        this.userService.enableSSO =
                            admin_settings["sso"] == 1 ? true : false;

                        this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                        this.userService.enableMediaThemes=admin_settings['media_files_themes'] == 0 ? false : true;

                        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                        let org = organization;
                        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));
                        cdn_sign["cdn_signature"] = organization["cdn_signature"];
                        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];
                        localStorage.setItem("organization", JSON.stringify(org));
                        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                        this.selectedOrg = this.userService.organization_id;
                        // this.ngOnInit();
                    },
                    (error) => {
                    }
                );
            } else if (this.is_global) {
                console.log(this.source)
                console.log("here")
                this.is_global = true;

            }
            else {
                this.dbService.DBtableHead = [
                    adminLabels.addUserAvtar,
                    adminLabels.addUserFirstName,
                    adminLabels.addUserLastName,
                    adminLabels.addUserEmailId,
                    adminLabels.tableHeaderStatus,
                    adminLabels.addUserLockedStatus,
                    adminLabels.addUserRole,
                    adminLabels.addUserOrganisationRole,
                    adminLabels.dashboardUserAction,
                ];
                this.table_titles = this.dbService.DBtableHead;
            }


        });
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs("Users", this.route.url["_value"]);

                this.userService.setBreadcrumb([
                    {
                        name: adminLabels.usersBreadCrumbTitle,
                        route: null
                    }
                ])
                this.breadcrumb=this.userService.breadCrumbs;
        });


    }



    ngOnInit() {

        console.log(this.source)

        this.searchInputApp = document.getElementById("searchInputApp");
        this.userService.isEdit = false;
        this.pageLimitSet = false;
        this.gotoPageSubject.pipe(
            debounceTime(800))
            .subscribe((page: number) => {

                if (page != this.paginationService.currentPage) {
                    this.offset = this.paginationService.gotoPage(page, this.limit, this.offset);
                    if (this.offset >= 0) {
                        if (this.search_text) {
                            if (this.is_global) {
                                this.getSearchedUserListGlobal(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                            }
                            else {
                                this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                            }
                        } else {
                            this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                        }
                    }
                }
            })
        if (this.is_local) {
            this.dbService.DBtableHead = [
                adminLabels.addUserAvtar,
                adminLabels.addUserFirstName,
                adminLabels.addUserLastName,
                adminLabels.addUserEmailId,
                adminLabels.tableHeaderStatus,
                adminLabels.addUserLockedStatus,
                adminLabels.addUserRole,
                adminLabels.addUserOrganisationRole,
                adminLabels.dashboardUserAction,
            ];

            this.table_titles = this.dbService.DBtableHead;


            let userList = this.getUserList.bind(this);
            var promise = new Promise((resolve, reject) => {
                let roleService = this.roleService;
                let limit = 100;
                let offset = 0;
                let cb = roleService.getRoles(limit, offset);
                this.allRoles = [];
                let allRoles = this.allRoles;
                let fetchRows = false;
                let rows;
                function roles() {
                    cb.subscribe(
                        (data) => {
                            Array.prototype.push.apply(
                                allRoles,
                                data["data"]["organizationRoleList"]
                            );
                            offset += 100;
                            cb = roleService.getRoles(limit, offset);
                            if (!fetchRows) {
                                rows = data["data"]["total_rows"]
                                fetchRows = true;
                            } else {
                                rows = rows % 100;
                            }
                            if (rows / 100 > 1) {
                                roles();
                            } else {
                                allRoles.push({
                                    role_id: '0',
                                    title: ''
                                })
                                resolve(allRoles);
                            }
                        },
                        (error) => {
                        }
                    );
                }
                roles();
            }).then(function (data) {
                userList();
            });
        }
        else if (this.is_global) {
            this.table_titles = this.dbService.DBtableHead_global;
            this.table_title_keys = this.dbService.userKeys_global;

            if(this.source == "topLevelInternalUsers")
                {
                    this.table_titles = this.dbService.DBtableHead_global;
                    this.table_title_keys = this.dbService.userKeys_global;
                    this.col_widths = ['13.5%','13.5%','15%','5%','9.5%','11.5%','15%','17%'];

                }
                else if (this.source == "topLevelExternalUsers")
                {
                    console.log("here")
                    this.table_titles = this.dbService.DBtableHead_global_external;
                    this.table_title_keys = this.dbService.userKeys_global_external;
                    this.col_widths = ['12.5%','12.5%','12.5%','5%','7.5%','12.5%','12.5%','12.5%','14%'];
                }


            this.getUserList();
        }
        else {
            this.dbService.DBtableHead = [
                adminLabels.addUserAvtar,
                adminLabels.addUserFirstName,
                adminLabels.addUserLastName,
                adminLabels.addUserEmailId,
                adminLabels.tableHeaderStatus,
                adminLabels.addUserLockedStatus,
                adminLabels.addUserRole,
                adminLabels.addUserOrganisationRole,
                adminLabels.dashboardUserAction,
            ];
            this.table_titles = this.dbService.DBtableHead;
        }
    }
    

    getUserList(search = "", is_supend = '', role = '', org_role_id = '') {
        this.userService.showLoader = this.userService.showLoader ? false : true;
        if (this.prevGetUserListCall) {
            // this.prevGetUserListCall.unsubscribe();
            clearTimeout(this.prevGetUserListCall);
        }
        this.prevGetUserListCall = setTimeout(()=>{
            this.userService.getUserList(this.limit, this.offset, search, is_supend, role, org_role_id, this.source).subscribe(
            (response) => {

                // if (!this.pageLimitSet) {
                    this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
                    this.pageLimitSet = true;
                // }

                if (response["data"]["users"]) {
                    this.users = response["data"]["users"];
                    this.deleteIndex = 0;
                    this.userService.selectedUser = null;
                    this.userService.isEdit = false;

                    this.paginationService.setPaginationStatus(this.users, this.limit);
                    if (this.toastService.showToast) {
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }
                    this.close();
                }
                // else {
                //     this.userService.getAccessTokenAndSave();
                //     this.getUserList();
                // }
            },
            (error) => {

            }
        )},this.is_searching?300:0);
    }

    getSearchedUserList(search = "", is_supend = '', role = '', org_role_id = '') {
        this.userService.showLoader = this.userService.showLoader ? false : true;
        if (this.prevGetUserListCall) {
            // this.prevGetUserListCall.unsubscribe();
            clearTimeout(this.prevGetUserListCall);
        }
        this.prevGetUserListCall = setTimeout(()=>{
            this.userService.getSearchUserList(this.limit, this.offset, search, is_supend, role, org_role_id).subscribe(
            (response) => {

                // if (!this.pageLimitSet) {
                    this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
                    this.pageLimitSet = true;
                // }

                if (response["data"]["users"]) {
                    this.users = response["data"]["users"];
                    this.deleteIndex = 0;
                    this.userService.selectedUser = null;
                    this.userService.isEdit = false;

                    this.paginationService.setPaginationStatus(this.users, this.limit);
                    if (this.toastService.showToast) {
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }
                    this.close();
                }
                // else {
                //     this.userService.getAccessTokenAndSave();
                //     this.getUserList();
                // }
            },
            (error) => {

            }
        )},this.is_searching?300:0);
    }

    getSearchedUserListGlobal(search = "", is_supend = '', role = '', org_role_id = '') {
        this.userService.showLoader = this.userService.showLoader ? false : true;
        if (this.prevGetUserListCall) {
            // this.prevGetUserListCall.unsubscribe();
            clearTimeout(this.prevGetUserListCall);
        }
        this.prevGetUserListCall = setTimeout(()=>{
            this.userService.getUserList(this.limit, this.offset, search, is_supend, role, org_role_id, this.source).subscribe(
            (response) => {

                // if (!this.pageLimitSet) {
                    this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
                    this.pageLimitSet = true;
                // }

                if (response["data"]["users"]) {
                    this.users = response["data"]["users"];
                    this.deleteIndex = 0;
                    this.userService.selectedUser = null;
                    this.userService.isEdit = false;

                    this.paginationService.setPaginationStatus(this.users, this.limit);
                    if (this.toastService.showToast) {
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }
                    this.close();
                }
                // else {
                //     this.userService.getAccessTokenAndSave();
                //     this.getUserList();
                // }
            },
            (error) => {

            }
        )},this.is_searching?300:0);
    }

    searchUserList() {
        // this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.pageLimitSet = false;
        this.is_searching=true;
        if (this.search_text) {
            if(this.is_global)
            {
                this.getSearchedUserListGlobal(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
            else{
                this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
        } else {
            this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
        }
    }

    appendOffsetAndGetUsers() {
        this.is_searching=false;
        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            // if (this.search_text) {
            //     this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            // } else {
            //     this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            // }
            if (this.search_text) {
                if(this.is_global)
                {
                    this.getSearchedUserListGlobal(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
                else{
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
        }
    }

    prependOffsetAndGetUsers() {
        this.is_searching=false;
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            // if (this.search_text) {
            //     this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            // } else {
            //     this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            // }
            if (this.search_text) {
                if(this.is_global)
                {
                    this.getSearchedUserListGlobal(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
                else{
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
        }

    }

    setndex(ind) {
        this.is_searching=false;
        let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
        if (offset >= 0) {

            this.offset = offset;
            // if (this.search_text) {
            //     this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            // } else {
            //     this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            // }
            if (this.search_text) {
                if(this.is_global)
                {
                    this.getSearchedUserListGlobal(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
                else{
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
        }

    }


    performEdit(user) {
        this.router.navigate([
            "organization",
            user['organization_id'],
            "users",
            "edit",
            user["user_id"],
        ]);
    }

    showModal(user_id) {
        this.userService.showModal.next(true);
        this.users.forEach((elem, i) => {
            if (elem["user_id"] == user_id) {
                this.deleteIndex = i;
                this.delete_user_id = elem["user_id"];
            }
        });
    }

    showlockModal(data) {
        this.lockModal = true;
        this.activeModal = false
        $("#lockModal").modal("show");
        this.users.forEach((elem, i) => {
            if (elem["user_id"] == data['user_id']) {
                this.unlockIndex = i;
                this.unlock_user_id = data;
            }
        });
    }

    showStatusModal(data) {
        this.lockModal = false;
        this.activeModal = true;
        $("#lockModal").modal("show");
        this.users.forEach((elem, i) => {
            if (elem["user_id"] == data['user_id']) {
                this.unlockIndex = i;
                this.unlock_user_id = data;
            }
        });
        this.selected_user_id = data;
    }

    closeModal() {
        $("#deleteUserModal").modal("hide");
    }
    lockcloseModal() {
        $("#lockModal").modal("hide");
    }
    deleteUser(user) {
        this.userService.delete_button.disable = true;
        this.userService.delete_button.text=this.admin_data.deletingButtonText;
        this.userService.deleteUser(this.delete_user_id).subscribe((response) => {
            this.userService.delete_button.disable = false;
            this.userService.delete_button.text=this.admin_data.deleteButtonText;
            if (!response['error']) {
                this.closeModal();
                this.userService.showModal.next(false);
                this.pageLimitSet = false;
                this.toastService.showToast = true;
                this.toastService.toastType = "delete_user";
                if (this.users.length == 1 && this.paginationService.currentPage > 1) {
                    this.prependOffsetAndGetUsers()
                } else {
                    this.getUserList(this.search_text);
                }
            } else {
                if (response['code'] == 3011) {
                    this.userService.showModal.next(false);
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                    this.getUserList(this.search_text);
                }
            }
        });
    }


    assignRole(user: User) {
        let request = {
            user_id: user.user_id,
            role: user.role,
            organization_id: this.userService.organization_id,
        };
        this.is_searching=false;
        this.userService.assignRole(request).subscribe((response) => {
            if (!response['error']) {
                this.toastService.showToast = true;
                this.toastService.toastType = "assign_role";
                if (this.search_text) {
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                } else {
                    this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                if (response['code'] == 3011) {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                    if (this.search_text) {
                        this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    } else {
                        this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    }
                }
            }
        });
    }

    assignOrgRole(user: User) {
        let request = {
            user_id: user.user_id,
            org_role_id: user.org_role_id,
            organization_id: this.userService.organization_id,
        };
        this.is_searching=false;
        this.userService.assignOrgRole(request).subscribe((response) => {
            if (!response['error']) {
                this.toastService.showToast = true;
                this.toastService.toastType = "assign_org_role";
                if (this.search_text) {
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                } else {
                    this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                if (response['code'] == 3011) {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                    if (this.search_text) {
                        this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    } else {
                        this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    }
                }
            }
        });
    }

    assignOrgRoleGlobal(user) {
        this.router.navigate([
            "users",
            "org",
            "assignment",
            user.user_id 
        ]);
    }

    changeUserStatus(data) {
        this.is_searching=false;
        this.userService.suspendUser(data).subscribe((response) => {
            if (!response['error']) {
                this.toastService.showToast = true;
                this.toastService.toastType = "user_status";
                if (this.search_text) {
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                } else {
                    this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                if (response['code'] == 3011) {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                    if (this.search_text) {
                        this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    } else {
                        this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    }
                }
            }
        });
        this.lockcloseModal();
    }

    changeUserLock() {
        this.is_searching=false;
        this.userService.lockUser(this.unlock_user_id).subscribe((response) => {
            if (!response['error']) {
                this.lockcloseModal();
                this.toastService.showToast = true;
                this.toastService.toastType = "user_unlock";
                if (this.search_text) {
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                } else {
                    this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                if (response['code'] == 3011) {
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage=errorResponse;
                    $("#errorModal").modal("show");
                    if (this.search_text) {
                        this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    } else {
                        this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                    }
                }
            }
        });
    }

    applyFilter(status, role, org_role) {
        // console.log(role, org_role)
        // this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.pageLimitSet = false;
        this.is_searching=false;
        this.is_suspend_filter = (status == 'all') ? '' : status;
        this.role_filter = (role == 'all') ? '' : role;
        this.org_role_filter = (org_role == 'all') ? '' : org_role;
        // this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
        if (this.search_text) {
            this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
        } else {
            this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
        }
    }

    close() {
        if (document.getElementById("filter-dropdown-menu") && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
            document.getElementById("filter-dropdown-menu").classList.remove("show");
            document.getElementById("filter").classList.remove("show");
        }
    }

    openDropdown() {
        document.getElementById("filter-dropdown-menu").classList.add("show");
    }

    clearSession(data) {
        let request = {
            "user_id": data[0]
        }

        this.userService.clearSession(request).subscribe((response) => {
            if (response['error'] == false) {

                this.toastService.showToast = true;
                this.toastService.toastType = "clear_session";
                this.toastService.toastParam = this.users[data[1]].user_email;
                this.getUserList(this.search_text);
            } else {
                this.userService.showModal.next(false);
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage=errorResponse;
                $("#errorModal").modal("show");
                this.getUserList(this.search_text);
            }
        })
    }

    // download(){
    
        // this.csv+="\n";
        // this.users.forEach((user)=>{
        //     this.csv+=user.first_name+","+user.last_name+","+user.user_email+","+user.role+",";
        //     let role = this.allRoles.filter(elem=>{return elem['role_id']==user.org_role_id});
        //     let role_entry;
        //     if(role.length==1){
        //     role_entry = role[0];
        //     this.csv+=role_entry['title']+"\n";
        //     }else{
        //     role_entry = "Unassigned";
        //     this.csv+=role_entry+"\n";
        //     }
        // })

        // let blob = new Blob([this.csv], { type: "application/csv;charset=utf-8;" });
        // let dwldLink = document.createElement("a");
        // let url = URL.createObjectURL(blob);
        // let isSafariBrowser =
        //     navigator.userAgent.indexOf("Safari") != -1 &&
        //     navigator.userAgent.indexOf("Chrome") == -1;
        // if (isSafariBrowser) {
        //     //if Safari open in new window to save file with random filename.
        //     dwldLink.setAttribute("target", "_blank");
        // }
        // dwldLink.setAttribute("href", url);
        // let date = Date.now();
        // let d = new Date(date);
        // let month = d.getMonth()+1;
        // let day = d.getDate();
        // let year = d.getUTCFullYear();
        // let date_str = month+"/"+day+"/"+year;
        // let name = this.userService.organization.name+":" +date_str + ".csv";
        // dwldLink.setAttribute("download", name);
        // dwldLink.style.visibility = "hidden";
        // document.body.appendChild(dwldLink);
        // dwldLink.click();
        // document.body.removeChild(dwldLink);
    // }

    onInputSearch(event: Event) {
        // this.userService.validateInput(event);
        if((event.target as HTMLInputElement).value!=this.lastSearchedValue){
            this.searchUserList();
            this.lastSearchedValue=(event.target as HTMLInputElement).value;
        }
    }

    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }

    gotoFirstPage() {
        this.setndex(1);
    }

    gotoLastPage(event) {
        this.setndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        if (this.search_text) {
            if (this.is_global) {
                this.getSearchedUserListGlobal(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
            else {
                this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
        } else {
            this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
        }
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) {
            if (this.search_text) {
                if (this.is_global) {
                    this.getSearchedUserListGlobal(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
                else {
                    this.getSearchedUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
                }
            } else {
                this.getUserList(this.search_text, this.is_suspend_filter, this.role_filter, this.org_role_filter);
            }
        }
    }

}