import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../common/services/user.service";
import { ApiService } from "../common/services/api.service";
import { RoleService } from "../common/services/role.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { Title } from '@angular/platform-browser';
import { Partner, PartnerService } from "../common/services/partner.service";

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
    breadcrumb;
    admin_data = adminLabels;
    search_text="";
    limit = 200;
    offset= 0;
    col_widths=['33%','33%','33%'];
    table_titles = [this.admin_data.tableHeaderTitle, this.admin_data.tableHeaderDescription, this.admin_data.tableHeaderActions]

    table_title_keys = ["title", "description","partner_actions"]
    constructor(
        public userService: UserService,
        private router: Router,
        private apiService: ApiService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private titleService: Title,
        public partnerService: PartnerService,
      ) // private intefaceCustomizations: CustomizationCentre
      {
        this.userService.getUserData();
        // this.paginationService.initializeValues();
        // this.organisationService.getCDNData()
        this.titleService.setTitle(
          this.admin_data.partners + " | Cornerstone Guide"
        );
        this.router.events
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe(() => {
            // this.userService.setBreadcrumbs(this.admin_data.themes, this.route.url["_value"]);
            let breadCrumb = [
              {
                name: this.admin_data.partners,
                route: null,
              },
            ]
            if (this.userService.organization_id === "000") {
              let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
              breadCrumb.unshift(tempDashboardBreadCrumb);
            }
            this.userService.setBreadcrumb(breadCrumb)
            this.breadcrumb = this.userService.breadCrumbs;
          });          
          this.getPartners(this.limit,this.offset);
      }

  ngOnInit() {
  }

    getPartners(limit, offset){
        this.partnerService.getPartnerList(limit,offset).subscribe((response)=>{
            this.partnerService.partners = response["data"]["partners"];
            
        });
    }

    searchPartnerList(){
        if(this.search_text.length>0){
        this.partnerService.searchPartner(this.limit, this.offset, this.search_text)
        .subscribe((response)=>{
            this.partnerService.partners = response["data"]["partners"];
            })
        }else{
            this.getPartners(this.limit, this.offset)
        }
    }

    performEdit(id,$event){
        this.partnerService.currPartner = this.partnerService.partners[
            this.partnerService.partners.findIndex(ele=>{
                                             return ele.id == id
                                                })]
        this.router.navigate([
            "partners",
            "edit",
            id,
        ]);
        
    }

    showModal(id,$event){
        this.userService.showModal.next(true);
        
        this.partnerService.currPartner = 
               JSON.parse(JSON.stringify( this.partnerService.partners[this.partnerService.partners.findIndex(ele=>{return ele.id == id})]))
        
                                                
        this.partnerService.isDelete = true
        
    }

    deletePartner($event){
        
        this.userService.showModal.next(false);
        this.partnerService.deletePartner(this.partnerService.currPartner.id).subscribe((response)=>{
            
        this.getPartners(this.limit, this.offset)
        })
        
    }
}
