import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_TASK_LIST_FORMDATA = [
  {
    type: "text",
    name: "title",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    validators: [
      {
        required: true,
        maxLength: 80
      },
    ],
    ui: {
      label: adminLabels.tableHeaderTitle,
      class: "col-lg-4 font14",
      inputclass: "col-lg-8",
    },
    input: {
      id: "title",
      class: "form-control",
      type: "text",
      name: "title",
      placeholder: adminLabels.tableHeaderTitle,
    },
    label: {
      title: adminLabels.tableHeaderTitle,
      class: "font-weight-bold label-asterisk",
      for: "title",
    },
  },
  {
    type: "select",
    name: "application_id",
    value: "",
    disable:false,
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    layoutModal: true,
    options: [],
    validators: [
      {
        required: true,
      },
    ],
    ui: {
      label: adminLabels.taskListTableHeaderApplication,
      class: "font-weight-bold label-asterisk font14",
      inputclass: "form-control",
    },
  },
];
