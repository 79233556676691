<div class="d-flex">
    <div id="cont" class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <div class="container-fluid bg-white px-2 py-3 box-shadow-t1">
            <a *ngIf="sso_config" href="{{SSO_API_BASE_URL}}/saml2/sp/download_metadata/{{sso_config['id']}}"
                download><button class="btn btn-sm btn-primary floatRight">{{this.admin_data.ssoListDownloadMetadata}}</button></a>
            <form [formGroup]="formGroup">
                <div class="form-group">
                    <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                        <ng-container *ngFor="let form of sso_formData;let j=index">
                            <div class="{{form.class}}" *ngIf="form.type=='text'">
                                <div>
                                    <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                    <input class="{{form.ui.inputclass}}" type="{{form.type}}" autocomplete="off"
                                        formControlName="{{form.name}}"  maxlength="{{ form.validators[0].maxLength }}">
                                </div>
                                <div class="height12px font12">
                                    <ng-container
                                        *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                        <div *ngIf="formGroup['controls'][form.name].invalid"
                                            class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                    </ng-container>
                                </div>

                            </div>
                            <div class="{{form.class}}" *ngIf="form.type=='textarea'">
                                <div>
                                    <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                    <textarea class="{{form.ui.inputclass}} resize-none" formControlName="{{form.name}}"
                                        rows="6" cols="50"  maxlength="form.validators[0].maxLength?{{ form.validators[0].maxLength }}:''"></textarea>
                                    <div class="height12px font12">
                                        <ng-container
                                            *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                            <div *ngIf="formGroup['controls'][form.name].invalid"
                                                class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                        </ng-container>
                                    </div>


                                </div>
                            </div>
                        </ng-container>
                        <div class="accordion w-100 mx-3 mt-3 rounded">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-4 py-3 bg-gray cursor pointer rounded-top"
                                data-toggle="collapse" data-target="#advanced"
                                (click)="show_Advanced?hideAdvanced():showAdvanced()">
                                <label class="font-weight-bold mb-0 font14 cursorPointer"
                                    [innerHtml]="show_Advanced?'Hide Advance Settings':'Show Advance Settings'">
                                </label>
                               
                                
                                <svg-icon data-toggle="collapse" data-target="#advanced" *ngIf="!show_Advanced" src="../../../assets/svgs/icon-down-arrow.svg" class="cursorPointer floatRight">
                                </svg-icon>
                                
                                <svg-icon data-toggle="collapse" data-target="#advanced" *ngIf="show_Advanced" src="../../../assets/svgs/icon-up-arrow.svg" class="cursorPointer floatRight">
                                </svg-icon>

                            
                            </div>
                            <div id="advanced" class="row px-4 py-3 collapse">
                                <ng-container *ngFor="let form of sso_advanced_formData;let j=index">
                                    <div class="{{form.class}}" *ngIf="form.type=='radio'">
                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                        <ng-container *ngFor="let val of form.value;let m=index">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="input-radio-custom" type="{{form.type}}"
                                                    id="{{val}}{{j}}" formControlName="{{form.name}}"
                                                    name="{{form.name}}" (change)="processAlgorithmControl()"
                                                    value="{{val}}">
                                                <label class="ml-2"
                                                    for="{{val}}{{j}}">{{form.value_labels[m]}}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div [class.d-none]="!showAlgorithmSettigs" class="{{form.class}}"
                                        *ngIf="showAlgorithmSettigs && form.type=='select'">
                                        <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                        <select class="{{form.ui.inputclass}}" formControlName="{{form.name}}">
                                            <option *ngFor="let option of form['options']" value="{{option.value}}">
                                                {{option.name}}
                                            </option>
                                        </select>
                                        <div class="height12px font12">
                                            <ng-container
                                                *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                <div *ngIf="formGroup['controls'][form.name].invalid"
                                                    class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                            </ng-container>
                                        </div>


                                    </div>
                                    <div [class.d-none]="!showX509Encryption"
                                        *ngIf="showX509Encryption && form.type=='textarea'">
                                        <div class="{{form.class}}">
                                            <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                            <textarea class="{{form.ui.inputclass}} resize-none"
                                                formControlName="{{form.name}}" rows="6" cols="80"></textarea>
                                            <div class="height12px font12">
                                                <ng-container
                                                    *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                    <div *ngIf="formGroup['controls'][form.name].invalid"
                                                        class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                                </ng-container>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 my-2"
                                        [class.d-none]="!showSloUrl"
                                        *ngIf="showSloUrl && form.type=='text' && form.name == 'slo_url'">
                                        <div>
                                            <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                            <input class="{{form.ui.inputclass}}" type="{{form.type}}" autocomplete="off"
                                                formControlName="{{form.name}}">
                                        </div>
                                        <div class="height12px font12">
                                            <ng-container
                                                *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                <div *ngIf="formGroup['controls'][form.name].invalid"
                                                    class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                            </ng-container>
                                        </div>


                                    </div>
                                    <div class="{{form.class}}" *ngIf="form.type=='text' && form.name!='slo_url'">
                                        <div>
                                            <label class="{{form.ui.class}}">{{form.ui.label}}</label>
                                            <input class="{{form.ui.inputclass}}" type="{{form.type}}" autocomplete="off"
                                                formControlName="{{form.name}}">
                                        </div>
                                        <div class="height12px font12">
                                            <ng-container
                                                *ngIf="formGroup.get(form.name).touched || formGroup.get(form.name).dirty">
                                                <div *ngIf="formGroup['controls'][form.name].invalid"
                                                    class="error color">{{form.ui.label}}{{this.admin_data.isRequired}}</div>
                                            </ng-container>
                                        </div>


                                    </div>

                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
                            <div
                                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14">
                                <label  class="font-weight-bold m-0 label-asterisk">{{this.admin_data.attributeMapping}}</label>
                                <span class="mx-3 cursor pointer" (click)="addAttribute()">
                                    <svg-icon src="../../../assets/svgs/add_lang.svg">
                                    </svg-icon>
                                </span>
                                
                            </div>
                            <ng-container formArrayName="attributes" *ngFor="let elem of this.elem_map;let i=index">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 d-inline-flex"
                                    [formGroupName]="i" #attributes>
                                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pl-0 my-2">
                                        <input
                                            [value]="this.formGroup.get('attributes')['controls'][i].get('attr_name').value" autocomplete="off"
                                            formControlName="attr_name" type="text" class=" form-control"
                                            placeholder={{this.admin_data.enterIDPAttributeNamePlaceholder}} required>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 my-2">
                                        <select
                                            [value]="this.formGroup.get('attributes')['controls'][i].get('attr_segment').value"
                                            formControlName="attr_segment" class="form-control" required>
                                            <option *ngFor="let attr of attribute_list" value="{{attr}}">{{attr}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 my-2 d-flex align-items-center justify-content-center"
                                        *ngIf="i!=0">
                                        <svg-icon src="../../../assets/svgs/org_delete.svg" class="cursor pointer"
                                            (click)="remove(i)">
                                        </svg-icon>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                        <div
                            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-4 d-flex align-items-center justify-content-center">
                            <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                                (click)="unassign()">{{this.admin_data.cancelButtonText}}</button>
                            <button type="button" class="btn btn-primary minWidth-120 mx-2" (click)="updateSsoConfig()"
                                [disabled]="!formGroup.valid">{{this.admin_data.saveButtonText}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>