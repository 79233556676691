import { Component, OnInit } from '@angular/core';
import { UserService } from '../common/services/user.service';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationService } from '../common/services/application.service';
import { OrganisationService } from '../common/services/organisation.service';
import { ToastModalService } from '../common/services/toast-modal.service';



@Component({
  selector: 'app-find-replace',
  templateUrl: './find-replace.component.html',
  styleUrls: ['./find-replace.component.scss']
})

export class FindReplaceComponent implements OnInit {

  admin_data = adminLabels;
  breadcrumb;
  loader = true;
  apps = [];
  tableHeader = [];
  tableData: any;
  selectedTableData = [];
  limit = 100;
  offset = 0;
  maxSelectLimit = 50;
  selectedNum = 0;
  fetchTours = true;
  textTableHeader = [this.admin_data.guideName, this.admin_data.tableHeaderFolder, this.admin_data.tableHeaderStatus];
  selectedAppId = "";
  searchVal = "";
  replaceVal = "";
  inputTimer;
  isSearchResult = false;
  updatingData = false;
  statusTableData = [];
  apiLoading = false;
  cdn_signature: any;
  constructor(public userService: UserService, private router: Router, private appplicationService: ApplicationService,
    private organisationService: OrganisationService, private toastService: ToastModalService) {
    this.userService.getUserData();
    this.userService.activeTab = "find_replace";
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.userService.setBreadcrumb([
          {
            name: this.admin_data.findReplace,
            route: null,
          },
        ]);
        this.breadcrumb = this.userService.breadCrumbs;
      });
    this.tableHeader = this.textTableHeader;
    this.loadData();
  }

  ngOnInit() {
  }

  loadData = () => {
    this.loadAppData().then((appRes) => {
      this.apps = appRes["data"]["applications"];
      this.selectedAppId = this.apps[0]["application_id"];
      this.loadTours().then((tourData) => {
        this.tableData = tourData;
      })
      this.organisationService.getSignature(this.userService.organization_id).subscribe((response) => {
        this.cdn_signature = response["data"]["cdn_signature"];
      });
    });
  }

  loadAppData = () => {
    return new Promise((resolve, reject) => {
      this.appplicationService.getApplications(100, 0).subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
  }

  loadTours = () => {
    return new Promise((resolve, reject) => {
      this.appplicationService.loadTours(this.selectedAppId, this.limit, this.offset).subscribe(
        (res) => resolve(this.handleTourData(res)),
        (err) => reject(err)
      );
    });
  }

  searchTours = () => {
    return new Promise((resolve, reject) => {
      this.appplicationService.searchTours(this.selectedAppId, this.limit, this.offset, this.searchVal).subscribe(
        (res) => resolve(this.handleTourData(res)),
        (err) => reject(err)
      );
    });
  }

  updateTour = (payload) => {
    return new Promise((resolve, reject) => {
      this.appplicationService.updateTours(payload).subscribe(
        res => resolve(res),
        err => reject(err)
      );
    });
  }

  updateStatus = (i, payload) => {
    return new Promise((resolve) => {
      if (this.statusTableData[i] === "Done") {
        resolve("Done");
      } else {
        this.updateTour(payload).then((res) => {
          this.statusTableData[i] = "Done";
          resolve("Done");
        });
      }
    });
  }

  handleTourData = (res) => {
    let data = res["data"]["tours"];
    this.offset += data.length;
    if (data.length != 100) {
      this.fetchTours = false;
    } else {
      this.fetchTours = true;
    }
    return data;
  }

  loadOrSearchTour = (isAppend?: boolean) => {
    this.apiLoading = true;
    if (!isAppend) {
      this.tableData = [];
      this.offset = 0;
      this.selectedTableData = [];
      this.selectedNum = 0;
      this.statusTableData = [];
    }
    if (this.searchVal.trim() == "") {
      this.replaceVal = "";
      this.loadTours().then(tourRes => {
        if (isAppend) {
          Array.prototype.push.apply(this.tableData, tourRes);
        } else {
          this.tableData = tourRes;
        }
        this.isSearchResult = false;
        this.apiLoading = false;
      })
    } else {
      this.searchTours().then(searchRes => {
        this.markSearchText(searchRes);
        this.isSearchResult = true;
        this.apiLoading = false;
      });
    }
  }

  changeApp = (event: Event) => {
    let el = (event.target) as HTMLSelectElement;
    this.selectedAppId = el.value;
    this.loadOrSearchTour();
  }

  onTableScroll(event) {
    if (this.fetchTours && (event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight) {
      this.loadOrSearchTour(true);
    }
  }

  onInput() {
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.loadOrSearchTour();
    }, 500);
  }

  getIcon = (tour) => {
    let img = '';
    if (tour.image_url && tour.image_url.indexOf('default_icon') == -1) {
      return tour.image_url + this.cdn_signature;
    } else if (this.isHelpArticle(tour)) {
      img = "help-article";
    } else if (this.isInsights(tour)) {
      img = "guide-insights";
    } else if (this.isTutorial(tour)) {
      img = "guide-tutorial";
    } else if (this.isTooltip(tour)) {
      img = "guide-tooltip";
    } else {
      img = "icon-guide";
    }
    return "./../../assets/svgs/" + (tour.is_published != '0' ? img + "-published" : img) + ".svg";
  }

  onSelect = (event: Event, i) => {
    this.populateTrackingMaps();
    let el = (event.target) as HTMLInputElement;
    if (el.checked) {
      if (this.selectedNum >= this.maxSelectLimit) {
        event.preventDefault();
        this.showMaxGuideToast();
      } else {
        this.selectedTableData[i] = true;
        this.selectedNum++;
      }
    } else {
      this.selectedTableData[i] = false;
      this.selectedNum--;
    }
  }

  onSelectAll = (event: Event) => {
    this.populateTrackingMaps();
    let el = (event.target) as HTMLInputElement;
    if (el.checked) {
      for (let i = 0; i < this.selectedTableData.length; i++) {
        if (this.selectedNum >= this.maxSelectLimit) {
          this.showMaxGuideToast();
          break;
        }
        if (!this.selectedTableData[i]) {
          this.selectedTableData[i] = true;
          this.selectedNum++;
        }
      }
    } else {
      this.cancelSelection();
    }
  }

  replace = () => {
    this.updatingData = true;
    this.populateTrackingMaps();
    let replaceObjArr = {};
    for (let i = 0; i < this.selectedTableData.length; i++) {
      if (this.selectedTableData[i]) {
        let data = this.tableData[i];
        let tempObj = {
          "category_id": data["category_id"],
          "tour_description": this.removeExtraElement(data["tour_description"]),
          "tour_id": data["tour_id"],
          "tour_image": data["tour_image"],
          "tour_settings": JSON.parse(JSON.stringify(data["tour_settings"])),
          "tour_title": this.removeExtraElement(data["tour_title"]),
          "tour_type": data["tour_type"]
        }
        replaceObjArr[i] = tempObj;
      }
      if (Object.keys(replaceObjArr).length == this.selectedNum) {
        break;
      }
    }
    const promises = [];
    for (const key in replaceObjArr) {
      promises.push(this.updateStatus(key, replaceObjArr[key]));
    }
    Promise.all(promises).then(results => {
      this.updatingData = false;
    }, err => {
      this.updatingData = false;
    })
  }

  clear = () => {
    this.searchVal = "";
    this.replaceVal = "";
    this.loadOrSearchTour();
  }

  cancelSelection = () => {
    this.selectedNum = 0;
    for (let i = 0; i < this.selectedTableData.length; i++) {
      this.selectedTableData[i] = false;
    }
  }

  refreshSection = () => {
    this.loadOrSearchTour();
  }

  populateTrackingMaps = (isStatus?: boolean) => {
    let tempArr;
    if (isStatus) {
      tempArr = new Array(this.tableData.length - this.statusTableData.length).fill("");
      Array.prototype.push.apply(this.statusTableData, tempArr);
    } else if (this.tableData.length - this.selectedTableData.length > 0) {
      tempArr = new Array(this.tableData.length - this.selectedTableData.length).fill(false);
      Array.prototype.push.apply(this.selectedTableData, tempArr);
    }
    tempArr = null;
  }

  markSearchText = (dataArr) => {
    let changedVal = this.searchVal;
    (dataArr as any[]).forEach(data => {
      if (this.replaceVal.trim() != "") {
        changedVal = this.replaceVal;
      }
      data["tour_title"] = data["tour_title"].replace(new RegExp(this.searchVal, 'gi'), match => {
        return `<span class="text-highlighted">${changedVal}</span>`;
      });
      data["tour_description"] = data["tour_description"].replace(new RegExp(this.searchVal, 'gi'), match => {
        return `<span class="text-highlighted">${changedVal}</span>`;
      });
      this.tableData.push(data);
    })
  }

  replaceText = () => {
    let changedVal = this.replaceVal.trim() != "" ? this.replaceVal : this.searchVal;
    this.tableData.forEach(data => {
      data["tour_title"] = this.replaceTextInsideSpan(data['tour_title'], changedVal);
      data["tour_description"] = this.replaceTextInsideSpan(data['tour_description'], changedVal);
    });
  }

  replaceTextInsideSpan = (inputString, replacementText) => {
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = inputString;
    var spanElements = tempDiv.querySelectorAll('span');
    spanElements.forEach(function(span) {
      span.textContent = replacementText;
    });
    var updatedString = tempDiv.innerHTML;
    return updatedString;
  }

  isTooltip = (tour) => {
    if (tour && tour.tour_type && tour.tour_type.indexOf('smartTip') !== -1) return true;
    else return false;
  };

  isHelpArticle = (tour) => {
    if (tour && tour.tour_type && tour.tour_type.indexOf('help_article') !== -1) return true;
    else return false;
  };

  isTutorial = (tour) => {
    if (tour && tour.tour_type && tour.tour_type.indexOf('onboarding_tour') !== -1) return true;
    else return false;
  };

  isInsights = (tour) => {
    if (tour && tour.tour_type.indexOf('insights') >= 0) return true;
    else return false;
  };

  removeExtraElement = (str) => {
    str = str.replaceAll(`<span class="text-highlighted">`, "");
    str = str.replaceAll(`</span>`, "");
    return str;
  }

  showMaxGuideToast = () => {
    this.toastService.showToastMessage("max_guide_selected")
  }

}


/*  
change find & replace sidebar icon 

Tour Category changes not required as of now 
      // categoryMap = new Map();

      // this.loadTourCategory().then((catRes) => {
      //   let catArr:[] = catRes["data"]["categories"];
      //   catArr.forEach((cat) => [
      //     this.categoryMap.set(cat["category_id"], cat["category_title"])
      //   ]);
      // });

      // loadTourCategory = () => {
      //   return new Promise((resolve, reject) => {
      //     this.appplicationService.loadTourCategories(this.selectedAppId).subscribe(
      //       (res) => resolve(res),
      //       (err) => reject(err)
      //     );
      //   });
      // }

      -- api service --
      // loadTourCategories(application_id: string) {
      //   let urlPath = environment.baseUrl + API_END_POINTS.GET_TOUR_CATEGORIES + "?application_id=" + application_id;
      //   return this.appplicationService.get(urlPath);
      // }
*/